import {UIPriceCurrency} from 'common/types';
import React, {FunctionComponent} from 'react';
import Coins, {CoinsProps as _CoinsProps} from 'screens/common/Coins';
import CoinsInCurrency, {
  CoinsInCurrencyProps as _CoinsInCurrencyProps,
} from 'screens/common/CoinsInCurrency';
import {CurrentUser} from 'screens/common/CurrentUser';

export interface PriceBaseProps {
  coins: number;
  oldCoins?: number;
  oldCoinsText?: string;
  perDay?: boolean;
  sign?: string;
  size?: 'small' | 'medium' | 'large';
  label?: any;
  row?: boolean;
  priceIcon?: React.ComponentType<any>;
  position?: 'right' | 'left';
  gray?: boolean;
}

export interface PriceProps extends PriceBaseProps {
  CoinsProps?: Exclude<Omit<_CoinsProps, 'value'>, PriceBaseProps>;
  CoinsInCurrencyProps?: Exclude<_CoinsProps, _CoinsInCurrencyProps>;
}

const Price: FunctionComponent<PriceProps> = ({
  CoinsProps,
  CoinsInCurrencyProps,
  coins,
  gray,
  oldCoins,
  oldCoinsText,
  priceIcon: PriceIcon,
  ...baseProps
}: PriceProps) => {

  return (
    <CurrentUser>
      {({user}) => {
        if (!user) {
          return null;
        }

        if (user.uiPriceCurrency === UIPriceCurrency.Coins && !PriceIcon) {
          return <Coins gray={gray} value={coins} oldValue={oldCoins} oldValueText={oldCoinsText} {...baseProps} {...CoinsProps} />;
        } else if (user.uiPriceCurrency === UIPriceCurrency.Coins) {
          // @ts-ignore
          return <Coins gray={gray} value={coins} oldValue={oldCoins} oldValueText={oldCoinsText} {...baseProps} {...CoinsProps} priceIcon={PriceIcon}/>;
        }

        return (
          <CoinsInCurrency
            oldCoins={oldCoins}
            oldCoinsText={oldCoinsText}
            coins={coins}
            priceIcon={PriceIcon}
            currency={user.uiPriceCurrency}
            {...baseProps}
            {...CoinsInCurrencyProps}
          />
        );
      }}
    </CurrentUser>
  );
};

export default Price;
