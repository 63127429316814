import {PartnersManagementListing} from 'common/types';

export const FETCH_PARTNERS_MANAGEMENT_LISTING_REQUEST =
  '@partners/fetch-partners-management-request';
export const FETCH_PARTNERS_MANAGEMENT_LISTING_SUCCESS =
  '@partners/fetch-partners-management-success';
export const FETCH_PARTNERS_MANAGEMENT_LISTING_FAILURE =
  '@partners/fetch-partners-management-failure';

export interface FetchPartnersManagementListingAction {
  type: typeof FETCH_PARTNERS_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchPartnersManagementListingSuccessAction {
  type: typeof FETCH_PARTNERS_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: PartnersManagementListing;
  };
}

export interface FetchPartnersManagementListingFailureAction {
  type: typeof FETCH_PARTNERS_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type PartnersAction =
  | FetchPartnersManagementListingAction
  | FetchPartnersManagementListingSuccessAction
  | FetchPartnersManagementListingFailureAction;
