import Coins, {CoinsProps as BaseCoinsProps} from 'components/Coins';
import React, {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';

export interface CoinsProps
  extends Pick<BaseCoinsProps, 'size' | 'reverse' | 'single'> {
  value: number;
  oldValue?: number;
  oldValueText?: string;
  withLabel?: boolean;
  label?: any;
  perDay?: boolean;
  sign?: string;
  row?: boolean;
  priceIcon?: React.ComponentType<any>;
  position?: 'right' | 'left';
  gray?: boolean;
}

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
// @ts-ignore
const EnhancedCoins: FunctionComponent<CoinsProps> = ({
  gray,
  value,
  oldValue,
  oldValueText,
  withLabel = false,
  label = '',
  perDay = false,
  sign = '',
  row = false,
  position = 'left',
  priceIcon: PriceIcon,
  ...rest
}: CoinsProps) => {
  const intl = useIntl();

  const formattedLabel = perDay
    ? (oldValue ? <div style={{display:'flex'}}>
      <span style={{textDecoration: "line-through"}}>{oldValue}</span>&nbsp;
      {intl.formatMessage({id: 'coins.coins_per_day'}, {sign, value: value})}
      {intl.formatMessage({id: 'coins.coins_discount'}, {value: Math.round((1-value/oldValue)*100), text: oldValueText})}</div>
      : intl.formatMessage({id: 'coins.coins_per_day'}, {sign, value: value}))
    : intl.formatMessage(
        {id: withLabel ? 'coins.value_with_label' : 'coins.value'},
        {sign, value, label}
      );

  return row ? formattedLabel : PriceIcon
    ? <PriceIcon label={formattedLabel} {...rest} position/>
    : <Coins gray={gray} position={position} label={formattedLabel} {...rest} />;
};

export default EnhancedCoins;
