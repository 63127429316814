import {Spec} from 'common/types';
import {call, put, takeLatest} from 'redux-saga/effects';

import {CommonAPI} from 'services/CommonAPI';
import {createSpecId} from 'utils';
import * as CommonActions from './actions';
import {
  FETCH_ITEM_TYPES_REQUEST,
  FETCH_OFFER_OPTIONS_REQUEST,
  FETCH_OPER_TYPES_REQUEST,
  FETCH_SPECS_REQUEST,
  FetchOfferOptionsAction,
} from './types';

export function* fetchSpecs() {
  try {
    const commonAPI = new CommonAPI();
    const {data: specs} = yield call(commonAPI.fetchSpecs);

    const extendedSpecsWithInternalId = specs.map((spec: Spec) => ({
      ...spec,
      id: createSpecId(spec),
    }));

    yield put(CommonActions.fetchSpecsSuccess(extendedSpecsWithInternalId));
  } catch (error) {
    yield put(CommonActions.fetchSpecsFailure(error));
  }
}

export function* fetchItemTypes() {
  try {
    const commonAPI = new CommonAPI();
    const {data: itemTypes} = yield call(commonAPI.fetchItemTypes);

    yield put(CommonActions.fetchItemTypesSuccess(itemTypes));
  } catch (error) {
    yield put(CommonActions.fetchItemTypesFailure(error));
  }
}

export function* fetchOperTypes() {
  try {
    const commonAPI = new CommonAPI();
    const {data: operTypes} = yield call(commonAPI.fetchOperTypes);

    yield put(CommonActions.fetchOperTypesSuccess(operTypes));
  } catch (error) {
    yield put(CommonActions.fetchOperTypesFailure(error));
  }
}

export function* fetchOfferOptions(action: FetchOfferOptionsAction) {
  try {
    const {operType, itemType} = action.payload;
    const commonAPI = new CommonAPI();
    const {data: offerOptions} = yield call(commonAPI.fetchOfferOptions, {
      operType,
      itemType,
    });

    yield put(CommonActions.fetchOfferOptionsSuccess(offerOptions));
  } catch (error) {
    yield put(CommonActions.fetchOfferOptionsFailure(error));
  }
}

export default [
  takeLatest(FETCH_SPECS_REQUEST, fetchSpecs),
  takeLatest(FETCH_ITEM_TYPES_REQUEST, fetchItemTypes),
  takeLatest(FETCH_OPER_TYPES_REQUEST, fetchOperTypes),
  takeLatest(FETCH_OFFER_OPTIONS_REQUEST, fetchOfferOptions),
];
