import React, {FunctionComponent} from 'react';
import {IntlProvider as BaseIntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import {AppState} from 'store';
import * as I18nSelectors from 'store/i18n/selectors';

const mapStateToProps = (state: AppState) => ({
  isFetching: I18nSelectors.isFetching(state),
  locale: I18nSelectors.locale(state),
  messages: I18nSelectors.messages(state),
  error: I18nSelectors.error(state),
});

interface IntlProviderProps extends ReturnType<typeof mapStateToProps> {
  children: React.ReactNode;
}

const IntlProvider: FunctionComponent<IntlProviderProps> = ({
  locale,
  messages,
  children,
}: IntlProviderProps) => {
  if (locale && messages) {
    return (
      <BaseIntlProvider locale={locale} messages={messages}>
        {children}
      </BaseIntlProvider>
    );
  }

  return null;
};

const ConnectedIntlProvider = connect(mapStateToProps)(IntlProvider);

export {ConnectedIntlProvider};
