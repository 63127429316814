import {makeStyles, Theme} from '@material-ui/core';
import cx from 'classnames';
import React, {FunctionComponent, memo} from 'react';
import {createResizedImageUrl, createSrcSet} from 'utils/media';

const SCREENS_DENSITIES = [1, 2, 3];

export interface ResizedImageProps {
  imageKey: string;
  rounded?: boolean;
  round?: boolean;
  width: number;
  height: number;
  className?: string;
  alt?: string;
  densities?: number[];
}

const useStyles = makeStyles<Theme, ResizedImageProps>(theme => ({
  root: {
    borderRadius: props => (props.round ? '50%' : (props.rounded ? theme.shape.borderRadius : 0)),
    width: props => props.width,
    height: props => props.height,
  },
}));

export const ResizedImage: FunctionComponent<ResizedImageProps> = memo(
  (props: ResizedImageProps) => {
    const {densities = [], imageKey, width, height, className, alt = ''} = props;

    const classes = useStyles(props);

    return (
      <img
        className={cx(classes.root, className)}
        src={createResizedImageUrl(imageKey, width, height)}
        srcSet={createSrcSet(
          ...(densities && densities.length ? densities : SCREENS_DENSITIES).map(density =>
            createResizedImageUrl(imageKey, width * density, height * density)
          )
        )}
        alt={alt}
      />
    );
  }
);
