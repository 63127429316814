interface State {
  mobileMenuOpen: boolean;
  logoutDialogOpen: boolean;
}

const initialState: State = {
  mobileMenuOpen: false,
  logoutDialogOpen: false,
};

const OPEN_MOBILE_MENU = '@header/open-mobole-menu';
const CLOSE_MOBILE_MENU = '@header/close-mobole-menu';
const OPEN_LOGOUT_DIALOG = '@header/open-logout-dialog';
const CLOSE_LOGOUT_DIALOG = '@header/close-logout-dialog';

interface OpenMobileMenuAction {
  type: typeof OPEN_MOBILE_MENU;
}

interface CloseMobileMenuAction {
  type: typeof CLOSE_MOBILE_MENU;
}

interface OpenLogoutDialogAction {
  type: typeof OPEN_LOGOUT_DIALOG;
}

interface CloseLogoutDialogAction {
  type: typeof CLOSE_LOGOUT_DIALOG;
}

type HeaderAction =
  | OpenMobileMenuAction
  | CloseMobileMenuAction
  | OpenLogoutDialogAction
  | CloseLogoutDialogAction
  ;

export const headerReducer = (
  state: State = initialState,
  action: HeaderAction
) => {
  switch (action.type) {
    case OPEN_MOBILE_MENU:
      return {
        ...state,
        mobileMenuOpen: true
      };

    case CLOSE_MOBILE_MENU:
      return {
        ...state,
        mobileMenuOpen: false
      };

    case OPEN_LOGOUT_DIALOG:
      return {
        ...state,
        logoutDialogOpen: true
      };

    case CLOSE_LOGOUT_DIALOG:
      return {
        ...state,
        logoutDialogOpen: false
      };

    default:
      return state;
  }
};

export const openMobileMenu = {
  type: OPEN_MOBILE_MENU
};

export const closeMobileMenu = {
  type: CLOSE_MOBILE_MENU
};

export const openLogoutDialog = {
  type: OPEN_LOGOUT_DIALOG
};

export const closeLogoutDialog = {
  type: CLOSE_LOGOUT_DIALOG
};
