import {
  CookieName,
  DEFAULT_LOCALE,
  Header,
  Locale,
  LocaleFull,
  MAP_LOCALE_FULL_TO_LOCALE,
  MAP_LOCALE_TO_LOCALE_FULL,
} from 'common/types';
import flatten from 'flat';
import {Cookies} from 'react-cookie';
import {call, fork, put, takeLatest} from 'redux-saga/effects';
import {extendDefaultFetcherCommonHeaders} from 'services/APIService';
import {createSharedCookieSetOptions} from 'utils';
import * as I18nActions from './actions';
import {
  FETCH_I18N_MESSAGES_REQUEST,
  FetchI18nMessagesAction,
  SET_LOCALE,
  SetLocaleAction,
} from './types';

const fetchMessages = async (locale: Locale) => {
  const messages =
    locale === Locale.RU
      ? (await import('modules/intl/messages/ru')).default
      : (await import('modules/intl/messages/uk')).default;

  if (locale === Locale.RU) {
    // @ts-ignore
    await import('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
  } else {
    // @ts-ignore
    await import('@formatjs/intl-relativetimeformat/dist/locale-data/uk');
  }

  return flatten(messages);
};

export function* setLocale(action: SetLocaleAction) {
  try {
    const {locale} = action.payload;
    const localeFull = MAP_LOCALE_TO_LOCALE_FULL[locale];

    extendDefaultFetcherCommonHeaders({
      [Header.AcceptLanguage]: localeFull,
    });

    const cookies = new Cookies();
    cookies.set(process.env.REACT_APP_LANGUAGE_COOKIE_PARAM, localeFull, createSharedCookieSetOptions());

    yield put(I18nActions.fetchi18nMessages(action.payload.locale));
  } catch (error) {}
}

export function* fetchI18nMessages(action: FetchI18nMessagesAction) {
  try {
    const {locale} = action.payload;

    const messages = yield call(fetchMessages, locale);

    yield put(I18nActions.fetchi18nMessagesSuccess(locale, messages));
  } catch (error) {
    yield put(I18nActions.fetchi18nMessagesFailure(error));
  }
}

export function* setupLocale() {
  const cookies = new Cookies();

  const localeFullFromCookie = cookies.get(process.env.REACT_APP_LANGUAGE_COOKIE_PARAM) as LocaleFull;
  const locale = MAP_LOCALE_FULL_TO_LOCALE[localeFullFromCookie];
  const localeToSet =
    // locale ? locale :
    DEFAULT_LOCALE;

  yield put(I18nActions.setLocale(localeToSet));
}

export default [
  takeLatest(SET_LOCALE, setLocale),
  takeLatest(FETCH_I18N_MESSAGES_REQUEST, fetchI18nMessages),
  fork(setupLocale),
];
