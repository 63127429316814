import {call, put, takeLatest} from 'redux-saga/effects';
import {OffersAPI} from 'services/OffersAPI';
import * as OffersActions from './actions';
import {
  FETCH_OFFERS_MANAGEMENT_LISTING_REQUEST,
  FetchOffersManagementListingAction,
} from './types';

export function* fetchOffersManagementListing(
  action: FetchOffersManagementListingAction
) {
  try {
    const {params} = action.payload;

    const offersAPI = new OffersAPI();

    const {data} = yield call(offersAPI.fetchOffersList, params);
    yield put(OffersActions.fetchOffersManagementListingSuccess(data));
  } catch (error) {
    yield put(OffersActions.fetchOffersManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_OFFERS_MANAGEMENT_LISTING_REQUEST,
    fetchOffersManagementListing
  ),
];
