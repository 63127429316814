import {
  Box,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Close,
} from '@material-ui/icons';
import UserMenu from 'components/UserMenu';
import React from 'react';
import {rieltor} from 'styles/theme';
import Balance from '../Balance';
import {MobileHeaderAvatar} from '../HeaderAvatar';
import {useMobileMenu} from '../hooks';
import LogoutConfirmation from '../LogoutConfirmation';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  menuTopWrapper: {
    background: rieltor.mainGradient,
  },
  menuTopClose: {
    height: '70px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: rieltor.fontFamily,
    fontSize: '20px',
    fontWeight: 700,
    color: rieltor.grayFill,
  },
  menuTopUser: {
    minHeight: '100px',
    padding: '20px 16px',
  },
  menuTopControls: {
    padding: '16px',
  },
  menuTop: {
    padding: '16px',
  }
}));

const SideNavigation = () => {
  const classes = useStyles();
  const [isMenuOpen, , close] = useMobileMenu();

  return (
    <>
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        anchor="left"
        open={isMenuOpen}
        onClose={close}
      >
        <div className={classes.menuTopWrapper}>
          <div className={classes.menuTopClose}>
            <IconButton onClick={close} style={{position: 'absolute', top: '12px', left: '16px'}}>
              <Close style={{fill: '#fff'}}/>
            </IconButton>
            Меню
          </div>

          <Divider style={{backgroundColor: 'rgba(255,255,255,0.4)'}}/>

          <Box className={classes.menuTopUser}>
            <MobileHeaderAvatar avatarSize={74}/>
          </Box>

          <Divider style={{backgroundColor: 'rgba(255,255,255,0.4)'}}/>

          <Box className={classes.menuTopControls}>
            <Balance/>
          </Box>
        </div>

        <UserMenu isMobile={true}/>
      </Drawer>

      <LogoutConfirmation/>
    </>
  );
};

export default SideNavigation;
