import {DealsManagementListing} from 'common/types';
import {
  FETCH_DEALS_MANAGEMENT_LISTING_FAILURE,
  FETCH_DEALS_MANAGEMENT_LISTING_REQUEST,
  FETCH_DEALS_MANAGEMENT_LISTING_SUCCESS,
  FetchDealsManagementListingAction,
  FetchDealsManagementListingFailureAction,
  FetchDealsManagementListingSuccessAction,
} from './types';

export const fetchDealsManagementListing = (
  params: any
): FetchDealsManagementListingAction => ({
  type: FETCH_DEALS_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchDealsManagementListingSuccess = (
  data: DealsManagementListing
): FetchDealsManagementListingSuccessAction => ({
  type: FETCH_DEALS_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchDealsManagementListingFailure = (
  error: Error
): FetchDealsManagementListingFailureAction => ({
  type: FETCH_DEALS_MANAGEMENT_LISTING_FAILURE,
  error,
});
