import {call, put, takeLatest} from 'redux-saga/effects';
import {AgencyFeedAPI} from 'services/AgencyFeedAPI';
import * as AgencyFeedActions from './actions';
import {
  FETCH_AGENCYFEED_MANAGEMENT_LISTING_REQUEST,
  FetchAgencyFeedManagementListingAction,
} from './types';

export function* fetchAgencyFeedManagementListing(
  action: FetchAgencyFeedManagementListingAction
) {
  try {
    console.log('FetchAgencyFeedManagementListingAction');
    const {params} = action.payload;

    const agencyFeedAPI = new AgencyFeedAPI();

    const {data} = yield call(agencyFeedAPI.fetchAgencyFeedList, params);
    yield put(AgencyFeedActions.fetchAgencyFeedManagementListingSuccess(data));
  } catch (error) {
    yield put(AgencyFeedActions.fetchAgencyFeedManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_AGENCYFEED_MANAGEMENT_LISTING_REQUEST,
    fetchAgencyFeedManagementListing
  ),
];
