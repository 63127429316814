import {Location} from 'history';
import {useEffect} from 'react';
import ReactGA from 'react-ga';
import {NormalizedRoutes} from 'common/types';

const GA_TRACKING_ID = 'UA-19483441-1';

const usePageviewTracker = (location: Location) => {
  useEffect(() => {
    const normalizedPathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, location.pathname.length - 1)
      : location.pathname;

    if (normalizedPathname === NormalizedRoutes.OlxMigration) {
      ReactGA.set({'contentGroup1': 'From Olx to Rieltor'});
    } else if (normalizedPathname === NormalizedRoutes.OffersManagement) {
      ReactGA.set({'contentGroup1': 'My Offers'});
    } else if (normalizedPathname === NormalizedRoutes.DealsManagement) {
      ReactGA.set({'contentGroup1': 'My Deals'});
    } else if (normalizedPathname === NormalizedRoutes.ProfileStats) {
      ReactGA.set({'contentGroup1': 'My Stats'});
    } else if (normalizedPathname === NormalizedRoutes.ProfileSummary) {
      ReactGA.set({'contentGroup1': 'My Settings'});
    } else if (normalizedPathname === NormalizedRoutes.Login) {
      ReactGA.set({'contentGroup1': 'Login Page'});
    } else if (normalizedPathname === NormalizedRoutes.OffersCreate) {
      ReactGA.set({'contentGroup1': 'Add Offer'});
    } else if (normalizedPathname === NormalizedRoutes.Dashboard) {
      ReactGA.set({'contentGroup1': 'My Dashboard'});
    } else {
      ReactGA.set({'contentGroup1': 'Cabinet'});
    }
    ReactGA.pageview(location.pathname + location.search + location.hash);


  }, [location]);
};

ReactGA.initialize(GA_TRACKING_ID, {
  debug: process.env.NODE_ENV === 'development',
  titleCase: false,
});

export {GA_TRACKING_ID, usePageviewTracker};
export * from './makeTrackable';
