import {Nullable, Spec, UserType} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

interface RegistrationConfirmSmsCodeData {
  registerId: string;
}

type PartialSpec = Pick<Spec, 'itemType' | 'operType'>;

export interface RegistrationPersonalPayload {
  username: string;
  email: string;
  cityId: number;
  password: string;
  registerId: string;
  avatar: string;
  utmParams?: Nullable<{}>;
}

export interface RegistrationRealtorPayload {
  username: string;
  email: string;
  cityId: number;
  password: string;
  registerId: string;
  specializations: PartialSpec[];
  avatar?: string;
  website?: string;
  startedAt?: string;
  about?: string;
  referralUserKey?: string;
  speakEnglish: boolean;
  utmParams?: Nullable<{}>;
  addressNp?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
}

export interface RegistrationAgencyPayload {
  username: string;
  email: string;
  cityId: number;
  streetId?: number;
  streetNameUnfound?: string;
  specializations: PartialSpec[];
  password: string;
  registerId: string;
  avatar?: string;
  website?: string;
  startedAt?: string;
  about?: string;
  address: string;
  houseNameUnfound: string;
  utmParams?: Nullable<{}>;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
}

export interface TempRegistrationPayload {
  registerId: string;
  userType: UserType;
}

export type RegistrationPayload =
  | RegistrationPersonalPayload
  | RegistrationRealtorPayload
  | RegistrationAgencyPayload;

export interface SendSmsCodePayload {
  phone: string;
  retry?: number;
  recaptcha: string;
}

class RegistrationAPI extends APIService {
  sendSmsCode = apiMethod(({phone, retry = 0, recaptcha = ''}: SendSmsCodePayload) =>
    this.fetcher.request({
      url: `/api/users/register-sms/`,
      method: 'post',
      data: {
        phone,
        retry,
        recaptcha
      },
    })
  );

  confirmSmsCode = apiMethod<RegistrationConfirmSmsCodeData>(
    (phone: string, code: string) =>
      this.fetcher.request({
        url: `/api/users/register-confirm-sms/`,
        method: 'post',
        data: {
          phone,
          code,
        },
      })
  );

  registerOwner = apiMethod((payload: RegistrationPersonalPayload) =>
    this.fetcher.request({
      url: `/api/users/register/personal/`,
      method: 'post',
      data: payload,
    })
  );

  registerRealtor = apiMethod((payload: RegistrationRealtorPayload) =>
    this.fetcher.request({
      url: `/api/users/register/realtor/`,
      method: 'post',
      data: payload,
    })
  );

  registerAgency = apiMethod((payload: RegistrationAgencyPayload) =>
    this.fetcher.request({
      url: `/api/users/register/agency/`,
      method: 'post',
      data: payload,
    })
  );

  saveTempRegistration = apiMethod(
    (payload: TempRegistrationPayload) =>
      this.fetcher.request({
        url: `/api/users/temp-register/${payload.registerId}/`,
        method: 'put',
        data: {
          group: payload.userType,
        },
      }),
    {throwIfStatusFail: false}
  );
}

export {RegistrationAPI};
