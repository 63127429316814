import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {
  APIError,
  APIResponse,
  APIResponseStatus,
  DEFAULT_LOCALE_FULL,
  Header,
} from 'common/types';

const defaultFetcher = axios.create({
  baseURL: process.env.REACT_APP_LEGACY_INTERFACE_BASE_URL,
  withCredentials: true,
});

// @TODO: find a better place for setting initial locale
const localeFullFromCookie =
  // new Cookies().get(process.env.REACT_APP_LANGUAGE_COOKIE_PARAM) ||
  DEFAULT_LOCALE_FULL;

defaultFetcher.defaults.headers.common[
  Header.AcceptLanguage
] = localeFullFromCookie;
// -----------------------

const extendDefaultFetcherCommonHeaders = (headers: object) => {
  Object.entries(headers).forEach(([key, value]) => {
    defaultFetcher.defaults.headers.common[key] = value;
  });
};

class APIService {
  constructor(protected fetcher: AxiosInstance = defaultFetcher) {}
}

const createErrorMessage = (url: string | undefined = 'UNKNOWN URL') =>
  `Failed to fetch ${url}`;

export interface APIMethodConfig {
  throwIfStatusFail: boolean;
}

const DEFAULT_API_METHOD_CONFIG: APIMethodConfig = {
  throwIfStatusFail: true,
};

const apiMethod = <
  T = any,
  Fn extends (...args: any[]) => Promise<AxiosResponse<T>> = any
>(
  fn: Fn,
  config?: APIMethodConfig
) => async (...args: Parameters<Fn>): Promise<APIResponse<T>> => {
  const axiosResponse = await fn(...args);
  const mergedConfig = {...DEFAULT_API_METHOD_CONFIG, ...config};

  const response = (axiosResponse.data as unknown) as APIResponse<T>;

  if (
    response.status === APIResponseStatus.Error &&
    mergedConfig.throwIfStatusFail
  ) {
    let errorMessage = createErrorMessage(axiosResponse.config.url);

    let error = response.error;
    if (response.message) {
      errorMessage = response.message;
    }

    throw new APIError(errorMessage, error);
  }

  return response;
};

export {apiMethod, APIService, extendDefaultFetcherCommonHeaders};
