import {Box, fade, Link as MuiLink, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import Bankid from '../../profile/ProfileSummaryScreen/Bankid';
import {ReferralsScreenProps} from '..';
import ReferralCoins from '../ReferralCoins';
import ReferralInfo from '../ReferralInfo';
import ReferralSteps from '../ReferralSteps';
import RouterLink from "../../common/RouterLink";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    padding: '34px 20px 26px',
    minHeight: 294,
    marginTop: 18,
    alignItems: 'flex-start',
    background: 'linear-gradient(180deg, #7D1FF5 0%, #DA48C5 99.98%)',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    color: '#ffffff',
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 500,
    width: '330px',
    fontFamily: 'Inter',
    marginRight: 50,
    flexShrink: 0,
    '& span': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textUnderlineOffset: '4px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 22,
      width: '100%',
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 700,
      width: '100%',
    },
  },
  info: {
    flex: 1,
    paddingRight: 373,
    maxWidth: 1100,
    '&>div:first-child':{
      marginTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.57,
    color: '#fff',
    marginBottom: 26,

    '& a': {
      color: '#FFCB2B',
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textUnderlineOffset: '3px',
      fontWeight: 700,
    },
    '& b': {
      fontWeight: 700,
    },
  },
  img: {
    width: 347,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
      marginBottom: -26,
    },
    '& img': {
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 330,
      },
      '&:first-child': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '&:last-child': {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
    },
  },
  noVerifDesktopImg: {

  },
  close: {
    position: 'absolute',
    top: 11,
    right: 11,
    cursor: 'pointer',
  },
  steps: {
    paddingRight: 16,
  },
  moreBtn: {
    color: '#000',
    cursor: 'pointer',
    height: 30,
    display: 'inline-flex',
    fontSize: 12,
    background: '#FFCB2B',
    marginTop: 24,
    transition: 'background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    alignItems: 'center',
    fontWeight: 700,
    borderRadius: 2,
    padding: '0 20px',
    justifyContent: 'center',
    marginLeft: 17,
    '&:hover': {
      background: '#FFC000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 30,
      marginLeft: 0
    },
  },
}));

interface ReferralAlertProps {
  user: ReferralsScreenProps['user'];
  onClose: () => void;
}

const ReferralAlert = ({user, onClose}: ReferralAlertProps) => {
  const classes = useStyles();
  const {formatMessage: fm} = useIntl();

  return (
    <Box className={classes.root}>
      <Box
        className={classes.title}
        dangerouslySetInnerHTML={{
          __html: fm({
            id: 'referrals.alert_title',
          }),
        }}
      />
      <Box className={classes.info}>
        <ReferralSteps short={true} />


          <Box className={classes.steps}>
            <MuiLink
              className={classes.moreBtn}
              // @ts-ignore
              to="/referrals"
              component={RouterLink}
              color="primary"
            >
              &nbsp;<FormattedMessage id="referrals.more_link"/>
            </MuiLink>
          </Box>


      </Box>
      <Box className={classes.img}>
        {user.verificated ? (
          <>
            <img
              src={require('../icons/icon_alert.png')}
              srcSet={`${require('../icons/icon_alert.png')} 1x, ${require('../icons/icon_alert_2x.png')} 2x`}
              alt=""
            />
            <img
              src={require('../icons/icon_alert_mob.png')}
              srcSet={`${require('../icons/icon_alert_mob.png')} 1x, ${require('../icons/icon_alert_mob_2x.png')} 2x`}
              alt=""
            />
          </>
        ) : (
          <>
            <img
              src={require('../icons/icon_alert_no_verif_new_x1.png')}
              srcSet={`${require('../icons/icon_alert_no_verif_new_x1.png')} 1x, ${require('../icons/icon_alert_no_verif_new_x2.png')} 2x`}
              alt=""
              className={classes.noVerifDesktopImg}
            />
            <img
              src={require('../icons/icon_alert_no_verif_new_x1.png')}
              srcSet={`${require('../icons/icon_alert_no_verif_new_x1.png')} 1x, ${require('../icons/icon_alert_no_verif_new_x2.png')} 2x`}
              alt=""
            />
          </>
        )}
      </Box>
      <Box className={classes.close} onClick={onClose}>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
            fill="white"
          />
        </svg>
      </Box>
    </Box>
  );
};

export default ReferralAlert;
