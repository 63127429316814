import {Hidden, ListItem, ListItemIcon, makeStyles, Theme} from '@material-ui/core';
import cx from 'classnames';
import {UserInfo} from 'common/types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {useMobileMenu} from 'screens/MainLayout/hooks';
import {UserMenuConfigItem} from 'screens/MainLayout/menuConfig';
import {rieltor} from 'styles/theme';

const useStyles = makeStyles(({breakpoints}: Theme) => ({
  listItem: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    padding: '6px 20px',
    rowGap: '6px',
    width: 'fit-content',
    borderRadius: '4px',
    color: '#000',
    [breakpoints.down('sm')]: {
      flexFlow: 'row nowrap',
      width: '100%',
      columnGap: '16px',
      padding: '16px 0',
    },

    '&:hover': {
      color: '#000',
    },
    '&:focus': {
      color: '#000',
    },
  },
  listItemActive: {
    backgroundColor: rieltor.purple,
    borderRadius: '4px',
    color: '#fff',
    [breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      color: rieltor.purple,
    },

    '&:hover': {
      color: '#000',
      '& $badge': {
        backgroundColor: rieltor.purple,
        color: '#fff',
      },
    },
    '&:focus': {
      backgroundColor: rieltor.purple,
      color: '#fff',
      '& $badge': {
        backgroundColor: '#fff',
        color: rieltor.purple,
      },
    },
    '& $badge': {
      backgroundColor: '#fff',
      color: rieltor.purple,
      [breakpoints.down('sm')]: {
        backgroundColor: rieltor.purple,
        color: '#fff',
      },
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listItemText: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '4px',
    alignItems: 'center',
    flexGrow: 1,
    fontFamily: rieltor.fontFamily,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      textAlign: 'left',
      columnGap: '16px',
    },
  },
  listItemTextWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '4px',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  badge: {
    borderRadius: '12px',
    fontFamily: rieltor.fontFamily,
    backgroundColor: rieltor.purple,
    padding: '0 8px',
    fontSize: '11px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#fff',

    '&.new': {
      fontSize: '12px',
      backgroundColor: rieltor.yellow,
      color: '#000',
    }
  }
}));

interface UserMenuItemProps {
  user: UserInfo;
  menuItem: UserMenuConfigItem;
}

const UserMenuItem = (
  {user, menuItem}: UserMenuItemProps
) => {
  const classes = useStyles();
  const history = useHistory();
  const userMenuBadges = {
    activeOffersCount: user.activeOffersCount,
    partnersConfirmed: user.partnersConfirmed,
    partnersRequests: user.partnersRequests,
    agentsRequests: user.agentsRequests,
    referralCount: user.referralCount,
  };

  const url = menuItem.uid === 'my_website' ? `https://${user.domain}` : menuItem.url;
  const isActive = history.location.pathname === menuItem.url;

  const [, , closeMobileMenu] = useMobileMenu();

  return (
    <ListItem
      className={cx(classes.listItem, {
        [classes.listItemActive]: isActive
      })}
      key={`user_menu_${menuItem.uid}`}
      button
      onClick={() => {
        closeMobileMenu();

        url.match('https') ? window.open(url) : history.push(url);
      }}
    >
      {menuItem.icon}

      <div className={classes.listItemTextWrapper}>
        <div
          style={{whiteSpace: 'nowrap'}}
          className={classes.listItemText}
        >
          <FormattedMessage id={menuItem.label}/>
          {menuItem.new && <div className={cx(classes.badge, 'new')}>new</div>}
        </div>

        {menuItem.badge && menuItem.badge(userMenuBadges)}
        <Hidden mdUp>
          {menuItem.iconEnd ?? null}
        </Hidden>
      </div>
    </ListItem>
  );
};

export default UserMenuItem;

//

interface BadgeProps {
  value?: string | number;
}

export const Badge = ({value}: BadgeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.badge}>{value}</div>
  );
};
