import {combineReducers} from 'redux';
import {commonReducer} from './common/reducers';
import {i18nReducer} from './i18n/reducers';
import {offersReducer} from './offers/reducers';
import {userReducer} from './user/reducers';
import {dealsReducer} from './deals/reducers';
import {agentsReducer} from './agents/reducers';
import {partnersReducer} from './partners/reducers';
import {referralsReducer} from './referrals/reducers';
import {globalConfigReducer} from './global/reducers';
import {olxImportReducer} from './olx-import/reducers';
import {agencyFeedReducer} from './agency-feed/reducers';
import {profileReducer} from './profile/reducers';

export const rootReducer = combineReducers({
  common: commonReducer,
  i18n: i18nReducer,
  offers: offersReducer,
  deals: dealsReducer,
  referrals: referralsReducer,
  user: userReducer,
  agents: agentsReducer,
  partners: partnersReducer,
  globalConfig: globalConfigReducer,
  olxImport: olxImportReducer,
  agencyFeed: agencyFeedReducer,
  profile: profileReducer,
});
