import {Box, WithStyles, withStyles} from '@material-ui/core';
import {userLevelStatus} from 'common/strings';
import LevelStatus from 'components/LevelStatus';
import React from 'react';
import {CurrentUser} from 'screens/common/CurrentUser';
import UserAvatar from 'screens/common/UserAvatar';
import {rieltor} from 'styles/theme';

const styles = {
  avatarContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '16px',
    alignItems: 'center',
  },
  avatarUserInfo: {
    display: 'flex',
    flexFlow: 'column nowrap',
    fontFamily: rieltor.fontFamily,
    fontStyle: 'normal',
    color: '#fff',
    flexGrow: 1,
  },
  avatarUserInfoName: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  avatarUserInfoJob: {
    fontSize: '12px',
  },
};

interface HeaderAvatarProps extends WithStyles<typeof styles> {
  avatarSize?: number;
}

const HeaderAvatar = (
  {
    avatarSize = 40,
    classes
  }: HeaderAvatarProps
) => {
  return (
    <div className={classes.avatarContainer}>
      <CurrentUser>
        {({user}) => {
          if (user === null) {
            return null;
          }

          return (
            <>
              <UserAvatar
                imageKey={user.avatar}
                width={avatarSize}
                height={avatarSize}
                round={true}
              />

              <Box className={classes.avatarUserInfo} display={'flex'}>
                <Box className={classes.avatarUserInfoName}>{user.username}</Box>
                <Box className={classes.avatarUserInfoJob}>
                  {userLevelStatus(user)}
                </Box>
              </Box>

              <div style={{width: 46}}>
                <LevelStatus status={user.levelStatus}/>
              </div>
            </>
          );
        }}
      </CurrentUser>
    </div>
  );
};

const StyledHeaderAvatar = withStyles(styles)(HeaderAvatar);

export default StyledHeaderAvatar;

export const MobileHeaderAvatar = withStyles({
  avatarUserInfo: {
    rowGap: '10px',
  },
  avatarUserInfoName: {
    fontSize: '20px',
    fontWeight: 700,
  },
  avatarUserInfoJob: {
    fontSize: '14px',
  }
})(StyledHeaderAvatar);
