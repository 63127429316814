import {Theme, WithStyles, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {ButtonWhite} from 'components/Buttons';
import {WalletPlus} from 'components/Icons';
import {useCurrentUser} from 'hooks/user';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import Price from 'screens/common/Price';
import RouterLink from 'screens/common/RouterLink';
import {useMobileMenu} from './hooks';

const styles = (theme: Theme) => ({
  balance: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    columnGap: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  coins: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 50%',
      justifyContent: 'flex-start',
    },
  },
  payButton: {
    [theme.breakpoints.down('sm')]: {
      flex: '1 0 50%',
    },
  },
});

const Balance = (
  {classes}: WithStyles<typeof styles>
) => {
  const user = useCurrentUser();
  const [, , closeMobileMenu] = useMobileMenu();

  if (user === null) {
    return null;
  }

  return (
    <div className={classes.balance}>
      <Button
        className={classes.coins}
        component={RouterLink}
        to="/profile/stats"
      >
        <Price coins={user.balance}/>
      </Button>

      <ButtonWhite
        size={'large'}
        className={classes.payButton}
        // @ts-ignore
        component={RouterLink}
        to="/payment"
        startIcon={<WalletPlus/>}
        onClick={() => {
          closeMobileMenu();
        }}
      >
        <FormattedMessage id="header.pay"/>
      </ButtonWhite>
    </div>
  );
};

export default withStyles(styles)(Balance);
