import {
  Box,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import cx from 'classnames';
import {GOOGLE_PLAY_APP} from 'common/constants';
import Support from 'components/UserMenu/Support';
import UserMenuItem from 'components/UserMenu/UserMenuItem';
import {useCurrentUser} from 'hooks/user';
import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useLogoutConfirmation} from 'screens/MainLayout/hooks';
import {UserMenuConfig} from 'screens/MainLayout/menuConfig';
import {UsersAPI} from 'services/UsersAPI';
import {rieltor} from 'styles/theme';
import {createLegacyInterfaceUrl, createLoginLink} from 'utils/navigation';
import {referralProgramRegisterSelector} from '../../store/global/selectors';
import {Download, Faq, ProfileLogout} from '../Icons';

const styles = ({breakpoints}: Theme) => ({
  userMenuWrap: {
    width: '100%',
    padding: '6px 16px',
    background: '#fff',
    [breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  userMenuList: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '5px',
    maxWidth: '1888px', // 1920 - 32px
    margin: '0 auto',
    padding: '0px',
    [breakpoints.down('sm')]: {
      flexFlow: 'column nowrap',
    },
  },
  listItem: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    padding: '6px 20px',
    rowGap: '6px',
    width: 'fit-content',
    color: '#000',
    [breakpoints.down('sm')]: {
      flexFlow: 'row nowrap',
      width: '100%',
      columnGap: '16px',
      padding: '16px 0',
    },

    '&:hover': {
      borderRadius: '4px',
    },

    '& .MuiListItemIcon-root': {
      color: '#000',
    },

    '&.mobileSpacer': {
      display: 'flex',
      flexGrow: 1,
      padding: 0,
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listItemTextWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '4px',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  listItemText: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '4px',
    alignItems: 'center',
    flexGrow: 1,
    fontFamily: rieltor.fontFamily,
    fontSize: '14px',
    fontWeight: 500,
  },
});

interface UserMenuProps extends WithStyles<typeof styles> {
  isMobile?: boolean;
}

const UserMenu = (
  {
    classes,
    isMobile = false,
  }: UserMenuProps
) => {
  const {formatMessage} = useIntl();
  const referralProgramRegisterEnabled = useSelector(referralProgramRegisterSelector);

  const [, logoutConfirm] = useLogoutConfirmation();

  const user = useCurrentUser();
  if (user === null) {
    return null;
  }

  return (
    <Box className={classes.userMenuWrap}>
      <List className={classes.userMenuList}>
        {
          UserMenuConfig.map(menuItem => {
            const matchRole = menuItem.roles.includes(user.roleId);
            const matchVisibility = menuItem.visible && menuItem.visible({
              user,
              referralEnabled: referralProgramRegisterEnabled
            });
            if (!(matchRole || matchVisibility)) {
              return null;
            }

            return <UserMenuItem
              key={`user_menu_${menuItem.uid}`}
              user={user}
              menuItem={menuItem}
            />;
          })
        }

        <ListItem className={cx(classes.listItem, 'mobileSpacer')}>
          <Hidden mdUp>
            <Support/>
          </Hidden>
        </ListItem>

        {
          isMobile && (
            <>
              <ListItem
                className={classes.listItem}
                button
                component={Link}
                href={createLegacyInterfaceUrl('/faq/')}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {<Faq/>}
                </ListItemIcon>

                <ListItemText>
                  {formatMessage({id: 'side_navigation.support.faq'})}
                </ListItemText>
              </ListItem>

              <ListItem
                style={{padding: 0}}
                className={classes.listItem}
                button
                component={Link}
                href={GOOGLE_PLAY_APP}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {<Download/>}
                </ListItemIcon>

                <ListItemText>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <div>
                      Завантажити кабінет Rieltor.ua
                    </div>

                    <a href={GOOGLE_PLAY_APP} style={{display: 'inline-flex'}}>
                      <img
                        src={require('components/UserMenu/images/google-play.png')}
                        alt="Завантажити з Google Play"
                        width={'142px'}
                      /></a>
                  </Box>
                </ListItemText>
              </ListItem>
            </>
          )
        }

        <ListItem
          className={classes.listItem}
          button
          onClick={() => {
            isMobile ? logoutConfirm() : (new UsersAPI()).logout().finally(() => document.location.href = createLoginLink({}));
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <ProfileLogout/>
          </ListItemIcon>

          <div className={classes.listItemTextWrapper}>
            <div
              style={{whiteSpace: 'nowrap'}}
              className={classes.listItemText}
            >
              {formatMessage({id: 'user_profile_menu.logout'})}
            </div>
          </div>
        </ListItem>
      </List>
    </Box>
  );
};

export default withStyles(styles)(UserMenu);
