import {Link, Theme, withStyles, WithStyles} from '@material-ui/core';
import {SUPPORT_PHONE_NUMBER, SUPPORT_VIBER_CHAT} from 'common/constants';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {rieltor} from 'styles/theme';
import {createTelLink, formatPhone} from 'utils';
import {ButtonWhite} from '../Buttons';
import {Viber} from '../Icons';

const styles = (theme: Theme) => ({
  supportWrapper: {
    display: 'flex',
    padding: '16px',
    margin: '8px 0',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    rowGap: '10px',
    alignSelf: 'stretch',
    backgroundColor: rieltor.lightViolet,
    width: '100%',
    borderRadius: '8px',
  },
  schedule: {
    display: 'flex',
    columnGap: '10px',
  },
  supportImg: {
    width: '44px',
    height: '44px',
    borderRadius: '4px',
  },
  scheduleDescription: {
    display: 'flex',
    flexFlow: 'column nowrap',
    rowGap: '6px',
  },
  scheduleTitle: {
    fontFamily: rieltor.fontFamily,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18px',
  },
  scheduleText: {
    fontFamily: rieltor.fontFamily,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  phones: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '10px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-root': {
        padding: '8px',
      },
    },
  }
});

const SupportComponent = (
  {classes}: WithStyles<typeof styles>|WithStyles<typeof stylesFooter>
) => {
  return (
    <div className={classes.supportWrapper}>
      <div className={classes.schedule}>
        <img className={classes.supportImg} src={require('./images/support.png')} alt="support"/>

        <div className={classes.scheduleDescription}>
          <div className={classes.scheduleTitle}>
            <FormattedMessage id={'side_navigation.support.title'}/>
          </div>

          <div className={classes.scheduleText}>
            <FormattedMessage id={'side_navigation.support.schedule'}/>
          </div>
        </div>
      </div>

      <div className={classes.phones}>
        {
          SUPPORT_PHONE_NUMBER.split(',').map((phone: string) => {

            return <ButtonWhite
              key={`support_phone_${phone}`}
              // @ts-ignore
              component={Link}
              href={createTelLink(phone)}
              fullWidth={true}
              size={'large'}
            >
              {formatPhone(phone, true)}
            </ButtonWhite>;
          })
        }
      </div>

      <ButtonWhite
        // @ts-ignore
        component={Link}
        href={SUPPORT_VIBER_CHAT}
        fullWidth={true}
        size={'large'}
        startIcon={<Viber/>}
      >
        Viber
      </ButtonWhite>
    </div>
  );
};

const Support = withStyles(styles)(SupportComponent);

export default Support;

const stylesFooter = {
  supportWrapper: {
    display: 'grid',
    padding: '10px',
    gridTemplateColumns: '190px 2fr 1fr',
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    borderRadius: '4px',
    gap: '10px',
    '& .MuiButton-root': {
      padding: '0 8px !important',
      height: '32px',
    },
    '& .MuiButton-label': {
      fontSize: '12px',
    },
  },
  schedule: {
    display: 'flex',
    columnGap: '6px',
  },
  supportImg: {
    width: '31px',
    height: '31px',
  },
  scheduleDescription: {
    display: 'flex',
    flexFlow: 'column nowrap',
    rowGap: '6px',
  },
  scheduleTitle: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '15px',
    color: '#fff',
  },
  scheduleText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '13px',
    color: '#fff',
  },
  phones: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '10px',
    flex: '1 0 50%',
  },
};

const SupportFooter = withStyles(stylesFooter)(SupportComponent);

export {SupportFooter};
