import {Box, CircularProgress, Fade, makeStyles} from '@material-ui/core';
import cx from 'classnames';
import React, {FunctionComponent} from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
  },
  fluid: {
    minHeight: 0,
  },
}));

interface LoadingProps {
  className?: string;
  fluid?: boolean;
}

const FADE_STYLE = {
  transitionDelay: '1000ms',
};

const Loading: FunctionComponent<LoadingProps> = ({
  className,
  fluid = false,
}: LoadingProps) => {
  const classes = useStyles();

  return (
    <Box
      className={cx(
        classes.root,
        {
          [classes.fluid]: fluid,
        },
        className
      )}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Fade in style={FADE_STYLE}>
        <CircularProgress />
      </Fade>
    </Box>
  );
};

export default Loading;
