import {
  AgentsMonthReport,
  OlxImportListingSearch, OlxImportManagementFields , OffersManagementListingItem, OfficeMonthReport, ReasonOfUnpublish, RoleId,
} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

interface FetchUnpublishReasonsPayload {
  roleId: RoleId;
}

export interface SmallOlxImportListing {
  fields: OlxImportManagementFields;
  items: OffersManagementListingItem[];
}

export class OlxImportAPI extends APIService {
  fetchOlxImportList = apiMethod((params: Partial<OlxImportListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/olx-import/list/',
      params,
    }),
  );
 
  importDraft = apiMethod(
    (id: number) =>
      this.fetcher.request({
        method: 'put',
        url: `/api/olx-import/import/${id}/`,
      })
  );

}
