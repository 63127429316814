import {call, fork, put} from "redux-saga/effects";
import * as Actions from "../global/actions";
import {GlobalConfigAPI} from "../../services/GlobalConfigAPI";

export function* fetchConfig() {
  try {
    const api = new GlobalConfigAPI();

    const {data: globalConfig} = yield call(api.fetchConfig);

    yield put(Actions.fetchGlobalConfigSuccess(globalConfig));
  } catch (error) {
    yield put(Actions.fetchGlobalConfigFailure(error));
  }
}

export default [
  fetchConfig,

  fork(fetchConfig),
]