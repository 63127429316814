import {DealsManagementListing} from 'common/types';

export const FETCH_DEALS_MANAGEMENT_LISTING_REQUEST =
  '@deals/fetch-deals-management-request';
export const FETCH_DEALS_MANAGEMENT_LISTING_SUCCESS =
  '@deals/fetch-deals-management-success';
export const FETCH_DEALS_MANAGEMENT_LISTING_FAILURE =
  '@deals/fetch-deals-management-failure';

export interface FetchDealsManagementListingAction {
  type: typeof FETCH_DEALS_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchDealsManagementListingSuccessAction {
  type: typeof FETCH_DEALS_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: DealsManagementListing;
  };
}

export interface FetchDealsManagementListingFailureAction {
  type: typeof FETCH_DEALS_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type DealsAction =
  | FetchDealsManagementListingAction
  | FetchDealsManagementListingSuccessAction
  | FetchDealsManagementListingFailureAction;
