import {Locale, Nullable} from 'common/types';
import {
  FETCH_I18N_MESSAGES_FAILURE,
  FETCH_I18N_MESSAGES_REQUEST,
  FETCH_I18N_MESSAGES_SUCCESS,
  I18nAction,
  SET_LOCALE,
} from './types';

type Messages = Record<string, string>;

interface I18nState {
  isFetching: boolean;
  locale: Nullable<Locale>;
  messages: Nullable<Messages>;
  error: Nullable<Error>;
}

const initialState: I18nState = {
  isFetching: false,
  locale: null,
  messages: null,
  error: null,
};

export const i18nReducer = (
  state: I18nState = initialState,
  action: I18nAction
) => {
  switch (action.type) {
    case SET_LOCALE:
      return state;

    case FETCH_I18N_MESSAGES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_I18N_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        locale: action.payload.locale,
        messages: action.payload.messages,
      };

    case FETCH_I18N_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
