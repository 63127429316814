import {makeStyles, Theme} from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {usePeriodFilter} from 'store/profile/reducers/statsReducer';

export const PERIOD_QUARTER = 'quarter';
export const PERIOD_YEAR = 'year';
export const PERIOD_2YEAR = '2year';
export const PERIOD_3YEAR = '3year';

const PeriodSwitcher = () => {
  const classes = makeStyles<Theme>(theme => ({
    periodWrapper: {},
    period: {
      fontSize: '1.25rem',
      color: '#8711ff',
      marginRight: '10px',
      borderBottom: '1px dotted #8711ff',
      cursor: 'pointer',
      marginBottom: '10px',
    },
    activePeriod: {
      borderBottom: 'none',
    },
  }))();

  const [statPeriod, setStatPeriod] = usePeriodFilter();

  return (
    <div className={classes.periodWrapper}>
      {
        [PERIOD_QUARTER, PERIOD_YEAR, PERIOD_2YEAR].map(period => {
          return (
            <span
              key={`period_switch_${period}`}
              className={cx(
                classes.period,
                {[classes.activePeriod]: statPeriod === period},
              )}
              onClick={() => setStatPeriod(period)}
            >
              <FormattedMessage id={`profile_stats.period_${period}`}/>
            </span>
          );
        })
      }
    </div>
  );
};

export default PeriodSwitcher;
