import {Box, makeStyles} from '@material-ui/core';
import {Locale, UserLevelStatus} from 'common/types';
import React, {FunctionComponent, useMemo} from 'react';

import {ReactComponent as IconGoldNoBorder} from './images/gold_no_border.svg';
import {ReactComponent as IconPlatinumNoBorder} from './images/platinum_no_border.svg';
import {ReactComponent as IconSilverNoBorder} from './images/silver_no_border.svg';
import {ReactComponent as IconEmptyNoBorder} from './images/status_empty.svg';
import {ReactComponent as IconWhiteNoBorder} from './images/white_no_border.svg';

export interface LevelStatusItem {
  locale: Locale;
  label: any;
}

type Variant = 'dark' | 'light';

const useStyles = makeStyles(theme => ({
  boardProfInfoPartnerImgWr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 2,

    [theme.breakpoints.down('xs')]: {
      order: -1,
      paddingTop: 0
    },

    '& svg': {
      fill: 'none !important',
    }
  },
  boardProfInfoPartnerImg: {
    width: '100%',
    maxWidth: '46px',
  },
}));

export const statusImages = {
  [UserLevelStatus.White]:    require('./images/white_badge.png'),
  [UserLevelStatus.Silver]:   require('./images/silver_badge.png'),
  [UserLevelStatus.Gold]:     require('./images/gold_badge.png'),
  [UserLevelStatus.Platinum]: require('./images/platinum_badge.png'),
};

export const statusImagesNoBorder = {
  empty: <IconEmptyNoBorder/>,
  [UserLevelStatus.White]: <IconWhiteNoBorder/>,
  [UserLevelStatus.Silver]: <IconSilverNoBorder/>,
  [UserLevelStatus.Gold]: <IconGoldNoBorder/>,
  [UserLevelStatus.Platinum]: <IconPlatinumNoBorder/>,
};

export interface LevelStatusProps {
  status: UserLevelStatus;
}

const LevelStatus: FunctionComponent<LevelStatusProps> = (
  {
    status,
  }: LevelStatusProps
) => {
  const classes = useStyles();

  const statusImage = useMemo(() => {
    if (status && statusImages[status]) {
      return statusImages[status];
    }
    return null;
  }, [status]);

  return (
    <Box className={classes.boardProfInfoPartnerImgWr}>
      <img
        className={classes.boardProfInfoPartnerImg}
        // @ts-ignore
        src={statusImage}
        alt=""
      />
    </Box>
  );
};

export default LevelStatus;
