import {Nullable, OlxImportManagementListing} from 'common/types';
import {
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_FAILURE,
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST,
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_SUCCESS,
  OlxImportAction,
} from '../types';

interface State {
  data: Nullable<OlxImportManagementListing>;
  isFetching: boolean;
  error: Nullable<Error>;
}

const initialState: State = {
  data: null,
  isFetching: false,
  error: null,
};

export const managementReducer = (
  state: State = initialState,
  action: OlxImportAction
) => {
  switch (action.type) {
    case FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };

    case FETCH_OLXIMPORT_MANAGEMENT_LISTING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
      };
    case FETCH_OLXIMPORT_MANAGEMENT_LISTING_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
