import {
  ReferralsListingSearch, RoleId,
} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

export class ReferralsAPI extends APIService {
  fetchReferralsList = apiMethod((params: Partial<ReferralsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/referrals/list/',
      params,
    }),
  );
}
