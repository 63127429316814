import {Box, fade} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: 1,
    alignItems: 'center',
    padding: '5px 7px 5px 5px',
    borderRadius: 4,
    background: fade('#FFCB2B', 0.3),
  },
  img: {
    width: 13,
    height: 12,
    marginRight: 4,
  },
  yellow: {
    color: '#FFCB2B'
  },
}));

interface ReferralCoinsProps {
  color?: 'yellow' | 'inherit';
  value: number;
  unit?: string;
  icon?: any;
}

const ReferralCoins = ({ color = 'yellow', value, unit = 'грн', icon }: ReferralCoinsProps) => {
  const classes = useStyles();
  const Icon = ()=> icon === undefined ? <img
    className={classes.img}
    src={require('../icons/coin_s.png')}
    alt=""
    srcSet={`${require('../icons/coin_s.png')} 1x, ${require('../icons/coin_m.png')} 2x`}
  /> : <></>
  return (
    <Box className={cx(classes.root, {[classes.yellow]: color === 'yellow'})}>
      <Icon />
      <span>{value} {unit}</span>
    </Box>
  );
};

export default ReferralCoins;
