import {Nullable} from 'common/types';
import {
  RESTORE_USER_FAILURE,
  RESTORE_USER_REQUEST,
  RESTORE_USER_SUCCESS,
  RESET_REGISTRATION_STATE,
  SEND_RESTORE_USER_CODE_FAILURE,
  SEND_RESTORE_USER_CODE_REQUEST,
  SEND_RESTORE_USER_CODE_SUCCESS,
  UserAction,
  VERIFY_RESTORE_USER_CODE_FAILURE,
  VERIFY_RESTORE_USER_CODE_REQUEST,
  VERIFY_RESTORE_USER_CODE_SUCCESS,
} from '../types';

interface RestoreUserState {
  isCodeSent: boolean;
  isCodeSending: boolean;
  isCodeVerified: boolean;
  isCodeVerifying: boolean;
  sendCodeError: Nullable<Error>;
  codeVerificationError: Nullable<Error>;

  restoreUserToken?: string;
  isRestored: boolean;
  isRestoring: boolean;
  resetError: Nullable<Error>;

  sendCodeAttemptsCount: number;
}
const initialState: RestoreUserState = {
  isCodeSending: false,
  isCodeSent: false,
  sendCodeError: null,

  isCodeVerified: false,
  isCodeVerifying: false,
  codeVerificationError: null,

  restoreUserToken: undefined,
  isRestored: false,
  isRestoring: false,
  resetError: null,

  sendCodeAttemptsCount: 0,
};

export const restoreUserReducer = (
  state = initialState,
  action: UserAction
) => {

  switch (action.type) {
    case SEND_RESTORE_USER_CODE_REQUEST:
      return {
        ...state,
        isCodeSending: true,
        isCodeSent: false,
        sendCodeError: null,
        sendCodeAttemptsCount: state.sendCodeAttemptsCount + 1,
      };

    case SEND_RESTORE_USER_CODE_SUCCESS:
      return {
        ...state,
        isCodeSending: false,
        isCodeSent: true,
      };

    case SEND_RESTORE_USER_CODE_FAILURE:
      return {
        ...state,
        isCodeSending: false,
        sendCodeError: action.error,
      };

    case VERIFY_RESTORE_USER_CODE_REQUEST:
      return {
        ...state,
        isCodeVerifying: true,
        isCodeVerified: false,
        codeVerificationError: null,
      };

    case VERIFY_RESTORE_USER_CODE_SUCCESS:
      return {
        ...state,
        isCodeVerifying: false,
        isCodeVerified: true,
        restoreUserToken: action.payload.restoreUserToken,
      };

    case VERIFY_RESTORE_USER_CODE_FAILURE:
      return {
        ...state,
        isCodeVerifying: false,
        codeVerificationError: action.error,
      };

    case RESTORE_USER_REQUEST:
      return {
        ...state,
        isRestoring: true,
        isRestored: false,
        resetError: null,
      };

    case RESTORE_USER_SUCCESS:
      return {
        ...state,
        isRestoring: false,
        isRestored: true,
      };

    case RESTORE_USER_FAILURE:
      return {
        ...state,
        isRestoring: false,
        resetError: action.error,
      };

    case RESET_REGISTRATION_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
