import {APIResponse} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

export const createCRUDAPIService = <
  EntityRead,
  EntityCreate = EntityRead,
  EntityUpdate = EntityRead
>(
  baseUrl: string
) =>
  class extends APIService {
    create = apiMethod<{id: number}>((entity: Omit<EntityCreate, 'id'>) =>
      this.fetcher.request({
        url: `${baseUrl}/`,
        method: 'post',
        data: entity,
      })
    );

    read = apiMethod<APIResponse<EntityRead>>((entityId: number, args: any[]) =>
      this.fetcher.request({
        url: `${baseUrl}/${entityId}/`,
        params: args
      })
    );

    update = apiMethod((entityId: number, entity: Omit<EntityUpdate, 'id'>) =>
      this.fetcher.request({
        url: `${baseUrl}/${entityId}/`,
        method: 'put',
        data: entity,
      })
    );

    delete = apiMethod((entityId: number) =>
      this.fetcher.request({
        url: `${baseUrl}/${entityId}/`,
        method: 'delete',
      })
    );
  };
