import {apiMethod, APIService} from "../APIService";
import {GlobalConfig} from "../../common/types";

class GlobalConfigAPI extends APIService {
  fetchConfig = apiMethod<GlobalConfig>(() =>
    this.fetcher.request({
      method: 'get',
      url: '/api/global/settings/',
    })
  );
}

export {GlobalConfigAPI};