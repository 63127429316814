import {combineReducers} from 'redux';
import {userInfoReducer} from './info';
import {loginReducer} from './login';
import {registrationReducer} from './registration';
import {resetPasswordReducer} from './reset-password';
import {restoreUserReducer} from "./restore-user";

export const userReducer = combineReducers({
  info: userInfoReducer,
  login: loginReducer,
  registration: registrationReducer,
  resetPassword: resetPasswordReducer,
  restoreUser: restoreUserReducer,
});
