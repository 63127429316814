import {
  AgentsMonthReport, ChipsCounters,
  DealsListingSearch, OffersManagementFields, OffersManagementListingItem, OfficeMonthReport, ReasonOfUnpublish, RoleId,
} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

interface FetchUnpublishReasonsPayload {
  roleId: RoleId;
}

export interface SmallDealsListing {
  fields: OffersManagementFields;
  items: OffersManagementListingItem[];
}

export class DealsAPI extends APIService {
  fetchDealsList = apiMethod((params: Partial<DealsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/deals/list/',
      params,
    }),
  );
  monthReport = apiMethod<AgentsMonthReport>((params: Partial<DealsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/deals/month-report/',
      params
    }),
  );
  officeReport = apiMethod<OfficeMonthReport>((params: Partial<DealsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/deals/office-report/',
      params
    }),
  );
  exportDealsList = (params: Partial<DealsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/deals/export-csv/',
      params,
    });
  smallDealsFetcher = apiMethod<SmallDealsListing>(() =>
    this.fetcher.request({
      method: 'get',
      url: '/api/deals/small-list/',
    }),
  );
  dealsReasonsOfUnpublishFetcher = apiMethod<ReasonOfUnpublish[]>((payload: FetchUnpublishReasonsPayload) =>
    this.fetcher.request({
      url: '/api/common/permission-reasons/',
      params: {
        groupId: payload.roleId,
        forDeals: true
      },
    }),
  );
  changeReason = apiMethod(
    (offersIds: number[], reason?: number | string) =>
      this.fetcher.request({
        method: 'get',
        url: '/api/deals/change-reason/',
        params: {
          id: offersIds.join(','),
          reason,
        },
      })
  );
  getChipsCounters = apiMethod<ChipsCounters>((params: Partial<DealsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/deals/chips-counters/',
      params,
    })
  );
}
