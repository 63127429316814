const REASON_OPEN = '@deals/reason-open';
const REASON_SELECT = '@deals/reason-select';
const RELOAD_LIST = '@deals/reload-list';

export interface ReasonOpenAction {
  type: typeof REASON_OPEN;
  payload: {
    isOpened: boolean;
  };
}

export interface ReasonSelectAction {
  type: typeof REASON_SELECT;
  payload: {
    selected: number[];
  };
}

export interface ReloadListAction {
  type: typeof RELOAD_LIST;
  payload: {
    forceListReload: boolean;
  };
}

type ReasonAction = ReasonOpenAction
  | ReasonSelectAction
  | ReloadListAction;

interface State {
  isOpened: boolean;
  selected: number[];
  forceListReload: boolean;
}

const initialState: State = {
  isOpened: false,
  selected: [],
  forceListReload: false,
};

export const reasonChangeReducer = (
  state: State = initialState,
  action: ReasonAction
) => {
  switch (action.type) {
    case REASON_OPEN:
      return {
        ...state,
        isOpened: action.payload.isOpened
      };
    case REASON_SELECT:
      return {
        ...state,
        selected: action.payload.selected
      };
    case RELOAD_LIST:
      return {
        ...state,
        forceListReload: action.payload.forceListReload
      };
    default:
      return state;
  }
};

export const openReasonDialog = (isOpened: boolean) => {
  return {
    type: REASON_OPEN,
    payload: {
      isOpened
    }
  };
};

export const selectReasonDialog = (selected: number[]) => {
  return {
    type: REASON_SELECT,
    payload: {
      selected
    }
  };
};

export const reloadDealsList = (status: boolean) => {
  return {
    type: RELOAD_LIST,
    payload: {
      forceListReload: status
    }
  };
};
