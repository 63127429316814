import {call, put, takeLatest} from 'redux-saga/effects';
import {DealsAPI} from 'services/DealsAPI';
import * as DealsActions from './actions';
import {
  FETCH_DEALS_MANAGEMENT_LISTING_REQUEST,
  FetchDealsManagementListingAction,
} from './types';

export function* fetchDealsManagementListing(
  action: FetchDealsManagementListingAction
) {
  try {
    console.log('FetchDealsManagementListingAction');
    const {params} = action.payload;

    const dealsAPI = new DealsAPI();

    const {data} = yield call(dealsAPI.fetchDealsList, params);
    yield put(DealsActions.fetchDealsManagementListingSuccess(data));
  } catch (error) {
    yield put(DealsActions.fetchDealsManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_DEALS_MANAGEMENT_LISTING_REQUEST,
    fetchDealsManagementListing
  ),
];
