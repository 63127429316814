import {Nullable, UserInfo} from 'common/types';
import {
  DELETE_PROFILE_FAILURE,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  UserAction,
} from '../types';

interface State {
  isFetching: boolean;
  isActual: boolean;
  data: Nullable<UserInfo>;
  error: Nullable<Error>;
}

const initialState = {
  isFetching: false,
  isActual: false,
  data: null,
  error: null,
};

export const userInfoReducer = (
  state: State = initialState,
  action: UserAction
): State => {
  switch (action.type) {
    case FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        isActual: false,
      };

    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.userInfo,
        isActual: true,
      };

    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,

        isActual: false,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: null,
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case DELETE_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: null,
      };

    case DELETE_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
