import {Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {AlertType, QueryParam, UserInfo} from '../../../../common/types';

const useStyles = makeStyles(theme => ({
  popupWrapper: {
    '& .MuiDialog-paperWidthSm': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  popup: {
    width: 450,
    padding: '24px 8px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '24px 0',
    },
  },
  title: {
    padding: '16px 24px',
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 20,
    cursor: 'pointer',
    '& polygon': {
      transition: 'all .9s cubic-bezier(.19,1,.22,1)',
    },
    '&:hover polygon': {
      fill: '#81f',
    },
  },
  diiaTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
}));

interface VerificationAlertProps {
  params: any;
  user: UserInfo;
}

const VerificationSuccessAlert = (
  {params, user}: VerificationAlertProps
) => {
  const classes = useStyles();

  const [alertVisible, setVisibility] = useState(false);

  useEffect(() => {
    const verificationSuccess = params[QueryParam.AlertType] === AlertType.BankidVerifiedSuccessNewRieltor;
    const bankIdAlertVisible = params[QueryParam.AlertType] === AlertType.BankidVerifiedSuccessNewRieltorNoBonus;
    const diiaAlertVisible = params[QueryParam.AlertType] === AlertType.DiiaVerifiedSuccessNewRieltorNoBonus;
    if (verificationSuccess || bankIdAlertVisible || diiaAlertVisible) {
      setVisibility(true);
    }
  }, [params]);

  return <Dialog open={alertVisible} className={classes.popupWrapper}>
    <Box className={classes.popup}>
      <Box className={classes.close} onClick={() => setVisibility(false)}>
        <svg viewBox="0 0 357 357">
          <polygon
            fill="#b6b6b6"
            points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 214.2,178.5"
          >
          </polygon>
        </svg>
      </Box>
      <DialogTitle className={classes.title}>
        <Typography variant={'h4'} className={classes.diiaTitle}>
          <FormattedMessage
            id="alerts.new_rieltor_bonus_alert.title"
            values={{username: user.username}}
          />
        </Typography>
      </DialogTitle>
      <DialogContent style={{fontSize: '16px'}}>
        <FormattedMessage
          id="alerts.bankid.bankid_verified_new_rieltor_success_no_bonus"
        />
      </DialogContent>
      <DialogActions style={{display: 'flex', justifyContent: 'center', paddingTop: '32px'}}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => setVisibility(false)}
        >
          <FormattedMessage
            id="alerts.bankid.bankid_verified_registration_finish"
          />
        </Button>
      </DialogActions>
    </Box>
  </Dialog>;
};

export default VerificationSuccessAlert;
