import {combineReducers} from 'redux';
import {chipsCountersReducer} from './chipsCounters';
import {managementReducer} from './management';
import {reasonChangeReducer} from './reasonChange';

export const dealsReducer = combineReducers({
  management: managementReducer,
  reasonChange: reasonChangeReducer,
  chipsCounters: chipsCountersReducer,
});
