import {makeStyles, Theme, Typography} from '@material-ui/core';
import cx from 'classnames';
import React, {FunctionComponent} from 'react';

export interface CoinsProps {
  label?: string | any;
  size?: 'small' | 'medium' | 'large';
  reverse?: boolean;
  single?: boolean;
  position?: 'right' | 'left';
  gray?: boolean;
}

const useStyles = makeStyles<Theme, CoinsProps>(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    // verticalAlign: 'middle',
    flexDirection: props => (props.reverse ? 'row-reverse' : 'row'),
  },
  icon: {
    marginLeft: props => (props.reverse ? theme.spacing(0.5) : 0),
    marginRight: props => (!props.reverse ? theme.spacing(0.5) : 0),
    width: props => (props.single ? 15 : theme.spacing(3)),
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
  },
  rootLarge: {
    '& $icon': {
      width: props => (props.single ? theme.spacing(2.75) : theme.spacing(4.5)),
    },
    '& $label': {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  rootSmall: {
    '& $icon': {
      width: props => (props.single ? 12 : theme.spacing(2.5)),
    },
    '& $label': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  reverse: {
    direction: 'rtl'
  }
}));

const Coins: FunctionComponent<CoinsProps> = (props: CoinsProps) => {
  const classes = useStyles(props);

  const {label = '', size = 'medium', single = false, position = 'left', gray} = props;

  return (
    <div
      className={cx(classes.root, {
        // @todo: maybe move this into makeStyles
        [classes.rootLarge]: size === 'large',
        [classes.rootSmall]: size === 'small',
        [classes.reverse]: position === 'right',
      })}
    >
      <img
        className={classes.icon}
        src={single ? require('./coin.svg') : (gray ? require('./coins_gray.svg') : require('./coins.svg'))}
        alt=""
      />
      <Typography className={classes.label} color="inherit">
        {label}
      </Typography>
    </div>
  );
};

export default Coins;
