import React, {FunctionComponent, useEffect} from 'react';

const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;

export const Recaptcha: FunctionComponent<{}> = () => {
  const handleLoaded = (a: any) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(siteKey, {action: 'homepage'});
    });
  };
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
  }, []);
  return (
    <div
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-size="invisible"
    ></div>
  );
};
