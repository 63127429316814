import '@formatjs/intl-pluralrules/polyfill';
// todo: use only needed locales
import '@formatjs/intl-pluralrules/polyfill-locales';
import '@formatjs/intl-relativetimeformat/polyfill';

import {APIError} from 'common/types';
import * as R from 'ramda';
import {IntlShape} from 'react-intl';

export * from './ConnectedIntlProvider';

export const createI18nErrorMessage = R.curry(
  (intl: IntlShape, error: Error) => {
    const {messages, formatMessage} = intl;

    if (error instanceof APIError) {
      if(error.errorType == 'custom_error'){
        return {message: error.message};
      } else {
        const id = `api_errors.${error.errorType}`;
        if (messages[id]) {
          return formatMessage({
            id,
          });
        }
      }
    }

    return formatMessage({
      id: 'errors.general',
    });
  }
);
