import {APIError, CityId, NewRieltorBonusMap, RegionId, UserInfo, UserTariffRegion} from 'common/types';
import {differenceInCalendarMonths} from 'date-fns';
import isObject from 'lodash/isObject';
import toNumber from 'lodash/toNumber';
import {CookieSetOptions} from 'universal-cookie';

const MONTHS_IN_ONE_YEAR = 12;

export const createPageTitle = (title: string) => `${title} :: Rieltor.ua`;

export const noop = () => {};

export const getDateNYearsFromNow = (n: number) =>
  new Date(Date.now() + n * 365 * 24 * 60 * 60 * 1000);

export const createSharedCookieSetOptions = (
  overrides?: CookieSetOptions
): CookieSetOptions => ({
  path: '/',
  domain: process.env.REACT_APP_SHARED_COOKIE_DOMAIN,
  expires: getDateNYearsFromNow(0.5),
  ...overrides,
});

export const isAPIValidationError = (error: Error) =>
  error instanceof APIError && isObject(error.errorType);

export const createFormValidationErrors = (error: APIError): any =>
  Object.entries(error.errorType).reduce(
    (acc, [key, value]) => ({
      ...acc,
      // @ts-ignore
      [key]: value.join('. '),
    }),
    {}
  );

export const createAPIValidationErrorFullMessage = (error: APIError): string =>
  Object.entries(error.errorType)
    // @ts-ignore
    .map(([key, value]) => value.join('. '))
    .join('\n');

export const isSafari = () =>
  !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

export const formatPhone = (phone: string, noCountryPrefix: boolean = false) => {
  const m = phone.match(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (!m) {
    return phone;
  }

  if (noCountryPrefix) {
    return `(${m[2]}) ${m[3]}-${m[4]}-${m[5]}`;
  }

  return `+${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`;
};

export const createSpecId = ({
  itemType,
  operType,
}: {
  itemType: number;
  operType: number;
}) => `${itemType}-${operType}`;

export const parseSpecId = (id: string) => {
  const [itemType, operType] = id.split('-').map(toNumber);

  return {itemType, operType};
};

export const createTelLink = (phone: string | number) => `tel:+${phone}`;

export const createMailtoLink = (email: string) => `mailto:${email}`;

export const createUserWebsiteLink = (baseUrl: string) =>
  /^https?:\/\//i.test(baseUrl) ? baseUrl : `http://${baseUrl}`;

export const getUserExperienceInYearsAndMonths = ({startedAt}: UserInfo) => {
  if (!startedAt) {
    return null;
  }

  const diffInCalendarMonths = differenceInCalendarMonths(
    new Date(),
    new Date(startedAt)
  );
  const years = Math.floor(diffInCalendarMonths / MONTHS_IN_ONE_YEAR);
  const months = diffInCalendarMonths % MONTHS_IN_ONE_YEAR;

  return {years, months};
};

export const createCurrencyFieldFormatFn = (exchangeRate: number) => (
  value: number
) => String(parseFloat((value * exchangeRate).toFixed(1))) || '';

export const createCurrencyFieldParseFn = (exchangeRate: number) => (
  value: string
) => parseFloat((parseFloat(value) / exchangeRate).toFixed(0)) || 0;

export const getUserTariff = (cityId?: number, regionId?: number) => {
  if(cityId && cityId === CityId.Kyiv){
    return UserTariffRegion.Kiev;
  }
  if(cityId && cityId === CityId.Lviv){
    return UserTariffRegion.Lviv;
  }
  if(regionId === RegionId.Kyiv) {
    return UserTariffRegion.KievRegion;
  }
  if(cityId && [CityId.Lviv, CityId.Chernihiv, CityId.Dnipro, CityId.Kharkiv, CityId.Odessa, CityId.Zaporizhzhia].includes(cityId)){
    return UserTariffRegion.Top;
  }

  return UserTariffRegion.Regions;
}

export const getNewUserBonus = (newRieltorBonuses: NewRieltorBonusMap, userTariff?: string,  createdAt?: string, fallbackBonusDate?: string) => {
  const bonusItem = newRieltorBonuses ? newRieltorBonuses[userTariff || UserTariffRegion.Regions] : null;
  const active = !!(bonusItem && bonusItem.bonus && new Date(bonusItem.date).getTime() <= new Date(createdAt ? createdAt : '').getTime());
  const isNewUser = new Date(fallbackBonusDate || '2024-04-15 09:00:00').getTime() <= new Date(createdAt ? createdAt : '').getTime()

  return {active, bonus: bonusItem?.bonus! /10 || 0, hasProgram: !!bonusItem, isNewUser};
}
