import {Box, Link as MuiLink, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import ReferralCoins from '../ReferralCoins';
import cx from "classnames";
import {useSelector} from "react-redux";
import {
  referralProgramPaymentBonus,
  referralProgramRegisterBonus
} from "../../../store/global/selectors";

const COUNTER_ID = 'referrals-counter';

interface ReferralStepsProps {
  short?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: 13,
    marginBottom: 18,
    color: '#fff',
    fontFamily: 'Inter',
    counterReset: COUNTER_ID,
  },
  titleWr:{
    display: 'flex',
    marginBottom: 15,
    alignItems: 'center'
  },
  title: {

    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    fontSize: 14,
    lineHeight: 1.57,
    fontWeight: 400,
    letterSpacing: '-0.01em',
  },
  headerImageMob:{
    display:'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: 107
    }
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    fontSize: 14,
    lineHeight: 1.57,
    fontWeight: 400,
    letterSpacing: '-0.01em',
    marginBottom: 14,

    '& a': {
      color: '#FFCB2B',
      fontWeight: 700,
      textDecoration: 'none'
    },
    '& b': {
      fontWeight: 700
    },
    '&::before': {
      counterIncrement: COUNTER_ID,
      content: `counter(${COUNTER_ID})`,
      width: 32,
      height: 32,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      border: '2px solid #FFCB2B',
      fontSize: 14,
      color: '#FFCB2B',
      marginRight: 22,
      fontWeight: 700
    }
  },
  shortSteps: {
    '& $step': {
      marginBottom: 0,
    },
    '& $step::before': {
      content: `"•"`,
      border: 'none',
      color: '#FFFFFF',
      margin: 0
    },
  }
}));


const ReferralSteps = ({short = false}: ReferralStepsProps) => {
  const classes = useStyles();
  const {formatMessage: fm} = useIntl();

  const referralBonus = useSelector(referralProgramRegisterBonus);
  const referralPaymentBonus = useSelector(referralProgramPaymentBonus);

  return (
    <Box className={classes.root}>
      <Box className={classes.titleWr}>
        <Box className={classes.title}>{fm({id: 'referrals.new_step_title'})}</Box>

        {!short && <img
            src={require('./../icons/icon_header.png')}
            srcSet={`${require('./../icons/icon_header.png')} 1x, ${require('./../icons/icon_header_2x.png')} 2x`}
            alt=""
            className={classes.headerImageMob}
        />}
      </Box>

      <Box className={cx(classes.steps, {[classes.shortSteps]: short})}>
        <Box className={classes.step}>
          <span>
            <FormattedMessage id={'referrals.new_step_1'} values={{
              rieltor: <MuiLink className={classes.socialLink} href={"https://rieltor.ua/"}>Rieltor.ua</MuiLink>
            }}/>
          </span>
        </Box>
        <Box className={classes.step}>
          <span>
            <FormattedMessage id={'referrals.new_step_2'} values={{coins: <ReferralCoins value={(referralBonus || 0)/10}/>}}/>
          </span>
        </Box>
        <Box className={classes.step}>
          <span>
            <FormattedMessage id={'referrals.new_step_3'}
                              values={{percent: <ReferralCoins value={(referralPaymentBonus || 0)} unit={'%'} icon={''}/>}}/>
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default ReferralSteps;
