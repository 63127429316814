import {
  AgencyFeedListingSearch, AgencyFeedManagementFields , OffersManagementListingItem
} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

export interface AgencyFeedListing {
  fields: AgencyFeedManagementFields;
  items: OffersManagementListingItem[];
}

export class AgencyFeedAPI extends APIService {
  fetchAgencyFeedList = apiMethod((params: Partial<AgencyFeedListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/agency-feed/list/',
      params,
    }),
  );
 
  importDraft = apiMethod(
    (id: string) =>
      this.fetcher.request({
        method: 'put',
        url: `/api/agency-feed/import/${id}/`,
      })
  );

}
