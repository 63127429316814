import React, {ComponentType, FunctionComponent, useMemo} from 'react';
import ReactGA, {EventArgs} from 'react-ga';

export interface TrackableWrapperProps {
  gaEvent: EventArgs;
  triggerEvent?: string;
}

export const makeTrackable = <P extends any>(
  WrappedComponent: ComponentType<P> | string
) => {
  // @todo: fix Own types which should be different for <tag/> and <Component/>

  type MergedProps = TrackableWrapperProps & P;
  const TrackableWrapper: FunctionComponent<MergedProps> = ({
    gaEvent,
    triggerEvent = 'onClick',
    ...rest
  }: MergedProps) => {
    // @ts-ignore
    const originalCallback: React.EventHandler | undefined = rest[triggerEvent];

    const additionalProps = useMemo(
      () => ({
        [triggerEvent]: (...args: any[]) => {
          ReactGA.event(gaEvent);
          if (originalCallback) {
            originalCallback(...args);
          }
        },
      }),
      // eslint-disable-next-line
      [originalCallback]
    );
    return <WrappedComponent {...(rest as P)} {...additionalProps} />;
  };

  return TrackableWrapper;
};
