import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Locale} from 'common/types';
import dateFnsLocaleRu from 'date-fns/locale/ru';
import dateFnsLocaleUk from 'date-fns/locale/uk';
import {usePageviewTracker} from 'modules/ga';
import {compose} from 'ramda';
import React, {FunctionComponent, lazy, Suspense} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import GuestRoute from 'screens/common/GuestRoute';
import Loading from 'screens/common/Loading';
import MainLayout from 'screens/MainLayout';
import {AppState} from 'store';
import * as I18nSelectors from 'store/i18n/selectors';

const LOCALE_MAP = {
  [Locale.RU]: dateFnsLocaleRu,
  [Locale.UK]: dateFnsLocaleUk,
};

const mapStateToProps = (state: AppState) => ({
  locale: I18nSelectors.locale(state),
});

const LoginScreen = lazy(() => import('screens/LoginScreen'));
const ReferralParkingScreen = lazy(() => import('screens/ReferralParkingScreen'));
const RegistrationScreen = lazy(() => import('screens/RegistrationScreen'));
const RegistrationInfoScreen = lazy(
  () => import('screens/RegistrationInfoScreen')
);
const ResetPasswordScreen = lazy(() => import('screens/ResetPasswordScreen'));
const RestoreUserScreen = lazy(() => import('screens/RestoreUserScreen'));
const HosingCostCalculationRequestScreen = lazy(
  () => import('screens/HosingCostCalculationRequestScreen')
);

interface RootProps
  extends RouteComponentProps,
    ReturnType<typeof mapStateToProps> {}

const Root: FunctionComponent<RootProps> = ({location, locale}: RootProps) => {
  usePageviewTracker(location);

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={LOCALE_MAP[locale as Locale]}
    >
      <>
        <Helmet>
          <html lang={locale as string} />
        </Helmet>
        <Suspense fallback={<Loading />}>
          <Switch>
            <GuestRoute path="/login" exact component={LoginScreen} />
            <GuestRoute path="/r/:phone" exact component={ReferralParkingScreen} />
            <GuestRoute
              path="/registration"
              exact
              component={RegistrationScreen}
            />
            <GuestRoute
              path="/restore-user"
              exact
              component={RestoreUserScreen}
            />
            <GuestRoute
              path="/registration/info"
              exact
              component={RegistrationInfoScreen}
            />
            <GuestRoute
              path="/reset-password"
              exact
              component={ResetPasswordScreen}
            />
            <Route
              path="/housing-cost-calculation-request"
              exact
              component={HosingCostCalculationRequestScreen}
            />
            <Route
              path={[
                '/offers',
                '/profile',
                '/agents-suggestions',
                '/payment',
                '/deals',
                '/referrals', '/dashboard',
                '/partners',
                '/unions',
                '/olx-migration',
                '/rieltor-manifest',
                '/internet-offers',
                '/agency-feed',
              ]}
              component={MainLayout}
            />
            <Redirect to="/login" />
          </Switch>
        </Suspense>
      </>
    </MuiPickersUtilsProvider>
  );
};

const EnhancedRoot = compose(
  withRouter,
  // @ts-ignore
  connect(mapStateToProps)
)(Root);

export default EnhancedRoot;
