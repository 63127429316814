import {UserInfo, UserLevelStatus} from './types';

export const ucFirst = (string: string): string => {
  if (string.length === 0) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const userLevelStatus = (user: UserInfo): string => {
  return user.levelStatus in UserLevelStatus
    ? ucFirst(user.levelStatusName.toLowerCase()) + ` partner`
    : user.role;
};
