import {call, put, takeLatest} from 'redux-saga/effects';
import {UserAgenciesAPI} from '../../services/UserAgenciesAPI';
// import {UserAgenciesAPI} from 'services/AgentsAPI';
import * as AgentsActions from './actions';
import {
  FETCH_AGENTS_MANAGEMENT_LISTING_REQUEST,
  FetchAgentsManagementListingAction,
} from './types';

export function* fetchAgentsManagementListing(
  action: FetchAgentsManagementListingAction
) {
  try {
    console.log('FetchAgentsManagementListingAction');
    const {params} = action.payload;

    const agentsAPI = new UserAgenciesAPI();

    const {data} = yield call(agentsAPI.fetchLinkedUsers, params);
    yield put(AgentsActions.fetchAgentsManagementListingSuccess(data));
  } catch (error) {
    yield put(AgentsActions.fetchAgentsManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_AGENTS_MANAGEMENT_LISTING_REQUEST,
    fetchAgentsManagementListing
  ),
];
