import {
  AlertStatusPostData,
  APIResponse, DealsListingSearch, GiftAddressInfo, GiftAddressPayload, Nullable, OfferChipsInfo,
  ProfileInfoFormData,
  ProfileInfoPayload, SocialSettingsFormData,
  UserInfo,
} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

type UploadProgressCallback = (progress: number) => void;

export interface AvatarUploadInfo {
  imageKey: string;
  isPerson: boolean;
}

export interface DiiaLinkRequest {
  deepLink: string;
  requestId: string;
}
export interface DiiaAuthStatus {
  state: string;
  inn: string;
}
export interface AnBgUploadInfo {
  imageKey: string;
}

interface SendResetPasswordCodePayload {
  phone: string;
  retry?: number;
  recaptcha: string;
}

interface SendRestoreUserCodePayload {
  phone: string;
  retry?: number;
  recaptcha: string;
}

interface ChangeEmailPayload {
  newEmail: string;
}

interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

interface AnStylesPayload {
  textColor: string;
  img: string;
}

class UsersAPI extends APIService {
  checkRegisterId = apiMethod(
    (id: string) => this.fetcher.request({
      url: `/api/users/check-register-id/${id}/`,
      method: 'get',
    })
  );
  getReferralInfo = apiMethod<string>((referral: string) =>
    this.fetcher.request({
      url: `/api/users/referral-info/${referral}/`,
      method: 'get',
    })
  );
  generateBankidAuthLink = apiMethod<string>((renew: boolean = false) =>
    this.fetcher.request({
      url: '/api/users/generate-bankid-auth-link/',
      method: 'get',
      params: {
        renew,
      },
    })
  );
  generateDiiaAuthLink = apiMethod<DiiaLinkRequest>((isSm: number, renew: boolean = false) =>
    this.fetcher.request({
      url: '/api/users/generate-diia-auth-link/',
      method: 'get',
      params: {
        mobile: isSm,
        renew,
      },
    }),
  );

  checkDiiaAuth = apiMethod<DiiaAuthStatus>((requestId: string) =>
    this.fetcher.request({
      url: '/api/users/check-diia-auth/',
      method: 'get',
      params: {
        requestId,
      },
    }),
  );

  uploadAvatar = apiMethod<AvatarUploadInfo>(
    (file: File, onProgress?: UploadProgressCallback) => {
      const formData = new FormData();
      formData.append('file', file);

      return this.fetcher.request({
        url: '/api/users/upload-avatar/',
        method: 'post',
        data: formData,
        onUploadProgress: onProgress
          ? event => {
              const percentage = Math.floor((event.loaded * 100) / event.total);
              onProgress(percentage);
            }
          : undefined,
      });
    }
  );
  uploadAnBg = apiMethod<AnBgUploadInfo>(
    (file: File, onProgress?: UploadProgressCallback) => {
      const formData = new FormData();
      formData.append('file', file);

      return this.fetcher.request({
        url: '/api/users/upload-anbg/',
        method: 'post',
        data: formData,
        onUploadProgress: onProgress
          ? event => {
              const percentage = Math.floor((event.loaded * 100) / event.total);
              onProgress(percentage);
            }
          : undefined,
      });
    }
  );

  login = apiMethod((phone: string, password: string, utmParams?: Nullable<{}>) =>
    this.fetcher.request({
      url: '/api/users/login-password/',
      method: 'post',
      data: {
        phone,
        password,
        utmParams
      },
    })
  );

  sendResetPasswordCode = apiMethod(
    ({phone, retry = 0, recaptcha = ''}: SendResetPasswordCodePayload) =>
      this.fetcher.request({
        url: '/api/users/reset-password-sms/',
        method: 'post',
        data: {
          phone,
          retry,
          recaptcha
        },
      })
  );

  confirmResetPasswordCode = apiMethod<
    APIResponse<{
      resetPasswordToken: string;
    }>
  >((phone: string, code: string) =>
    this.fetcher.request({
      url: '/api/users/reset-password-confirm-sms/',
      method: 'post',
      data: {
        phone,
        code,
      },
    })
  );

  resetPassword = apiMethod((resetPasswordToken: string, password: string) =>
    this.fetcher.request({
      url: '/api/users/reset-password/',
      method: 'post',
      data: {
        resetPasswordToken,
        password,
      },
    })
  );

  sendRestoreUserCode = apiMethod(
    ({phone, retry = 0, recaptcha = ''}: SendRestoreUserCodePayload) =>
      this.fetcher.request({
        url: '/api/users/restore-user-sms/',
        method: 'post',
        data: {
          phone,
          retry,
          recaptcha
        },
      })
  );

  confirmRestoreUserCode = apiMethod<
    APIResponse<{
      restoreUserToken: string;
    }>
  >((phone: string, code: string) =>
    this.fetcher.request({
      url: '/api/users/restore-user-confirm-sms/',
      method: 'post',
      data: {
        phone,
        code,
      },
    })
  );

  restoreUser = apiMethod((restoreUserToken: string) =>
    this.fetcher.request({
      url: '/api/users/restore-user/',
      method: 'post',
      data: {
        restoreUserToken,
      },
    })
  );

  restoreUserByDiia = apiMethod((requestId: string) =>
    this.fetcher.request({
      url: '/api/users/restore-user-by-diia/',
      method: 'post',
      data: {
        requestId: requestId,
      },
    })
  );

  getRestoreDiiaInfo = apiMethod<UserInfo>((requestId: string) =>
    this.fetcher.request({
      url: '/api/users/restore-diia-info/',
      method: 'get',
      params: {
        requestId: requestId,
      },
    })
  );

  getRestoreBankidInfo = apiMethod<UserInfo>((requestId: string) =>
    this.fetcher.request({
      url: '/api/users/restore-bankid-info/',
      method: 'get',
      params: {
        requestId: requestId,
      },
    })
  );

  restoreUserByBankid = apiMethod((requestId: string) =>
    this.fetcher.request({
      url: '/api/users/restore-user-by-bankid/',
      method: 'post',
      data: {
        requestId: requestId,
      },
    })
  );

  fetchUserInfo = apiMethod<UserInfo>(() =>
    this.fetcher.request({
      url: '/api/users/info/',
    })
  );

  logout = apiMethod(() =>
    this.fetcher.request({
      method: 'get',
      url: '/api/users/logout/',
    })
  );

  deleteProfile = apiMethod(() =>
    this.fetcher.request({
      method: 'delete',
      url: '/api/users/',
    })
  );

  changeEmail = apiMethod((payload: ChangeEmailPayload) =>
    this.fetcher.request({
      url: '/api/users/change-email/',
      method: 'post',
      data: payload,
    })
  );

  validateStep = apiMethod((step: string, values: object) =>
    this.fetcher.request({
      url: '/api/users/validate-step/',
      method: 'post',
      data: {
        step,
        values
      },
    })
  );

  changeAllowShare = apiMethod((state: boolean) =>
    this.fetcher.request({
      method: 'post',
      data: {
        value: state
      },
      url: '/api/users/allow-share/',
    })
  );

  updateSettings = apiMethod((name: string, value: any) =>
    this.fetcher.request({
      method: 'post',
      data: {
        [name]: value
      },
      url: '/api/users/update-settings/',
    })
  );

  changePassword = apiMethod((payload: ChangePasswordPayload) =>
    this.fetcher.request({
      url: '/api/users/change-password/',
      method: 'post',
      data: payload,
    })
  );

  updateAnStyles = apiMethod((payload: AnStylesPayload) =>
    this.fetcher.request({
      url: '/api/users/an-styles/',
      method: 'post',
      data: payload,
    })
  );

  fetchUserProfileInfo = apiMethod<ProfileInfoFormData>(() =>
    this.fetcher.request({
      url: '/api/users/change-info/',
      method: 'get',
    })
  );

  fetchUserSocialSettings = apiMethod<SocialSettingsFormData>(
    () => this.fetcher.request({
      url: '/api/users/social-settings/',
      method: 'get',
    })
  );

  updateUserSocialSettings = apiMethod<SocialSettingsFormData>(
    (payload: any) => this.fetcher.request({
      url: '/api/users/social-settings/',
      method: 'post',
      data: payload
    })
  );

  changeUserProfileInfo = apiMethod((payload: ProfileInfoPayload) =>
    this.fetcher.request({
      url: '/api/users/change-info/',
      method: 'post',
      data: payload,
    })
  );

  changeGiftAddress = apiMethod((payload: GiftAddressPayload) =>
    this.fetcher.request({
      url: '/api/users/gift-address/',
      method: 'post',
      data: payload,
    })
  );

  fetchGiftAddress = apiMethod<GiftAddressInfo>(() =>
    this.fetcher.request({
      url: '/api/users/gift-address/',
      method: 'get',
    })
  );

  incrementShowContacts = apiMethod((userId: number) =>
    this.fetcher.request({
      url: `/sc_inc/${userId}/`,
    })
  );

  requestFreeOffers = apiMethod(() =>
    this.fetcher.request({
      method: 'post',
      url: '/api/users/free-offers/',
    })
  );

  olxMigrate = apiMethod((url: string) =>
    this.fetcher.request({
      method: 'post',
      url: '/api/users/add-olx/',
      data: {
        url
      }
    })
  );

  updateAlertStatus = apiMethod((data: AlertStatusPostData) =>
    this.fetcher.request({
      method: 'post',
      url: '/api/users/update-alert-status/',
      data
    })
  );

  generateManifest = apiMethod((index: number) =>
    this.fetcher.request({
      method: 'post',
      url: '/api/users/generate-manifest/',
      params: {
        index
      }
    })
  );

  offerChips = apiMethod<OfferChipsInfo>(() =>
    this.fetcher.request({
      url: '/api/users/offer-chips/',
      method: 'get',
    })
  );
}

export {UsersAPI};
