const MEDIA_STORAGE_BASE_URL = 'https://img.rieltor.ua';

const createMediaStorageAbsoluteUrl = (path: string) =>
  `${MEDIA_STORAGE_BASE_URL}${path}`;

export const createResizedImageUrl = (
  imageKey: string,
  width: number,
  height: number
) => createMediaStorageAbsoluteUrl(`/crop/${width}x${height}/${imageKey}`);

export const createSrcSet = (...urls: string[]) =>
  urls.map((url, index) => `${url} ${index + 1}x`).join(', ');
export const createRequireSrcSet = (...urls: string[]) =>
  urls.map((url, index) => `${require(url)} ${index + 1}x`).join(', ');
