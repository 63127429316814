import {ReferralsManagementListing} from 'common/types';

export const FETCH_REFERRALS_MANAGEMENT_LISTING_REQUEST =
  '@referrals/fetch-referrals-management-request';
export const FETCH_REFERRALS_MANAGEMENT_LISTING_SUCCESS =
  '@referrals/fetch-referrals-management-success';
export const FETCH_REFERRALS_MANAGEMENT_LISTING_FAILURE =
  '@referrals/fetch-referrals-management-failure';

export interface FetchReferralsManagementListingAction {
  type: typeof FETCH_REFERRALS_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchReferralsManagementListingSuccessAction {
  type: typeof FETCH_REFERRALS_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: ReferralsManagementListing;
  };
}

export interface FetchReferralsManagementListingFailureAction {
  type: typeof FETCH_REFERRALS_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type ReferralsAction =
  | FetchReferralsManagementListingAction
  | FetchReferralsManagementListingSuccessAction
  | FetchReferralsManagementListingFailureAction;
