import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
import {closeLogoutDialog, closeMobileMenu, openLogoutDialog, openMobileMenu} from 'store/common/reducers/header';

export const useMobileMenu = (): [boolean, () => void, () => void] => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state: AppState) => state.common.header.mobileMenuOpen);

  const open = () => dispatch(openMobileMenu);
  const close = () => dispatch(closeMobileMenu);

  return [isMenuOpen, open, close];
};

export const useLogoutConfirmation = (): [boolean, () => void, () => void] => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state: AppState) => state.common.header.logoutDialogOpen);

  const open = () => dispatch(openLogoutDialog);
  const close = () => dispatch(closeLogoutDialog);

  return [isMenuOpen, open, close];
};
