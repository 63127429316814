import {OlxImportManagementListing} from 'common/types';

export const FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST =
  '@olxImport/fetch-olxImport-management-request';
export const FETCH_OLXIMPORT_MANAGEMENT_LISTING_SUCCESS =
  '@olxImport/fetch-olxImport-management-success';
export const FETCH_OLXIMPORT_MANAGEMENT_LISTING_FAILURE =
  '@olxImport/fetch-olxImport-management-failure';

export interface FetchOlxImportManagementListingAction {
  type: typeof FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchOlxImportManagementListingSuccessAction {
  type: typeof FETCH_OLXIMPORT_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: OlxImportManagementListing;
  };
}

export interface FetchOlxImportManagementListingFailureAction {
  type: typeof FETCH_OLXIMPORT_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type OlxImportAction =
  | FetchOlxImportManagementListingAction
  | FetchOlxImportManagementListingSuccessAction
  | FetchOlxImportManagementListingFailureAction;
