import {GlobalConfig} from "../../common/types";

export const FETCH_GLOBAL_CONFIG_REQUEST =
  '@user/fetch-global-config-request';
export const FETCH_GLOBAL_CONFIG_SUCCESS =
  '@user/fetch-global-config-success';
export const FETCH_GLOBAL_CONFIG_FAILURE =
  '@user/fetch-global-config-failure';

export interface FetchGlobalConfigAction {
  type: typeof FETCH_GLOBAL_CONFIG_REQUEST;
}

export interface FetchGlobalConfigSuccessAction {
  type: typeof FETCH_GLOBAL_CONFIG_SUCCESS;
  payload: {
    globalConfig: GlobalConfig;
  };
}

export interface FetchGlobalConfigFailureAction {
  type: typeof FETCH_GLOBAL_CONFIG_FAILURE;
  error: Error;
}

export type GlobalConfigAction =
  | FetchGlobalConfigAction
  | FetchGlobalConfigSuccessAction
  | FetchGlobalConfigFailureAction;