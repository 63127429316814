import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {makeTrackable} from 'modules/ga';
import React from 'react';
import {FormattedMessage as FM} from 'react-intl';
import {UsersAPI} from 'services/UsersAPI';
import {createLoginLink} from 'utils/navigation';
import {useLogoutConfirmation} from './hooks';

const TrackableButton = makeTrackable(Button);

const LogoutConfirmation = () => {
  const [isOpen, , close] = useLogoutConfirmation();

  return (
    <Dialog
      open={isOpen}
      onClose={close}
    >
      <DialogTitle>
        <FM id="side_navigation.logout_confirmation.title"/>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FM id="side_navigation.logout_confirmation.text"/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TrackableButton
          onClick={() => {
            (new UsersAPI()).logout().finally(() => document.location.href = createLoginLink({}));
          }}
          gaEvent={{
            category: 'side_navigation',
            action: 'logout_confirm',
          }}
        >
          <FM id="side_navigation.logout_confirmation.logout"/>
        </TrackableButton>
        <TrackableButton
          color="primary"
          onClick={close}
          gaEvent={{
            category: 'side_navigation',
            action: 'logout_cancel',
          }}
        >
          <FM id="side_navigation.logout_confirmation.cancel"/>
        </TrackableButton>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmation;
