import {combineReducers} from 'redux';
import {chipsCountersReducer} from './chipsCounters';
import {managementReducer} from './management';
import {partnerReducer} from './partners';

export const partnersReducer = combineReducers({
  management: managementReducer,
  partners: partnerReducer,
  chips: chipsCountersReducer,
});
