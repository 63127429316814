import {UserInfo} from 'common/types';
import {AppState} from 'store';

const DELETE_DIALOG_ITEM = '@agents/delete-dialog-item';
const GIVE_COINS_DIALOG_ITEM = '@agents/give-coins-dialog-item';
const CHANGE_OFFICE_DIALOG_ITEM = '@agents/change-office-dialog-item';
const FORCE_LIST_RELOAD = '@agents/force-list-reload';

export interface DeleteDialogOpenAction {
  type: typeof DELETE_DIALOG_ITEM;
  payload: {
    agentItem: null | UserInfo;
  };
}

export interface GiveCoinsDialogOpenAction {
  type: typeof GIVE_COINS_DIALOG_ITEM;
  payload: {
    agentItem: null | UserInfo;
  };
}

export interface ChangeOfficeDialogOpenAction {
  type: typeof CHANGE_OFFICE_DIALOG_ITEM;
  payload: {
    agentItem: null | UserInfo;
  };
}

export interface ForceListReloadAction {
  type: typeof FORCE_LIST_RELOAD;
  payload: {
    forceListReload: boolean;
  };
}

type AgentDialogAction = DeleteDialogOpenAction
  | GiveCoinsDialogOpenAction
  | ChangeOfficeDialogOpenAction
  | ForceListReloadAction;

interface State {
  deleteItem: null | UserInfo;
  giveCoinsItem: null | UserInfo;
  changeOfficeItem: null | UserInfo;
  forceListReload: boolean;
}

const initialState: State = {
  deleteItem: null,
  giveCoinsItem: null,
  changeOfficeItem: null,
  forceListReload: false,
};

export const agentDialogReducer = (state: State = initialState, action: AgentDialogAction) => {
  switch (action.type) {
    case DELETE_DIALOG_ITEM:
      return {
        ...state,
        deleteItem: action.payload.agentItem,
      };
    case GIVE_COINS_DIALOG_ITEM:
      return {
        ...state,
        giveCoinsItem: action.payload.agentItem,
      };
    case CHANGE_OFFICE_DIALOG_ITEM:
      return {
        ...state,
        changeOfficeItem: action.payload.agentItem,
      };
    case FORCE_LIST_RELOAD:
      return {
        ...state,
        forceListReload: action.payload.forceListReload,
      };
    default:
      return state;
  }
};

export const setDeleteDialogItem = (item: null | UserInfo) => {
  return {
    type: DELETE_DIALOG_ITEM,
    payload: {
      agentItem: item
    }
  };
};

export const setGiveCoinsDialogItem = (item: null | UserInfo) => {
  return {
    type: GIVE_COINS_DIALOG_ITEM,
    payload: {
      agentItem: item
    }
  };
};

export const setChangeOfficeDialogItem = (item: null | UserInfo) => {
  return {
    type: CHANGE_OFFICE_DIALOG_ITEM,
    payload: {
      agentItem: item
    }
  };
};

export const setListReload = (status: boolean) => {
  return {
    type: FORCE_LIST_RELOAD,
    payload: {
      forceListReload: status
    }
  };
};

export const agentDialogState = (state: AppState) => state.agents.dialogs;
