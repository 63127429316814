import {AgencyFeedManagementListing} from 'common/types';

export const FETCH_AGENCYFEED_MANAGEMENT_LISTING_REQUEST =
  '@agencyFeed/fetch-agencyFeed-management-request';
export const FETCH_AGENCYFEED_MANAGEMENT_LISTING_SUCCESS =
  '@agencyFeed/fetch-agencyFeed-management-success';
export const FETCH_AGENCYFEED_MANAGEMENT_LISTING_FAILURE =
  '@agencyFeed/fetch-agencyFeed-management-failure';

export interface FetchAgencyFeedManagementListingAction {
  type: typeof FETCH_AGENCYFEED_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchAgencyFeedManagementListingSuccessAction {
  type: typeof FETCH_AGENCYFEED_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: AgencyFeedManagementListing;
  };
}

export interface FetchAgencyFeedManagementListingFailureAction {
  type: typeof FETCH_AGENCYFEED_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type AgencyFeedAction =
  | FetchAgencyFeedManagementListingAction
  | FetchAgencyFeedManagementListingSuccessAction
  | FetchAgencyFeedManagementListingFailureAction;
