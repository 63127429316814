import {Nullable} from 'common/types';
import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_REGISTRATION_STATE,
  SEND_RESET_PASSWORD_CODE_FAILURE,
  SEND_RESET_PASSWORD_CODE_REQUEST,
  SEND_RESET_PASSWORD_CODE_SUCCESS,
  UserAction,
  VERIFY_RESET_PASSWORD_CODE_FAILURE,
  VERIFY_RESET_PASSWORD_CODE_REQUEST,
  VERIFY_RESET_PASSWORD_CODE_SUCCESS,
} from '../types';

interface ResetPasswordState {
  isCodeSent: boolean;
  isCodeSending: boolean;
  isCodeVerified: boolean;
  isCodeVerifying: boolean;
  sendCodeError: Nullable<Error>;
  codeVerificationError: Nullable<Error>;

  resetPasswordToken?: string;
  isResetting: boolean;
  isResetted: boolean;
  resetError: Nullable<Error>;

  sendCodeAttemptsCount: number;
}
const initialState: ResetPasswordState = {
  isCodeSending: false,
  isCodeSent: false,
  sendCodeError: null,

  isCodeVerified: false,
  isCodeVerifying: false,
  codeVerificationError: null,

  resetPasswordToken: undefined,
  isResetting: false,
  isResetted: false,
  resetError: null,

  sendCodeAttemptsCount: 0,
};

export const resetPasswordReducer = (
  state = initialState,
  action: UserAction
) => {
  switch (action.type) {
    case SEND_RESET_PASSWORD_CODE_REQUEST:
      return {
        ...state,
        isCodeSending: true,
        isCodeSent: false,
        sendCodeError: null,
        sendCodeAttemptsCount: state.sendCodeAttemptsCount + 1,
      };

    case SEND_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        isCodeSending: false,
        isCodeSent: true,
      };

    case SEND_RESET_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        isCodeSending: false,
        sendCodeError: action.error,
      };

    case VERIFY_RESET_PASSWORD_CODE_REQUEST:
      return {
        ...state,
        isCodeVerifying: true,
        isCodeVerified: false,
        codeVerificationError: null,
      };

    case VERIFY_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        isCodeVerifying: false,
        isCodeVerified: true,
        resetPasswordToken: action.payload.resetPasswordToken,
      };

    case VERIFY_RESET_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        isCodeVerifying: false,
        codeVerificationError: action.error,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetting: true,
        isResetted: false,
        resetError: null,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetting: false,
        isResetted: true,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetting: false,
        resetError: action.error,
      };

    case RESET_REGISTRATION_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
