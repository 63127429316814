import * as Sentry from '@sentry/browser';
import {APIError} from 'common/types';
import ReactGA from 'react-ga';
import {takeEvery} from 'redux-saga/effects';
import {Action} from 'store/types';
import {
  FETCH_USER_INFO_SUCCESS,
  FetchUserInfoSuccessAction,
  LOGOUT_SUCCESS,
} from 'store/user/types';

const errorActionPattern = (action: Action) => /-failure$/.test(action.type);

export function trackError(action: Action) {
  // @ts-ignore
  const {error} = action;

  const isAPIError = error instanceof APIError;

  if (error && !isAPIError) {
    ReactGA.exception({
      description: error.message,
    });

    Sentry.withScope(scope => {
      // @ts-ignore
      scope.setTag('ads_blocked', window.__ads_blocked__);

      Sentry.captureException(error);
    });
  }
}

export function setUser(action: FetchUserInfoSuccessAction) {
  const {userInfo} = action.payload;
  Sentry.setUser({
    username: userInfo.username,
    id: String(userInfo.userId),
    email: userInfo.email,
  });
}

export function unsetUser() {
  Sentry.setUser(null);
}

export default [
  takeEvery(errorActionPattern, trackError),
  takeEvery(FETCH_USER_INFO_SUCCESS, setUser),
  takeEvery(LOGOUT_SUCCESS, unsetUser),
];
