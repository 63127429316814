import React, {FunctionComponent, useMemo, useRef, useState} from 'react';
import {Box, Button, makeStyles, Theme, Typography, useMediaQuery,Link as MuiLink} from '@material-ui/core';
import {useIntl} from "react-intl";
import {APIResponseStatus, LocalStorageKeys, RoleId} from "../../../common/types";
import cx from "classnames";
import {createLegacyInterfaceUrl, createOfferCreateUrl, createRieltorManifestUrl} from "../../../utils/navigation";
import {ResizedImage} from "../../../components/ResizedImage";
import {CurrentUser} from "../../common/CurrentUser";
import {useSnackbar} from "notistack";
import {UsersAPI} from "../../../services/UsersAPI";
import copy from "copy-to-clipboard";
import {makeTrackable} from "../../../modules/ga";
import {theme} from "../../../styles/theme";
import {FacebookShareButton} from "react-share";
import {useLocalStorage} from "@rehooks/local-storage";
import {Link as RouterLink} from "react-router-dom";

const TrackableButton = makeTrackable(Button);

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    // minHeight: '100vh',
    borderRadius: 4,
    background: 'linear-gradient(180deg, #7D1FF5 0%, #DA48C5 99.98%)',
    margin: 10,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingRight: 0,
    },
    "&.-alert": {
      minHeight: 'auto',
    }
  },
  number: {
    color: '#FFCB2B',
    border: '2px solid #FFCB2B',
    borderRadius: '50%',
    width: 32,
    height: 32,
    minWidth: 32,
    minHeight: 32,
    maxWidth: 32,
    maxHeight: 32,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    color: 'white',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: 600
  },
  sentenceItem: {
    display: 'flex',
    // alignItems: 'center',
    border: '1px solid white',
    borderRadius: 4,
    padding: "10px 20px",
    marginBottom: 18,
    cursor: 'pointer',
    "&.-active": {
      backgroundColor: '#FFCB2B',
      "& $text": {
        color: 'black !important',
      },
      "& $number": {
        borderColor: 'black',
        color: 'black'
      }
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 71,
    marginRight: 71,
    justifyContent: "space-between",
    marginTop: 21,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 20,
    }
  },
  imgWrapper: {
    position: 'relative',
    width: '400px',
    height: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '200px',
    }
  },
  avatar: {
    maxWidth: '100%',
    height: 'auto',
  },
  baseImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    height: 'auto'
  },
  text1: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      marginBottom: 20,
      marginTop: 20,
    }
  },
  text1Link: {
    color: 'white',
    position: 'relative',
    display: 'inline-block',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  text2: {
    fontWeight: 700,
    fontSize: 18,
    marginTop: 20,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      fontWeight: 500,
    }
  },
  text3: {
    fontWeight: 500,
    fontSize: 16,
    whiteSpace: 'pre-wrap',
    lineHeight: '25px',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      fontWeight: 500,
    }
  },
  textWrapper: {
    marginLeft: 40,
  },
  headerWrapper: {
    position: 'relative',
    color: 'white',
    paddingTop: 18,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    // justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15,
      marginRight: 15,
      alignItems: 'flex-start',
    },
    "&.-alert": {
      alignItems: 'flex-start',
      marginLeft: 71,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 15,
      },
      "& $text1": {
        fontSize: 24
      },
      "& $text2": {
        fontSize: 18
      },
      "& $textWrapper": {
        marginLeft: 0
      }
    }
  },
  imgPhoto: {
    width: 120,
    height: 120,
    marginLeft: 71,
    // position: "absolute",
    // top: 0,
    // left: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  imgLighting: {
    width: 72,
    height: 72,
    // position: "absolute",
    // top: 20,
    // right: 40,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      // right: 0,
      // top: 109
    }
  },
  headerText: {
    fontWeight: 700,
    fontSize: 36,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      marginTop: 20,
    }
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    background: '#FFCB2B',
    fontWeight: 700,
    fontSize: 16,
    height: 37,
    justifyContent: 'center',
    cursor: 'pointer',
    minWidth: 227,
    position: 'relative',
    "&:last-child": {
      marginLeft: 14
    },
    "& svg": {
      marginRight: 10
    },
    [theme.breakpoints.down('sm')]: {
      "&:last-child": {
        marginLeft: 0,
        marginTop: 10
      },
      "& svg": {
        position: 'absolute',
        left: 8,
        top: 6
      },
    }
  },
  expandButton: {
    color: 'white',
    borderColor: 'white',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 36,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  variantsWrapper: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginTop: 20
    }
  },
  mobileVariantTitle: {
    display: 'none',
    textAlign: 'center',
    color: 'white',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  no_result_title: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000'
  },
  noResultWrapper: {
    [theme.breakpoints.up('xs')]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  },
  userWrapper: {
    marginLeft: 40,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 20
    }
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    zIndex: 1,
    display: 'block',
  },
  alert188: {
    background: '#F0F1FA',
    margin: 10,
    padding: 20,
    paddingLeft: 71,
    "& a": {
      color: 'black !important',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
    }
  }
}));

interface RieltorManifestProps {
  alertMode?: boolean;
  visibleAlert?: boolean;
  alertCloseHandler?: () => void;
}

const RieltorManifest: FunctionComponent<RieltorManifestProps> = ({
                                                                    alertMode = false,
                                                                    visibleAlert = false,
                                                                    alertCloseHandler
                                                                  }: RieltorManifestProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const {formatMessage: fm} = intl;

  const [currentSentence, setCurrentSentence] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shareLink, setShareLink] = useState('');

  const _snackbar = useSnackbar();
  const snackbar = useMemo(() => _snackbar, []);

  const api = useMemo(() => new UsersAPI(), []);
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const expandedLimit = isDownSm ? 1 : 3;

  const items = [
    'Рієлтор шукає не просто квадратні метри, рієлтор допомагає людям знайти своє справжнє житло.',
    'Рієлтор завжди ставить на перше місце інтереси клієнта.',
    'Рієлтор несе відповідальність. За свої слова, свої дії, за свій підпис.',
    'Рієлтор цінує свою репутацію, тому веде свої справи чесно і відкрито.',
    'Рієлтор допомагає людям отримати краще від нерухомості.',
    'Рієлтор поважає своїх колег і колеги поважають рієлтора.',
    'Рієлтор дбає про свій розвиток: професійний та особистісний.',
    'Рієлтор виступає проти дискримінації людей за будь-якими ознаками та не працює з людьми, що проявляють дискримінацію.',
    'Рієлтор робить все можливе, щоб рієлторів поважали у суспільстві.',
    'Багато хто називає себе рієлтором. Та не кожен може бути рієлтором.'
  ];

  const sharingEnabled = false;

  const downloadImage = async () => {
    setLoading(true);
    fetch(createLegacyInterfaceUrl('/api/users/download-manifest/?index=' + currentSentence), {
      credentials: 'include'
    })
      .then((response: any) => response.blob())
      // .then((response:any) => response.data)
      .then((blob: any) => {
        var url = window.URL.createObjectURL(new Blob([blob], {type: 'image/jpg'}));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute(
          'download',
          `manifest.jpg`,
        );

        document.body.appendChild(link);

        link.click();

        // @ts-ignore
        link.parentNode.removeChild(link);
        setLoading(false);
      }).catch(() => {
      setLoading(false);
    });
  }

  const shareImage = async () => {
    setShareLink('');
    setLoading(true);
    const response = await api.generateManifest(currentSentence);
    if (response.status === APIResponseStatus.Ok) {
      setLoading(false);
      const link = response.data;
      if (link) {
        // setShareLink(link);
        // if (buttonRef && buttonRef.current) {
        //   // @ts-ignore
        //   buttonRef.current.click();
        // }
        let shareData = {
          title: 'Посилання',
          text: items[currentSentence] + ' ' + fm({id: 'rieltor_manifest_screen.i_am_rieltor'}),
          url: link,
        };
        if ((window.navigator as any).canShare && (window.navigator as any).canShare(shareData)) {
          (window.navigator as any).share(shareData)
        } else {
          copy(link);
          snackbar.enqueueSnackbar(fm({id: 'rieltor_manifest_screen.copy'}), {
            key: 'copy_referral',
          });
        }
      }
    }
  }
  const buttonRef = useRef(null);

  if (alertMode && !visibleAlert) {
    return null;
  }

  return (
    <>
      <CurrentUser>
        {({user, setUIPriceCurrency, fetchUserInfo}) => {
          if (!user) {
            return null;
          }
          if (user.roleId !== RoleId.Realtor) {
            return null;
          }


          return (
            <>
              <Box
                className={cx(classes.root, {['-alert']: alertMode})}
                bgcolor="primary.main"
                display="flex"
                flexDirection="column"
              >
                {alertMode &&
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                         onClick={() => alertCloseHandler? alertCloseHandler() : ()=>{}}
                         className={classes.closeBtn}>
                        <path
                            d="M16.2234 2.08117L10.1624 8.14209L16.2234 14.203L14.2031 16.2233L8.14214 10.1624L2.08122 16.2233L0.0609167 14.203L6.12183 8.14209L0.0609146 2.08117L2.08122 0.0608681L8.14214 6.12178L14.203 0.0608667L16.2234 2.08117Z"
                            fill="white"/>
                    </svg>}
                <Box className={cx(classes.headerWrapper, {['-alert']: alertMode})}>
                  {!alertMode && <img src={require('./images/camera.png')} className={classes.imgPhoto}/>}
                  <Box className={classes.textWrapper}>
                    <Typography className={classes.text1} align="left">
                      {fm({id: 'rieltor_manifest_screen.text_generic'},
                        {link: alertMode ?
                            <MuiLink href={'https://www.youtube.com/watch?v=Gj3ZFEn2Zko&embeds_referring_euri=https%3A%2F%2Fmy.rieltor.ua%2F&source_ve_path=OTY3MTQ&feature=emb_imp_woyt'} target='_blank' className={classes.text1Link}>{fm({id: 'rieltor_manifest_screen.text1_link'})}</MuiLink> :
                            fm({id: 'rieltor_manifest_screen.text1_link'})})
                      }
                    </Typography>
                    <Typography className={classes.text2} align="left">
                      {fm({id: 'rieltor_manifest_screen.text2_generic'})}
                    </Typography>
                    {/*<Typography className={classes.text3} align="left">*/}
                    {/*  {fm({id: 'rieltor_manifest_screen.text3_rieltor_day'})}*/}
                    {/*</Typography>*/}
                  </Box>
                </Box>
                <Box className={classes.row}>
                  <Box className={classes.variantsWrapper}>
                    <Typography
                      className={classes.mobileVariantTitle}>{fm({id: 'rieltor_manifest_screen.mobile_variant_title'})}</Typography>
                    {items.splice(0, !isExpanded && alertMode ? expandedLimit : 100).map((item, index) =>
                      <Box key={index}
                           className={cx(classes.sentenceItem, {['-active']: index == currentSentence})}
                           onClick={() => setCurrentSentence(index)}>
                        <Box className={classes.number}>{index + 1}</Box>
                        <Box className={classes.text}>
                          {item}
                          <br/>
                          {fm({id: 'rieltor_manifest_screen.i_am_rieltor'})}
                        </Box>
                      </Box>)
                    }
                    {alertMode && !isExpanded && <TrackableButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        className={classes.expandButton}
                        gaEvent={{
                          category: 'rieltor_manifest',
                          action: 'expand_items',
                        }} variant={'outlined'}>
                      {fm({id: 'rieltor_manifest_screen.expand_items_button'})}
                    </TrackableButton>}
                    {alertMode && isExpanded && <TrackableButton
                      // @ts-ignore
                        component={RouterLink}
                        to={createRieltorManifestUrl({})}
                        className={classes.expandButton}
                        gaEvent={{
                          category: 'rieltor_manifest',
                          action: 'goto_section',
                        }} variant={'outlined'}>
                      {fm({id: 'rieltor_manifest_screen.goto_section_button'})}
                    </TrackableButton>}
                  </Box>
                  <Box display={"flex"} alignItems={'center'} flexDirection={'column'}
                       className={classes.userWrapper}>
                    <Box className={classes.imgWrapper}>
                      <img src={(createLegacyInterfaceUrl('/img/manifest/' + (currentSentence + 1) + '.png'))}
                           className={classes.baseImg}/>
                      {user.avatar ?
                        <ResizedImage width={400} height={400} imageKey={user.avatar} className={classes.avatar}
                                      densities={[1]}/> :
                        <img src={require('screens/common/assets/placeholder.svg')} width={400}/>}
                    </Box>
                    <Box className={classes.buttonWrapper}>
                      {<TrackableButton className={classes.actionButton} onClick={() => downloadImage()}
                                        disabled={loading || !user.avatar}
                                        gaEvent={{
                                          category: 'rieltor_manifest',
                                          action: 'download',
                                        }}
                                        variant="contained">
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5" focusable="false"
                             aria-hidden="true" viewBox="0 0 24 24" data-testid="DownloadIcon">
                          <path d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"></path>
                        </svg>
                        {fm({id: 'rieltor_manifest_screen.download_button'})}
                      </TrackableButton>}

                      {sharingEnabled &&
                          <TrackableButton className={classes.actionButton} onClick={() => shareImage()}
                                           disabled={loading}
                                           gaEvent={{
                                             category: 'rieltor_manifest',
                                             action: 'share',
                                           }}
                                           variant="contained">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M12.3414 17.9821V18.4821H12.8414H17.1725C17.8689 18.4821 18.4323 17.9151 18.4323 17.2201V2.28586C18.4323 1.59088 17.8689 1.02391 17.1725 1.02391H2.27953C1.58311 1.02391 1.01969 1.59088 1.01969 2.28586V17.2201C1.01969 17.9151 1.58311 18.4821 2.27953 18.4821H10.4867H10.9867V17.9821V11.7722V11.2722H10.4867H8.70632V9.91012H10.4867H10.9867V9.41012V7.77855C10.9867 6.11191 12.3253 4.76694 13.9811 4.76694H15.0388V6.12899H13.9811C13.0588 6.12899 12.3414 6.85038 12.3414 7.77192V9.41012V9.91012H12.8414H14.988L14.7643 11.2722H12.8414H12.3414V11.7722V17.9821ZM2.27953 0.5H17.1725C18.152 0.5 18.952 1.30027 18.952 2.28586V17.2201C18.952 18.2057 18.152 19.006 17.1725 19.006H2.27953C1.30005 19.006 0.5 18.2057 0.5 17.2201V2.28586C0.5 1.30027 1.30005 0.5 2.27953 0.5Z"
                                      fill="black" stroke="black"/>
                              </svg>

                            {fm({id: 'rieltor_manifest_screen.share_button'})}
                          </TrackableButton>}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {!alertMode && <div dangerouslySetInnerHTML={{__html: '<style type="text/css">.container {\n' +
                  '    display: flex;\n' +
                  '    column-gap: 16px;\n' +
                  '    row-gap: 32px;\n' +
                  '    flex-direction: row;\n' +
                  '  }\n' +
                  '\n' +
                  '  .text {\n' +
                  '    flex: 1;\n' +
                  '  }\n' +
                  '\n' +
                  '  .video {\n' +
                  '   flex: 1;\n' +
                  'text-align: right;\n' +
                  '  }\n' +
                  '\n' +
                  '\n' +
                  '  @media (max-width: 900px) {\n' +
                  '    .container {\n' +
                  '      flex-direction: column;\n' +
                  '    }\n' +
                  '\n' +
                  '    .video {\n' +
                  '      margin-left: 0;\n' +
                  '\n' +
                  '    }\n' +
                  '.video iframe {\n' +
                  'width: 100% !important;\n' +
                  'height: auto;\n' +
                  'aspect-ratio: 560/315;\n' +
                  '\n' +
                  '}\n' +
                  '  }\n' +
                  '</style>\n' +
                  '<div class="container">\n' +
                  '<div class="text"><b data-stringify-type="bold">МАНІФЕСТ &laquo;Я &ndash; RIELTOR&raquo;</b><br />\n' +
                  '<br />\n' +
                  'Ми всі прагнемо працювати в умовах чесного, цивілізованого ринку. Тому на базі Кодексу Етики ми розробили рекламну кампанію Маніфест &laquo;Я &ndash; RIELTOR&raquo;. Потужні слова, підкріплені реальними діями &ndash; такий шлях обирають чесні рієлтори.<br />\n' +
                  '<br />\n' +
                  'Закликаємо всіх рієлторів підтримати маніфест, щоб зробити ринок максимально чесним та відкритим!<br />\n' +
                  '<br />\n' +
                  'Щоб підтримати Маніфест:<br />\n' +
                  '1) поставте лайк у Facebook та поділіться постом:&nbsp;<strong><a data-sk="tooltip_parent" data-stringify-link="https://www.facebook.com/story.php?story_fbid=pfbid02tt9yt7NYez9LDNytaouo6a6ZTzzZN1MUWp5dhAA4VS1EpHcrohh2HpqZnyvHV7sEl&amp;id=100063640683767&amp;paipv=0&amp;eav=AfZc3WFsGb9mwID_XDl72aJF1HoiEpoWWuq0xNu-r1R4ZfjA0LeKOY_KcFA7_rkkzkY&amp;_rdr" delay="150" href="https://www.facebook.com/story.php?story_fbid=pfbid02tt9yt7NYez9LDNytaouo6a6ZTzzZN1MUWp5dhAA4VS1EpHcrohh2HpqZnyvHV7sEl&amp;id=100063640683767&amp;paipv=0&amp;eav=AfZc3WFsGb9mwID_XDl72aJF1HoiEpoWWuq0xNu-r1R4ZfjA0LeKOY_KcFA7_rkkzkY&amp;_rdr" rel="noopener noreferrer" style="color:white" target="_blank">Facebook: Маніфест &quot;Я &ndash; RIELTOR&quot;</a></strong><br />\n' +
                  '2) поставте лайк в Instagram та поділіться постом:&nbsp;<strong><a data-sk="tooltip_parent" data-stringify-link="https://www.instagram.com/reel/C3h2q3rxdZy/?utm_source=ig_web_copy_link&amp;igsh=MzRlODBiNWFlZA==" delay="150" href="https://www.instagram.com/reel/C3h2q3rxdZy/?utm_source=ig_web_copy_link&amp;igsh=MzRlODBiNWFlZA==" rel="noopener noreferrer" style="color:white" target="_blank">Instagram: Маніфест &quot;Я &ndash; RIELTOR&quot;</a></strong><br />\n' +
                  '3) відправте колегам у Viber і Telegram:<strong>&nbsp;<a data-sk="tooltip_parent" data-stringify-link="https://www.youtube.com/watch?v=Gj3ZFEn2Zko" delay="150" href="https://www.youtube.com/watch?v=Gj3ZFEn2Zko" rel="noopener noreferrer" style="color:white" target="_blank">Youtube: Маніфест &quot;Я &ndash; RIELTOR&quot;</a></strong></div>\n' +
                  '\n' +
                  '<div class="video"><!-- Ваше відео тут --><iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" frameborder="0" height="315" src="https://www.youtube.com/embed/Gj3ZFEn2Zko?si=YOt0e5_TzPBSQ4L3" title="YouTube video player" width="560"></iframe></div>\n' +
                  '</div>\n'}} className={cx(classes.alert188)}/>}
            </>
          );
        }}
      </CurrentUser>
    </>
  );
};

export default RieltorManifest;
