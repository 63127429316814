import {AgentsManagementListing} from 'common/types';
import {
  FETCH_AGENTS_MANAGEMENT_LISTING_FAILURE,
  FETCH_AGENTS_MANAGEMENT_LISTING_REQUEST,
  FETCH_AGENTS_MANAGEMENT_LISTING_SUCCESS,
  FetchAgentsManagementListingAction,
  FetchAgentsManagementListingFailureAction,
  FetchAgentsManagementListingSuccessAction,
} from './types';

export const fetchAgentsManagementListing = (
  params: any
): FetchAgentsManagementListingAction => ({
  type: FETCH_AGENTS_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchAgentsManagementListingSuccess = (
  data: AgentsManagementListing
): FetchAgentsManagementListingSuccessAction => ({
  type: FETCH_AGENTS_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAgentsManagementListingFailure = (
  error: Error
): FetchAgentsManagementListingFailureAction => ({
  type: FETCH_AGENTS_MANAGEMENT_LISTING_FAILURE,
  error,
});
