import {all} from 'redux-saga/effects';
import watchCommon from './common/sagas';
import watchError from './errors';
import watchI18n from './i18n/sagas';
import watchOffers from './offers/sagas';
import watchDeals from './deals/sagas';
import watchUser from './user/sagas';
import watchAgents from './agents/sagas';
import watchPartners from './partners/sagas';
import watchReferrals from './referrals/sagas';
import watchGlobalConfig from './global/sagas';
import watchOlxImport from './olx-import/sagas';
import watchAgencyFeed from './agency-feed/sagas';

export function* rootSaga() {
  yield all([
    ...watchCommon,
    ...watchError,
    ...watchI18n,
    ...watchUser,
    ...watchOffers,
    ...watchDeals,
    ...watchAgents,
    ...watchPartners,
    ...watchReferrals,
    ...watchGlobalConfig,
    ...watchOlxImport,
    ...watchAgencyFeed,
  ]);
}
