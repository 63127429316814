import {call, put, takeLatest} from 'redux-saga/effects';
import {OlxImportAPI} from 'services/OlxImportAPI';
import * as OlxImportActions from './actions';
import {
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST,
  FetchOlxImportManagementListingAction,
} from './types';

export function* fetchOlxImportManagementListing(
  action: FetchOlxImportManagementListingAction
) {
  try {
    console.log('FetchOlxImportManagementListingAction');
    const {params} = action.payload;

    const olxImportAPI = new OlxImportAPI();

    const {data} = yield call(olxImportAPI.fetchOlxImportList, params);
    yield put(OlxImportActions.fetchOlxImportManagementListingSuccess(data));
  } catch (error) {
    yield put(OlxImportActions.fetchOlxImportManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST,
    fetchOlxImportManagementListing
  ),
];
