import {Nullable, OfferCommonFields} from 'common/types';
import {
  CommonAction,
  FETCH_OFFER_OPTIONS_FAILURE,
  FETCH_OFFER_OPTIONS_REQUEST,
  FETCH_OFFER_OPTIONS_SUCCESS,
} from '../types';

interface State {
  data: Nullable<OfferCommonFields>;
  isFetching: boolean;
  error: Nullable<Error>;
}

const initialState: State = {
  data: null,
  isFetching: false,
  error: null,
};

export const offerOptionsReducer = (
  state: State = initialState,
  action: CommonAction
) => {
  switch (action.type) {
    case FETCH_OFFER_OPTIONS_REQUEST:
      return {
        ...state,

        error: null,
        isFetching: true,
      };

    case FETCH_OFFER_OPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.offerOptions,
        isFetching: false,
      };

    case FETCH_OFFER_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
