import {Locale} from 'common/types';

export const SET_LOCALE = 'i18n/set-locale';

export interface SetLocaleAction {
  type: typeof SET_LOCALE;
  payload: {
    locale: Locale;
  };
}

export const FETCH_I18N_MESSAGES_REQUEST = '@i18n/fetch-i18n-messages-request';
export const FETCH_I18N_MESSAGES_SUCCESS = '@i18n/fetch-i18n-messages-success';
export const FETCH_I18N_MESSAGES_FAILURE = '@i18n/fetch-i18n-messages-failure';

export interface FetchI18nMessagesAction {
  type: typeof FETCH_I18N_MESSAGES_REQUEST;
  payload: {
    locale: Locale;
  };
}

export interface FetchI18nMessagesSuccessAction {
  type: typeof FETCH_I18N_MESSAGES_SUCCESS;
  payload: {
    locale: Locale;
    messages: Record<string, string>;
  };
}

export interface FetchI18nMessagesFailureAction {
  type: typeof FETCH_I18N_MESSAGES_FAILURE;
  error: Error;
}

export type I18nAction =
  | SetLocaleAction
  | FetchI18nMessagesAction
  | FetchI18nMessagesSuccessAction
  | FetchI18nMessagesFailureAction;
