import {useDispatch, useSelector} from 'react-redux';
import {
  PERIOD_QUARTER,
} from 'screens/profile/ProfileStatsScreen/PeriodSwitcher';
import {AppState} from '../../index';

const PERIOD_FILTER = '@profile/stats-period-filter';

export interface ProfileStatsAction {
  type: typeof PERIOD_FILTER;
  payload: {
    periodFilter: string
  };
}

interface State {
  periodFilter: string;
}

const initialState: State = {
  periodFilter: PERIOD_QUARTER,
};

export const statsReducer = (
  state: State = initialState,
  action: ProfileStatsAction,
) => {
  switch (action.type) {
    case PERIOD_FILTER:
      return {
        ...state,
        periodFilter: action.payload.periodFilter,
      };

    default:
      return state;
  }
};

export const setPeriodFilter = (period: string) => {
  return {
    type: PERIOD_FILTER,
    payload: {
      periodFilter: period,
    },
  };
};

export const usePeriodFilter = (): [
  string,
  (period: string) => void
] => {
  const periodState = useSelector(
    (state: AppState) => state.profile.stats.periodFilter);
  const dispatch = useDispatch();

  const setPeriod = (period: string) => {
    dispatch(setPeriodFilter(period));
  };

  return [
    periodState,
    setPeriod,
  ];
};
