import {call, put, takeLatest} from 'redux-saga/effects';
import {PartnersAPI} from '../../services/PartnersService';
import * as PartnersActions from './actions';
import {
  FETCH_PARTNERS_MANAGEMENT_LISTING_REQUEST,
  FetchPartnersManagementListingAction,
} from './types';

export function* fetchPartnersManagementListing(
  action: FetchPartnersManagementListingAction
) {
  try {
    console.log('FetchPartnersManagementListingAction');
    const {params} = action.payload;

    const partnersAPI = new PartnersAPI();

    const {data} = yield call(partnersAPI.fetchPartners, params);
    yield put(PartnersActions.fetchPartnersManagementListingSuccess(data));
  } catch (error) {
    yield put(PartnersActions.fetchPartnersManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_PARTNERS_MANAGEMENT_LISTING_REQUEST,
    fetchPartnersManagementListing
  ),
];
