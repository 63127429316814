import {AgentsManagementListing} from 'common/types';

export const FETCH_AGENTS_MANAGEMENT_LISTING_REQUEST =
  '@agents/fetch-agents-management-request';
export const FETCH_AGENTS_MANAGEMENT_LISTING_SUCCESS =
  '@agents/fetch-agents-management-success';
export const FETCH_AGENTS_MANAGEMENT_LISTING_FAILURE =
  '@agents/fetch-agents-management-failure';

export interface FetchAgentsManagementListingAction {
  type: typeof FETCH_AGENTS_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchAgentsManagementListingSuccessAction {
  type: typeof FETCH_AGENTS_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: AgentsManagementListing;
  };
}

export interface FetchAgentsManagementListingFailureAction {
  type: typeof FETCH_AGENTS_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type AgentsAction =
  | FetchAgentsManagementListingAction
  | FetchAgentsManagementListingSuccessAction
  | FetchAgentsManagementListingFailureAction;
