import {ReferralsManagementListing} from 'common/types';
import {
  FETCH_REFERRALS_MANAGEMENT_LISTING_FAILURE,
  FETCH_REFERRALS_MANAGEMENT_LISTING_REQUEST,
  FETCH_REFERRALS_MANAGEMENT_LISTING_SUCCESS,
  FetchReferralsManagementListingAction,
  FetchReferralsManagementListingFailureAction,
  FetchReferralsManagementListingSuccessAction,
} from './types';

export const fetchReferralsManagementListing = (
  params: any
): FetchReferralsManagementListingAction => ({
  type: FETCH_REFERRALS_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchReferralsManagementListingSuccess = (
  data: ReferralsManagementListing
): FetchReferralsManagementListingSuccessAction => ({
  type: FETCH_REFERRALS_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchReferralsManagementListingFailure = (
  error: Error
): FetchReferralsManagementListingFailureAction => ({
  type: FETCH_REFERRALS_MANAGEMENT_LISTING_FAILURE,
  error,
});
