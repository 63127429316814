import {AgencyFeedManagementListing} from 'common/types';
import {
  FETCH_AGENCYFEED_MANAGEMENT_LISTING_FAILURE,
  FETCH_AGENCYFEED_MANAGEMENT_LISTING_REQUEST,
  FETCH_AGENCYFEED_MANAGEMENT_LISTING_SUCCESS,
  FetchAgencyFeedManagementListingAction,
  FetchAgencyFeedManagementListingFailureAction,
  FetchAgencyFeedManagementListingSuccessAction,
} from './types';

export const fetchAgencyFeedManagementListing = (
  params: any
): FetchAgencyFeedManagementListingAction => ({
  type: FETCH_AGENCYFEED_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchAgencyFeedManagementListingSuccess = (
  data: AgencyFeedManagementListing
): FetchAgencyFeedManagementListingSuccessAction => ({
  type: FETCH_AGENCYFEED_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAgencyFeedManagementListingFailure = (
  error: Error
): FetchAgencyFeedManagementListingFailureAction => ({
  type: FETCH_AGENCYFEED_MANAGEMENT_LISTING_FAILURE,
  error,
});
