import {ReferralInfo, UserInfo, Nullable} from 'common/types';
import {getReferralInfo} from './actions';

export const RESET_LOGIN_STATE = '@user/reset-login-state';

export interface ResetLoginStateAction {
  type: typeof RESET_LOGIN_STATE;
}

export const GET_REFERRAL_INFO_REQUEST =
  '@user/get-referral-info-request';
export const GET_REFERRAL_INFO_SUCCESS =
  '@user/get-referral-info-success';
export const GET_REFERRAL_INFO_FAILURE =
  '@user/get-referral-info-failure';


export interface GetReferralInfoAction {
  type: typeof GET_REFERRAL_INFO_REQUEST;
  payload: {
    referral: string;
  };
}

export interface GetReferralInfoSuccessAction {
  type: typeof GET_REFERRAL_INFO_SUCCESS;
  payload: ReferralInfo
}

export interface GetReferralInfoFailureAction {
  type: typeof GET_REFERRAL_INFO_FAILURE;
  error: Error;
}

export const SEND_REGISTRATION_CODE_REQUEST =
  '@user/send-registration-code-request';
export const SEND_REGISTRATION_CODE_SUCCESS =
  '@user/send-registration-code-success';
export const SEND_REGISTRATION_CODE_FAILURE =
  '@user/send-registration-code-failure';

export interface SendRegistrationCodeAction {
  type: typeof SEND_REGISTRATION_CODE_REQUEST;
  payload: {
    phone: string;
    recaptcha: string;
  };
}

export interface SendRegistrationCodeSuccessAction {
  type: typeof SEND_REGISTRATION_CODE_SUCCESS;
}

export interface SendRegistrationCodeFailureAction {
  type: typeof SEND_REGISTRATION_CODE_FAILURE;
  error: Error;
}

export const VERIFY_REGISTRATION_CODE_REQUEST =
  '@user/verify-registration-code-request';
export const VERIFY_REGISTRATION_CODE_SUCCESS =
  '@user/verify-registration-code-success';
export const VERIFY_REGISTRATION_CODE_FAILURE =
  '@user/verify-registration-code-failure';

export const RESET_REGISTRATION_STATE = '@user/reset-registration-state';

export interface VerifyRegistrationCodeAction {
  type: typeof VERIFY_REGISTRATION_CODE_REQUEST;
  payload: {
    phone: string;
    code: string;
  };
}

export interface VerifyRegistrationCodeSuccessAction {
  type: typeof VERIFY_REGISTRATION_CODE_SUCCESS;
  payload: {
    registerId: string;
  };
}

export interface VerifyRegistrationCodeFailureAction {
  type: typeof VERIFY_REGISTRATION_CODE_FAILURE;
  error: Error;
}

export interface ResetRegistrationStateAction {
  type: typeof RESET_REGISTRATION_STATE;
}

export const LOGIN_REQUEST = '@user/login-request';
export const LOGIN_SUCCESS = '@user/login-success';
export const LOGIN_FAILURE = '@user/login-failure';

export interface LoginAction {
  type: typeof LOGIN_REQUEST;
  payload: {
    phone: string;
    password: string;
    utmParams?: Nullable<{}>;
  };
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  error: Error;
}

export const SEND_RESET_PASSWORD_CODE_REQUEST =
  '@user/send-reset-password-code-request';
export const SEND_RESET_PASSWORD_CODE_SUCCESS =
  '@user/send-reset-password-code-success';
export const SEND_RESET_PASSWORD_CODE_FAILURE =
  '@user/send-reset-password-code-failure';

export interface SendResetPasswordCodeAction {
  type: typeof SEND_RESET_PASSWORD_CODE_REQUEST;
  payload: {
    phone: string,
    recaptcha: string
  };
}

export interface SendResetPasswordCodeSuccessAction {
  type: typeof SEND_RESET_PASSWORD_CODE_SUCCESS;
}

export interface SendResetPasswordCodeFailureAction {
  type: typeof SEND_RESET_PASSWORD_CODE_FAILURE;
  error: Error;
}

export const VERIFY_RESET_PASSWORD_CODE_REQUEST =
  '@user/verify-reset-password-code-request';
export const VERIFY_RESET_PASSWORD_CODE_SUCCESS =
  '@user/verify-reset-password-code-success';
export const VERIFY_RESET_PASSWORD_CODE_FAILURE =
  '@user/verify-reset-password-code-failure';

export const RESET_RESET_PASSWORD_STATE = '@user/reset-reset-password-state';

export interface VerifyResetPasswordCodeAction {
  type: typeof VERIFY_RESET_PASSWORD_CODE_REQUEST;
  payload: {
    phone: string;
    code: string;
  };
}

export interface VerifyResetPasswordCodeSuccessAction {
  type: typeof VERIFY_RESET_PASSWORD_CODE_SUCCESS;
  payload: {
    resetPasswordToken: string;
  };
}

export interface VerifyResetPasswordCodeFailureAction {
  type: typeof VERIFY_RESET_PASSWORD_CODE_FAILURE;
  error: Error;
}

export interface ResetResetPasswordStateAction {
  type: typeof RESET_RESET_PASSWORD_STATE;
}

export const RESET_PASSWORD_REQUEST = '@user/reset-password-request';
export const RESET_PASSWORD_SUCCESS = '@user/reset-password-success';
export const RESET_PASSWORD_FAILURE = '@user/reset-password-failure';

export interface ResetPasswordAction {
  type: typeof RESET_PASSWORD_REQUEST;
  payload: {
    password: string;
    resetPasswordToken: string;
  };
}

export interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE;
  error: Error;
}

//--------------------------------------------
export const SEND_RESTORE_USER_CODE_REQUEST =
  '@user/send-restore-user-code-request';
export const SEND_RESTORE_USER_CODE_SUCCESS =
  '@user/send-restore-user-code-success';
export const SEND_RESTORE_USER_CODE_FAILURE =
  '@user/send-restore-user-code-failure';

export interface SendRestoreUserCodeAction {
  type: typeof SEND_RESTORE_USER_CODE_REQUEST;
  payload: {
    phone: string,
    recaptcha: string
  };
}

export interface SendRestoreUserCodeSuccessAction {
  type: typeof SEND_RESTORE_USER_CODE_SUCCESS;
}

export interface SendRestoreUserCodeFailureAction {
  type: typeof SEND_RESTORE_USER_CODE_FAILURE;
  error: Error;
}

export const VERIFY_RESTORE_USER_CODE_REQUEST =
  '@user/verify-restore-user-code-request';
export const VERIFY_RESTORE_USER_CODE_SUCCESS =
  '@user/verify-restore-user-code-success';
export const VERIFY_RESTORE_USER_CODE_FAILURE =
  '@user/verify-restore-user-code-failure';

export const RESET_RESTORE_USER_STATE = '@user/reset-restore-user-state';

export interface VerifyRestoreUserCodeAction {
  type: typeof VERIFY_RESTORE_USER_CODE_REQUEST;
  payload: {
    phone: string;
    code: string;
  };
}

export interface VerifyRestoreUserCodeSuccessAction {
  type: typeof VERIFY_RESTORE_USER_CODE_SUCCESS;
  payload: {
    restoreUserToken: string;
  };
}

export interface VerifyRestoreUserCodeFailureAction {
  type: typeof VERIFY_RESTORE_USER_CODE_FAILURE;
  error: Error;
}

export interface ResetRestoreUserStateAction {
  type: typeof RESET_RESTORE_USER_STATE;
}

export const RESTORE_USER_REQUEST = '@user/restore-user-request';
export const RESTORE_USER_SUCCESS = '@user/restore-user-success';
export const RESTORE_USER_FAILURE = '@user/restore-user-failure';

export interface RestoreUserAction {
  type: typeof RESTORE_USER_REQUEST;
  payload: {
    restoreUserToken: string;
  };
}

export interface RestoreUserSuccessAction {
  type: typeof RESTORE_USER_SUCCESS;
}

export interface RestoreUserFailureAction {
  type: typeof RESTORE_USER_FAILURE;
  error: Error;
}
//--------------------------------------------

export const FETCH_USER_INFO_REQUEST = '@user/fetch-user-info-request';
export const FETCH_USER_INFO_SUCCESS = '@user/fetch-user-info-success';
export const FETCH_USER_INFO_FAILURE = '@user/fetch-user-info-failure';

export interface FetchUserInfoAction {
  type: typeof FETCH_USER_INFO_REQUEST;
}

export interface FetchUserInfoSuccessAction {
  type: typeof FETCH_USER_INFO_SUCCESS;
  payload: {
    userInfo: UserInfo;
  };
}

export interface FetchUserInfoFailureAction {
  type: typeof FETCH_USER_INFO_FAILURE;
  error: Error;
}

export const LOGOUT_REQUEST = '@user/logout-request';
export const LOGOUT_SUCCESS = '@user/logout-success';
export const LOGOUT_FAILURE = '@user/logout-failure';

export interface LogoutAction {
  type: typeof LOGOUT_REQUEST;
}

export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export interface LogoutFailureAction {
  type: typeof LOGOUT_FAILURE;
  error: Error;
}

export const DELETE_PROFILE_REQUEST = '@user/delete-profile-request';
export const DELETE_PROFILE_SUCCESS = '@user/delete-profile-success';
export const DELETE_PROFILE_FAILURE = '@user/delete-profile-failure';

export interface DeleteProfileAction {
  type: typeof DELETE_PROFILE_REQUEST;
}

export interface DeleteProfileSuccessAction {
  type: typeof DELETE_PROFILE_SUCCESS;
}

export interface DeleteProfileFailureAction {
  type: typeof DELETE_PROFILE_FAILURE;
  error: Error;
}

export type UserAction =
  | DeleteProfileAction
  | DeleteProfileFailureAction
  | DeleteProfileSuccessAction
  | FetchUserInfoAction
  | FetchUserInfoFailureAction
  | FetchUserInfoSuccessAction
  | LoginAction
  | LoginFailureAction
  | LoginSuccessAction
  | LogoutAction
  | LogoutFailureAction
  | LogoutSuccessAction
  | ResetLoginStateAction
  | ResetPasswordAction
  | ResetPasswordFailureAction
  | ResetPasswordSuccessAction
  | ResetRegistrationStateAction
  | SendRegistrationCodeAction
  | SendRegistrationCodeFailureAction
  | SendRegistrationCodeSuccessAction
  | SendResetPasswordCodeAction
  | SendResetPasswordCodeFailureAction
  | SendResetPasswordCodeSuccessAction
  | VerifyRegistrationCodeAction
  | VerifyRegistrationCodeFailureAction
  | VerifyRegistrationCodeSuccessAction
  | VerifyResetPasswordCodeAction
  | VerifyResetPasswordCodeFailureAction
  | VerifyResetPasswordCodeSuccessAction
  | GetReferralInfoAction
  | GetReferralInfoFailureAction
  | GetReferralInfoSuccessAction
  | VerifyRestoreUserCodeAction
  | VerifyRestoreUserCodeFailureAction
  | VerifyRestoreUserCodeSuccessAction
  | SendRestoreUserCodeAction
  | SendRestoreUserCodeFailureAction
  | SendRestoreUserCodeSuccessAction
  | RestoreUserAction
  | RestoreUserFailureAction
  | RestoreUserSuccessAction
  | ResetRestoreUserStateAction

;
