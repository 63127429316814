import {Nullable, UserInfo, ReferralInfo} from 'common/types';
import {
  DELETE_PROFILE_FAILURE,
  DELETE_PROFILE_REQUEST,
  DELETE_PROFILE_SUCCESS,
  DeleteProfileAction,
  DeleteProfileFailureAction,
  DeleteProfileSuccessAction,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  FetchUserInfoAction,
  FetchUserInfoFailureAction,
  FetchUserInfoSuccessAction, GET_REFERRAL_INFO_FAILURE,
  GET_REFERRAL_INFO_REQUEST,
  GET_REFERRAL_INFO_SUCCESS,
  GetReferralInfoAction, GetReferralInfoFailureAction,
  GetReferralInfoSuccessAction,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LoginAction,
  LoginFailureAction,
  LoginSuccessAction,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LogoutAction,
  LogoutFailureAction,
  LogoutSuccessAction,
  RESET_LOGIN_STATE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_REGISTRATION_STATE,
  RESET_RESET_PASSWORD_STATE, RESET_RESTORE_USER_STATE,
  ResetLoginStateAction,
  ResetPasswordAction,
  ResetPasswordFailureAction,
  ResetPasswordSuccessAction,
  ResetRegistrationStateAction,
  ResetResetPasswordStateAction, ResetRestoreUserStateAction, RESTORE_USER_FAILURE,
  RESTORE_USER_REQUEST,
  RESTORE_USER_SUCCESS,
  RestoreUserAction, RestoreUserFailureAction,
  RestoreUserSuccessAction,
  SEND_REGISTRATION_CODE_FAILURE,
  SEND_REGISTRATION_CODE_REQUEST,
  SEND_REGISTRATION_CODE_SUCCESS,
  SEND_RESET_PASSWORD_CODE_FAILURE,
  SEND_RESET_PASSWORD_CODE_REQUEST,
  SEND_RESET_PASSWORD_CODE_SUCCESS,
  SEND_RESTORE_USER_CODE_FAILURE,
  SEND_RESTORE_USER_CODE_REQUEST,
  SEND_RESTORE_USER_CODE_SUCCESS,
  SendRegistrationCodeAction,
  SendRegistrationCodeFailureAction,
  SendRegistrationCodeSuccessAction,
  SendResetPasswordCodeAction,
  SendResetPasswordCodeFailureAction,
  SendResetPasswordCodeSuccessAction,
  SendRestoreUserCodeAction,
  SendRestoreUserCodeFailureAction,
  SendRestoreUserCodeSuccessAction,
  VERIFY_REGISTRATION_CODE_FAILURE,
  VERIFY_REGISTRATION_CODE_REQUEST,
  VERIFY_REGISTRATION_CODE_SUCCESS,
  VERIFY_RESET_PASSWORD_CODE_FAILURE,
  VERIFY_RESET_PASSWORD_CODE_REQUEST,
  VERIFY_RESET_PASSWORD_CODE_SUCCESS,
  VERIFY_RESTORE_USER_CODE_FAILURE,
  VERIFY_RESTORE_USER_CODE_REQUEST,
  VERIFY_RESTORE_USER_CODE_SUCCESS,
  VerifyRegistrationCodeAction,
  VerifyRegistrationCodeFailureAction,
  VerifyRegistrationCodeSuccessAction,
  VerifyResetPasswordCodeAction,
  VerifyResetPasswordCodeFailureAction,
  VerifyResetPasswordCodeSuccessAction,
  VerifyRestoreUserCodeAction,
  VerifyRestoreUserCodeFailureAction,
  VerifyRestoreUserCodeSuccessAction,
} from './types';

export const resetLoginState = (): ResetLoginStateAction => ({
  type: RESET_LOGIN_STATE,
});


export const getReferralInfo = (
  referral: string
): GetReferralInfoAction => ({
  type: GET_REFERRAL_INFO_REQUEST,
  payload: {
    referral
  },
});

export const getReferralInfoSuccess = (payload: ReferralInfo): GetReferralInfoSuccessAction => ({
  type: GET_REFERRAL_INFO_SUCCESS,
  payload
});

export const getReferralInfoFailure = (
  error: Error
): GetReferralInfoFailureAction => ({
  type: GET_REFERRAL_INFO_FAILURE,
  error,
});

export const sendRegistrationCode = (
  phone: string,
  recaptcha: string
): SendRegistrationCodeAction => ({
  type: SEND_REGISTRATION_CODE_REQUEST,
  payload: {
    phone,
    recaptcha
  },
});

export const sendRegistrationCodeSuccess = (): SendRegistrationCodeSuccessAction => ({
  type: SEND_REGISTRATION_CODE_SUCCESS,
});

export const sendRegistrationCodeFailure = (
  error: Error
): SendRegistrationCodeFailureAction => ({
  type: SEND_REGISTRATION_CODE_FAILURE,
  error,
});

export const verifyRegistrationCode = (
  phone: string,
  code: string
): VerifyRegistrationCodeAction => ({
  type: VERIFY_REGISTRATION_CODE_REQUEST,
  payload: {
    phone,
    code,
  },
});

export const verifyRegistrationCodeSuccess = (
  registerId: string
): VerifyRegistrationCodeSuccessAction => ({
  type: VERIFY_REGISTRATION_CODE_SUCCESS,
  payload: {
    registerId,
  },
});

export const verifyRegistrationCodeFailure = (
  error: Error
): VerifyRegistrationCodeFailureAction => ({
  type: VERIFY_REGISTRATION_CODE_FAILURE,
  error,
});

export const resetRegistrationState = (): ResetRegistrationStateAction => ({
  type: RESET_REGISTRATION_STATE,
});

export const login = (phone: string, password: string, utmParams?: Nullable<{}>): LoginAction => ({
  type: LOGIN_REQUEST,
  payload: {
    phone,
    password,
    utmParams,
  },
});

export const loginSuccess = (): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = (error: Error): LoginFailureAction => ({
  type: LOGIN_FAILURE,
  error,
});

export const sendResetPasswordCode = (
  phone: string,
  recaptcha: string
): SendResetPasswordCodeAction => ({
  type: SEND_RESET_PASSWORD_CODE_REQUEST,
  payload: {
    phone,
    recaptcha
  },
});

export const sendResetPasswordCodeSuccess = (): SendResetPasswordCodeSuccessAction => ({
  type: SEND_RESET_PASSWORD_CODE_SUCCESS,
});

export const sendResetPasswordCodeFailure = (
  error: Error
): SendResetPasswordCodeFailureAction => ({
  type: SEND_RESET_PASSWORD_CODE_FAILURE,
  error,
});

export const verifyResetPasswordCode = (
  phone: string,
  code: string
): VerifyResetPasswordCodeAction => ({
  type: VERIFY_RESET_PASSWORD_CODE_REQUEST,
  payload: {
    phone,
    code,
  },
});

export const verifyResetPasswordCodeSuccess = (
  resetPasswordToken: string
): VerifyResetPasswordCodeSuccessAction => ({
  type: VERIFY_RESET_PASSWORD_CODE_SUCCESS,
  payload: {
    resetPasswordToken,
  },
});

export const verifyResetPasswordCodeFailure = (
  error: Error
): VerifyResetPasswordCodeFailureAction => ({
  type: VERIFY_RESET_PASSWORD_CODE_FAILURE,
  error,
});

export const resetPassword = (
  resetPasswordToken: string,
  password: string
): ResetPasswordAction => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    password,
    resetPasswordToken,
  },
});

export const resetPasswordSuccess = (): ResetPasswordSuccessAction => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (
  error: Error
): ResetPasswordFailureAction => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

// :)
export const resetResetPasswordState = (): ResetResetPasswordStateAction => ({
  type: RESET_RESET_PASSWORD_STATE,
});

//---------------------------------------------
export const sendRestoreUserCode = (
  phone: string,
  recaptcha: string
): SendRestoreUserCodeAction => ({
  type: SEND_RESTORE_USER_CODE_REQUEST,
  payload: {
    phone,
    recaptcha
  },
});

export const sendRestoreUserCodeSuccess = (): SendRestoreUserCodeSuccessAction => ({
  type: SEND_RESTORE_USER_CODE_SUCCESS,
});

export const sendRestoreUserCodeFailure = (
  error: Error
): SendRestoreUserCodeFailureAction => ({
  type: SEND_RESTORE_USER_CODE_FAILURE,
  error,
});

export const verifyRestoreUserCode = (
  phone: string,
  code: string
): VerifyRestoreUserCodeAction => ({
  type: VERIFY_RESTORE_USER_CODE_REQUEST,
  payload: {
    phone,
    code,
  },
});

export const verifyRestoreUserCodeSuccess = (
  restoreUserToken: string
): VerifyRestoreUserCodeSuccessAction => ({
  type: VERIFY_RESTORE_USER_CODE_SUCCESS,
  payload: {
    restoreUserToken,
  },
});

export const verifyRestoreUserCodeFailure = (
  error: Error
): VerifyRestoreUserCodeFailureAction => ({
  type: VERIFY_RESTORE_USER_CODE_FAILURE,
  error,
});

export const restoreUser = (
  restoreUserToken: string,
): RestoreUserAction => ({
  type: RESTORE_USER_REQUEST,
  payload: {
    restoreUserToken,
  },
});

export const restoreUserSuccess = (): RestoreUserSuccessAction => ({
  type: RESTORE_USER_SUCCESS,
});

export const restoreUserFailure = (
  error: Error
): RestoreUserFailureAction => ({
  type: RESTORE_USER_FAILURE,
  error,
});

// :)
export const resetRestoreUserState = (): ResetRestoreUserStateAction => ({
  type: RESET_RESTORE_USER_STATE,
});
//---------------------------------------------

export const fetchUserInfo = (): FetchUserInfoAction => ({
  type: FETCH_USER_INFO_REQUEST,
});

export const fetchUserInfoSuccess = (
  userInfo: UserInfo
): FetchUserInfoSuccessAction => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: {
    userInfo,
  },
});

export const fetchUserInfoFailure = (
  error: Error
): FetchUserInfoFailureAction => ({
  type: FETCH_USER_INFO_FAILURE,
  error,
});

export const logout = (): LogoutAction => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = (): LogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (error: Error): LogoutFailureAction => ({
  type: LOGOUT_FAILURE,
  error,
});

export const deleteProfile = (): DeleteProfileAction => ({
  type: DELETE_PROFILE_REQUEST,
});

export const deleteProfileSuccess = (): DeleteProfileSuccessAction => ({
  type: DELETE_PROFILE_SUCCESS,
});

export const deleteProfileFailure = (
  error: Error
): DeleteProfileFailureAction => ({
  type: DELETE_PROFILE_FAILURE,
  error,
});
