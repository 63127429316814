import {Nullable, Option} from 'common/types';
import {
  CommonAction,
  FETCH_OPER_TYPES_FAILURE,
  FETCH_OPER_TYPES_REQUEST,
  FETCH_OPER_TYPES_SUCCESS,
} from '../types';

interface State {
  data: Option[];
  isFetching: boolean;
  isActual: boolean;
  error: Nullable<Error>;
}

const initialState: State = {
  data: [],
  isFetching: false,
  isActual: false,
  error: null,
};

export const operTypesReducer = (
  state: State = initialState,
  action: CommonAction
) => {
  switch (action.type) {
    case FETCH_OPER_TYPES_REQUEST:
      return {
        ...state,

        error: null,
        isFetching: true,
        isActual: false,
      };

    case FETCH_OPER_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload.operTypes,
        isFetching: false,
        isActual: true,
      };

    case FETCH_OPER_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
