import {QueryParam} from 'common/types';
import qs from 'qs';
import * as R from 'ramda';

const AUXILIARY_QUERY_PARAMS = [
  QueryParam.To,
  QueryParam.AlertType,
  QueryParam.AlertPayload,
  QueryParam.Referral,
];

// fileter null, undefined and empty string
const filterQueryParams = (obj: object) =>
  Object.entries(obj).reduce((acc: any, [key, value]: [string, any]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});

export const createLegacyInterfaceUrl = (relativeUrl: string) =>
  `${process.env.REACT_APP_LEGACY_INTERFACE_BASE_URL}${relativeUrl}`;

const createUrl = R.curry((path: string, params: any) => {
  const search = qs.stringify(filterQueryParams(params), {
    addQueryPrefix: true,
    indices: false,
  });

  return path + search;
});

export const parseLocationSearch = (search: string) =>
  qs.parse(search, {
    ignoreQueryPrefix: true,
  });

export const createLoginLink = createUrl('/login');

export const createRegistrationLink = createUrl('/registration');
export const createRestoreUserLink = createUrl('/restore-user');

export const createRegistrationInfoLink = createUrl('/registration/info');
export const createReferralsManagementUrl = createUrl('/referrals');

export const createResetPasswordLink = createUrl('/reset-password');

export const createOffersManagementUrl = createUrl('/offers/management');
export const createAgencyFeedManagementUrl = createUrl('/agency-feed');
export const createDashboardUrl = createUrl('/dashboard');
export const createDealsManagementUrl = createUrl('/deals');
export const createOlxImportManagementUrl = createUrl('/internet-offers');
export const createProfileAgentsManagementUrl = createUrl('/profile/agents');
export const createPartnersManagementUrl = createUrl('/partners');
export const createUnionsManagementUrl = createUrl('/unions');

export const createOfferCreateUrl = createUrl('/offers/create');

export const createProfileStatsUrl = createUrl('/profile/stats');
export const createProfileSummaryUrl = createUrl('/profile/summary');
export const createProfileEditUrl = createUrl('/profile/edit');
export const createRieltorManifestUrl = createUrl('/rieltor-manifest');

export const createAgentsSuggestionsUrl = (offerId: number) =>
  `/agents-suggestions/${offerId}`;

export const extractAuxiliaryQueryParams = R.pick(AUXILIARY_QUERY_PARAMS);

export const omitAuxiliaryQueryParams = R.omit(AUXILIARY_QUERY_PARAMS);
