import {BottomNavigation, BottomNavigationAction, makeStyles} from '@material-ui/core';
import {useCurrentUser} from 'hooks/user';
import React from 'react';
import {FormattedMessage as FM} from 'react-intl';
import {useSelector} from 'react-redux';
import RouterLink from 'screens/common/RouterLink';
import {rieltor} from 'styles/theme';
import {NormalizedRoutes, RoleId} from '../../common/types';
import {Dashboard, Offers, ProfileStats, WalletPlus} from '../../components/Icons';
import {olxImportAllowedSelector} from '../../store/global/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    background: rieltor.purplePushed,

    '& .MuiBottomNavigationAction-root': {
      color: '#fff',
      opacity: 0.6,
      '&.Mui-selected': {
        opacity: 1,
      }
    },

    '& .MuiBottomNavigationAction-label': {
      fontSize: '12px',
      lineHeight: '16px',
    }
  },
}));

const MobilePanelBottom = ({pathname}: { pathname: string }) => {
  const user = useCurrentUser();
  const classes = useStyles();

  const normalizedPathname = pathname.endsWith('/')
    ? pathname.slice(0, pathname.length - 1)
    : pathname;

  const importAllowed = useSelector(olxImportAllowedSelector);

  if (user === null) {
    return null;
  }

  return (
    <BottomNavigation value={normalizedPathname} showLabels className={classes.root}>
      {
        user.roleId !== RoleId.Owner ? <BottomNavigationAction
          icon={<Dashboard/>}
          value={NormalizedRoutes.Dashboard}
          label={<FM id="bottom_tabs.dashboard"/>}
          component={RouterLink}
          to={NormalizedRoutes.Dashboard}
        /> : null
      }

      <BottomNavigationAction
        icon={<Offers/>}
        value={NormalizedRoutes.OffersManagement}
        label={<FM id="bottom_tabs.offers"/>}
        component={RouterLink}
        to={NormalizedRoutes.OffersManagement}
      />

      <BottomNavigationAction
        icon={<ProfileStats/>}
        value={NormalizedRoutes.ProfileStats}
        label={<FM id="bottom_tabs.stats"/>}
        component={RouterLink}
        to={NormalizedRoutes.ProfileStats}
      />

      <BottomNavigationAction
        icon={<WalletPlus/>}
        value={NormalizedRoutes.Payment}
        label={<FM id="bottom_tabs.payment"/>}
        component={RouterLink}
        to={NormalizedRoutes.Payment}
      />
    </BottomNavigation>
  );
};

export default MobilePanelBottom;
