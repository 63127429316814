import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {Box, Button, makeStyles, Theme, Typography} from '@material-ui/core';
import {AlertType, APIResponseStatus, Nullable, UserInfo} from "../../../../common/types";
import {UsersAPI} from "../../../../services/UsersAPI";
import {FormattedMessage, useIntl} from "react-intl";
import {useSnackbar} from "notistack";
import {Alert} from "@material-ui/lab";
import {createLoginLink} from "../../../../utils/navigation";


const useStyles = makeStyles<Theme>(theme => ({
  alert: {
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  alertButton: {
    maxWidth: 230,
  }
}));

interface BankidAlertProps {
  requestId: any;
  alertType: AlertType;
}

const BankidAlert: FunctionComponent<BankidAlertProps> = ({requestId, alertType}: BankidAlertProps) => {
  const classes = useStyles();
  const usersAPI = useMemo(() => new UsersAPI(), []);
  const {messages, formatMessage} = useIntl();
  const _snackbar = useSnackbar();
  // eslint-disable-next-line
  const snackbar = useMemo(() => _snackbar, []);

  const [restoreUserInfo, setRestoreUserInfo] = React.useState<Nullable<UserInfo>>(null);

  const fillRestoreUserInfo = async (requestId: string) => {
    try {
      const response = await usersAPI.getRestoreBankidInfo(requestId);
      if (response.status === APIResponseStatus.Ok) {
        setRestoreUserInfo(response.data);
      }
    } catch (error) {
      const id = `api_errors.${error.errorType}`;
      if (messages[id]) {
        snackbar.enqueueSnackbar(formatMessage({id}));
      } else {
        snackbar.enqueueSnackbar(formatMessage({id}));
      }
    }
  }

  const restoreUser = async () => {
    try {
      const response = await usersAPI.restoreUserByBankid(requestId);
      if (response.status === APIResponseStatus.Ok) {
        document.location.href = createLoginLink({});
      }
    } catch (error) {
      const id = `api_errors.${error.errorType}`;
      if (messages[id]) {
        snackbar.enqueueSnackbar(formatMessage({id}));
      } else {
        snackbar.enqueueSnackbar(formatMessage({id}));
      }
    }
  }

  useEffect(() => {
    if (requestId) {
      fillRestoreUserInfo(requestId);
    }
  }, [requestId]);

  return (
    <Box mt={2} mb={2}>
      <Alert className={classes.alert} severity="error">
        <Box mb={2}>
          <Typography>
            <FormattedMessage id="alerts.bankid.user_deleted"  values={{'username': restoreUserInfo?.username}}/>
          </Typography>
        </Box>
        <Button
          className={classes.alertButton}
          variant="contained"
          color="primary"
          onClick={restoreUser}
        >
          <FormattedMessage id="alerts.bankid.restore_user"/>
        </Button>
      </Alert>
    </Box>
  );
};

export default BankidAlert;