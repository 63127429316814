import {OfferCommonFields, Option, Spec} from 'common/types';

export const FETCH_SPECS_REQUEST = '@common/fetch-specs-request';
export const FETCH_SPECS_SUCCESS = '@common/fetch-specs-success';
export const FETCH_SPECS_FAILURE = '@common/fetch-specs-failure';

export interface FetchSpecsAction {
  type: typeof FETCH_SPECS_REQUEST;
}

export interface FetchSpecsSuccessAction {
  type: typeof FETCH_SPECS_SUCCESS;
  payload: {
    specs: Spec[];
  };
}

export interface FetchSpecsFailureAction {
  type: typeof FETCH_SPECS_FAILURE;
  error: Error;
}

export const FETCH_ITEM_TYPES_REQUEST = '@common/fetch-item-types-request';
export const FETCH_ITEM_TYPES_SUCCESS = '@common/fetch-item-types-success';
export const FETCH_ITEM_TYPES_FAILURE = '@common/fetch-item-types-failure';

export interface FetchItemTypesAction {
  type: typeof FETCH_ITEM_TYPES_REQUEST;
}

export interface FetchItemTypesSuccessAction {
  type: typeof FETCH_ITEM_TYPES_SUCCESS;
  payload: {
    itemTypes: Option[];
  };
}

export interface FetchItemTypesFailureAction {
  type: typeof FETCH_ITEM_TYPES_FAILURE;
  error: Error;
}

export const FETCH_OPER_TYPES_REQUEST = '@common/fetch-oper-types-request';
export const FETCH_OPER_TYPES_SUCCESS = '@common/fetch-oper-types-success';
export const FETCH_OPER_TYPES_FAILURE = '@common/fetch-oper-types-failure';

export interface FetchOperTypesAction {
  type: typeof FETCH_OPER_TYPES_REQUEST;
}

export interface FetchOperTypesSuccessAction {
  type: typeof FETCH_OPER_TYPES_SUCCESS;
  payload: {
    operTypes: Option[];
  };
}

export interface FetchOperTypesFailureAction {
  type: typeof FETCH_OPER_TYPES_FAILURE;
  error: Error;
}

export const FETCH_OFFER_OPTIONS_REQUEST =
  '@common/fetch-offer-options-request';
export const FETCH_OFFER_OPTIONS_SUCCESS =
  '@common/fetch-offer-options-success';
export const FETCH_OFFER_OPTIONS_FAILURE =
  '@common/fetch-offer-options-failure';

export interface FetchOfferOptionsAction {
  type: typeof FETCH_OFFER_OPTIONS_REQUEST;
  payload: {
    itemType: number;
    operType: number;
  };
}

export interface FetchOfferOptionsSuccessAction {
  type: typeof FETCH_OFFER_OPTIONS_SUCCESS;
  payload: {
    offerOptions: OfferCommonFields;
  };
}

export interface FetchOfferOptionsFailureAction {
  type: typeof FETCH_OFFER_OPTIONS_FAILURE;
  error: Error;
}

export type CommonAction =
  | FetchSpecsAction
  | FetchSpecsSuccessAction
  | FetchSpecsFailureAction
  | FetchItemTypesAction
  | FetchItemTypesSuccessAction
  | FetchItemTypesFailureAction
  | FetchOperTypesAction
  | FetchOperTypesSuccessAction
  | FetchOperTypesFailureAction
  | FetchOfferOptionsAction
  | FetchOfferOptionsSuccessAction
  | FetchOfferOptionsFailureAction;
