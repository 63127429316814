import {Nullable, Spec} from 'common/types';
import {
  CommonAction,
  FETCH_SPECS_FAILURE,
  FETCH_SPECS_REQUEST,
  FETCH_SPECS_SUCCESS,
} from '../types';

interface State {
  data: Spec[];
  isFetching: boolean;
  isActual: boolean;
  error: Nullable<Error>;
}

const initialState: State = {
  data: [],
  isFetching: false,
  isActual: false,
  error: null,
};

export const specsReducer = (
  state: State = initialState,
  action: CommonAction
) => {
  switch (action.type) {
    case FETCH_SPECS_REQUEST:
      return {
        ...state,

        error: null,
        isFetching: true,
        isActual: false,
      };

    case FETCH_SPECS_SUCCESS:
      return {
        ...state,
        data: action.payload.specs,
        isFetching: false,
        isActual: true,
      };

    case FETCH_SPECS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
