import {Nullable} from 'common/types';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  RESET_LOGIN_STATE,
  UserAction,
} from '../types';

interface LoginState {
  isFetching: boolean;
  isSuccess: boolean;
  error: Nullable<Error>;
}
const initialState: LoginState = {
  isFetching: false,
  isSuccess: false,
  error: null,
};

export const loginReducer = (
  state: LoginState = initialState,
  action: UserAction
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        error: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case RESET_LOGIN_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
