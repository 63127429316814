import {PartnersManagementListing} from 'common/types';
import {
  FETCH_PARTNERS_MANAGEMENT_LISTING_FAILURE,
  FETCH_PARTNERS_MANAGEMENT_LISTING_REQUEST,
  FETCH_PARTNERS_MANAGEMENT_LISTING_SUCCESS,
  FetchPartnersManagementListingAction,
  FetchPartnersManagementListingFailureAction,
  FetchPartnersManagementListingSuccessAction,
} from './types';

export const fetchPartnersManagementListing = (
  params: any
): FetchPartnersManagementListingAction => ({
  type: FETCH_PARTNERS_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchPartnersManagementListingSuccess = (
  data: PartnersManagementListing
): FetchPartnersManagementListingSuccessAction => ({
  type: FETCH_PARTNERS_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchPartnersManagementListingFailure = (
  error: Error
): FetchPartnersManagementListingFailureAction => ({
  type: FETCH_PARTNERS_MANAGEMENT_LISTING_FAILURE,
  error,
});
