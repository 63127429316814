import {CssBaseline, MuiThemeProvider} from '@material-ui/core';
import {SnackbarOrigin} from '@material-ui/core/Snackbar';
import {ConnectedIntlProvider} from 'modules/intl';
import {SnackbarProvider} from 'notistack';
import React, {FC} from 'react';
import {CookiesProvider} from 'react-cookie';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from 'react-router-dom';
import {configureStore} from 'store';
import {theme} from 'styles/theme';
import {Recaptcha} from './components/Recaptcha';
import Root from './screens/Root';

const store = configureStore();

const SNACKBAR_PROVIDER_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const App: FC = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CookiesProvider>
          <ConnectedIntlProvider>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={SNACKBAR_PROVIDER_ANCHOR_ORIGIN}
            >
              <Recaptcha/>
              <CssBaseline />
              <BrowserRouter>
                <Route component={Root} />
              </BrowserRouter>
            </SnackbarProvider>
          </ConnectedIntlProvider>
        </CookiesProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
