import {makeStyles} from '@material-ui/core';
import Button, {ButtonProps} from '@material-ui/core/Button';
import React from 'react';
import {rieltor} from 'styles/theme';

interface RieltorButtonProps extends ButtonProps {
}

const useStyles = makeStyles({
  containedPrimary: {
    backgroundColor: rieltor.purple,
    border: '1px solid transparent',
    boxShadow: 'none !important',
    color: '#fff',
    textDecoration: 'none !important',
    padding: '8px 14px',
    fontFamily: rieltor.fontFamily,
    fontSize: '13px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: rieltor.purple,
      color: rieltor.purple,
      '&:active': {
        backgroundColor: rieltor.yellow,
        borderColor: rieltor.yellow,
        color: '#000',
      }
    },
    '&:disabled': {
      opacity: 0.5,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: rieltor.yellow,
      borderColor: rieltor.yellow,
      color: '#000',
    },
  },
  containedSecondary: {
    backgroundColor: '#fff',
    border: '1px solid transparent',
    boxShadow: 'none !important',
    color: rieltor.purple,
    textDecoration: 'none !important',
    padding: '8px 14px',
    fontFamily: rieltor.fontFamily,
    fontSize: '13px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: '#fff',
      color: '#fff',
      '&:active': {
        backgroundColor: rieltor.yellow,
        borderColor: rieltor.yellow,
        color: '#000',
      }
    },
    '&:disabled': {
      opacity: 0.5,
      borderColor: '#fff',
      color: rieltor.purple,
    },
    '&:active': {
      backgroundColor: rieltor.yellow,
      borderColor: rieltor.yellow,
      color: '#000',
    },
  },
  containedSizeSmall: {
    boxShadow: 'none !important',
    display: 'flex',
    height: '30px',
    padding: '4px 8px',
    alignItems: 'center',
    gap: '4px',
    fontSize: '12px',
    fontWeight: 500,
    backgroundColor: rieltor.grayFill,
    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: rieltor.lightViolet,
      color: rieltor.purple,
    },
    '&:active': {
      backgroundColor: rieltor.purple,
      color: '#fff',
    },
  },
});

const ButtonPurple = ({...rest}: RieltorButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      classes={classes}
      color={'primary'}
      variant={'contained'}
      {...rest}
    />
  );
};

const ButtonWhite = ({...rest}: RieltorButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      classes={classes}
      color={'secondary'}
      variant={'contained'}
      {...rest}
    />
  );
};

const ButtonSmall = ({...rest}: RieltorButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      size={'small'}
      classes={classes}
      color={'default'}
      variant={'contained'}
      {...rest}
    />
  );
};

export {ButtonPurple, ButtonWhite, ButtonSmall};
