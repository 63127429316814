import {Box, makeStyles, Theme} from '@material-ui/core';
import {COINS_TO_CURRENCY_EXCHANGE_RATE, UIPriceCurrency} from 'common/types';
import React, {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';

export interface CoinsInCurrencyProps {
  coins: number;
  oldCoins?: number;
  oldCoinsText?: string;
  currency: UIPriceCurrency;
  size?: 'small' | 'medium' | 'large';
  sign?: string;
  perDay?: boolean;
  label?: any;
  row?: boolean;
  priceIcon?: React.ComponentType<any>
}

const useStyles = makeStyles<Theme, CoinsInCurrencyProps>(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    fontSize: props => {
      if (props.size === 'small') {
        return theme.typography.body2.fontSize;
      }
      if (props.size === 'large') {
        return theme.typography.h6.fontSize;
      }

      return theme.typography.body1.fontSize;
    },
  },
}));

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
// @ts-ignore
const CoinsInCurrency: FunctionComponent<CoinsInCurrencyProps> = (
  props: CoinsInCurrencyProps
) => {
  const intl = useIntl();
  const classes = useStyles(props);

  const {
    currency,
    coins,
    oldCoins,
    oldCoinsText,
    sign = '',
    perDay = false,
    label = '',
    row = false,
    priceIcon:PriceIcon
  } = props;

  if (currency === UIPriceCurrency.Coins) {
    return null;
  }

  const exchangeRate = COINS_TO_CURRENCY_EXCHANGE_RATE[currency];

  const value = coins * exchangeRate;
  const oldValue = oldCoins ? oldCoins * exchangeRate : 0;

  const currencyLabel = PriceIcon?<PriceIcon/>:intl.formatMessage({id: `currency.${currency}`});

  const formattedValue = intl.formatNumber(value, {
    maximumFractionDigits: 2,
  });

  const formattedLabel = oldValue ?
    <span className={classes.root}>
      <span style={{textDecoration: "line-through"}}>{oldValue}</span>&nbsp;
      {intl.formatMessage({id: 'coins.coins_per_day'}, {sign, value: value})}
      {intl.formatMessage({id: 'coins.coins_discount'}, {value: Math.round((1-value/oldValue)*100), text: oldCoinsText})}</span>
    : intl.formatMessage(
    {
      id: perDay
        ? 'coins_in_currency.value_per_day'
        : 'coins_in_currency.value',
    },
    {value: formattedValue, currency: currencyLabel, sign, label}
  );


  return row ? (
    formattedLabel
  ) : (
    oldValue ? formattedLabel :<span className={classes.root}>{formattedLabel}</span>
  );
};

export default CoinsInCurrency;
