import {OfferCommonFields, Option, Spec} from 'common/types';
import {
  FETCH_ITEM_TYPES_FAILURE,
  FETCH_ITEM_TYPES_REQUEST,
  FETCH_ITEM_TYPES_SUCCESS,
  FETCH_OFFER_OPTIONS_FAILURE,
  FETCH_OFFER_OPTIONS_REQUEST,
  FETCH_OFFER_OPTIONS_SUCCESS,
  FETCH_OPER_TYPES_FAILURE,
  FETCH_OPER_TYPES_REQUEST,
  FETCH_OPER_TYPES_SUCCESS,
  FETCH_SPECS_FAILURE,
  FETCH_SPECS_REQUEST,
  FETCH_SPECS_SUCCESS,
  FetchItemTypesAction,
  FetchItemTypesFailureAction,
  FetchItemTypesSuccessAction,
  FetchOfferOptionsAction,
  FetchOfferOptionsSuccessAction,
  FetchOperTypesAction,
  FetchOperTypesFailureAction,
  FetchOperTypesSuccessAction,
  FetchSpecsAction,
  FetchSpecsFailureAction,
  FetchSpecsSuccessAction,
} from './types';

export const fetchSpecs = (): FetchSpecsAction => ({
  type: FETCH_SPECS_REQUEST,
});

export const fetchSpecsSuccess = (specs: Spec[]): FetchSpecsSuccessAction => ({
  type: FETCH_SPECS_SUCCESS,
  payload: {
    specs,
  },
});

export const fetchSpecsFailure = (error: Error): FetchSpecsFailureAction => ({
  type: FETCH_SPECS_FAILURE,
  error,
});

export const fetchItemTypes = (): FetchItemTypesAction => ({
  type: FETCH_ITEM_TYPES_REQUEST,
});

export const fetchItemTypesSuccess = (
  itemTypes: Option[]
): FetchItemTypesSuccessAction => ({
  type: FETCH_ITEM_TYPES_SUCCESS,
  payload: {
    itemTypes,
  },
});

export const fetchItemTypesFailure = (
  error: Error
): FetchItemTypesFailureAction => ({
  type: FETCH_ITEM_TYPES_FAILURE,
  error,
});

export const fetchOperTypes = (): FetchOperTypesAction => ({
  type: FETCH_OPER_TYPES_REQUEST,
});

export const fetchOperTypesSuccess = (
  operTypes: Option[]
): FetchOperTypesSuccessAction => ({
  type: FETCH_OPER_TYPES_SUCCESS,
  payload: {
    operTypes,
  },
});

export const fetchOperTypesFailure = (
  error: Error
): FetchOperTypesFailureAction => ({
  type: FETCH_OPER_TYPES_FAILURE,
  error,
});

export interface FetchOfferOptionsActionPayload {
  itemType: number;
  operType: number;
}

export const fetchOfferOptions = ({
  itemType,
  operType,
}: FetchOfferOptionsActionPayload): FetchOfferOptionsAction => ({
  type: FETCH_OFFER_OPTIONS_REQUEST,
  payload: {
    itemType,
    operType,
  },
});

export const fetchOfferOptionsSuccess = (
  offerOptions: OfferCommonFields
): FetchOfferOptionsSuccessAction => ({
  type: FETCH_OFFER_OPTIONS_SUCCESS,
  payload: {
    offerOptions,
  },
});

export const fetchOfferOptionsFailure = (error: Error) => ({
  type: FETCH_OFFER_OPTIONS_FAILURE,
  error,
});
