import {QueryParam, SessionStorageKey} from 'common/types';
import React, {FunctionComponent} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {AppState} from 'store';
import * as UserSelectors from 'store/user/selectors';
import {createLoginLink, parseLocationSearch} from 'utils/navigation';
import {useSessionStorage} from 'react-use';

const mapStateToProps = (state: AppState) => ({
  user: UserSelectors.userInfo(state),
  isUserActual: UserSelectors.isUserInfoActual(state),
  isUserFetching: UserSelectors.isUserInfoFetching(state),
  error: UserSelectors.userInfoError(state),
});

interface PrivateRouteProps
  extends RouteProps,
    ReturnType<typeof mapStateToProps> {}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  isUserActual,
  isUserFetching,
  user,
  error,
  ...props
}: PrivateRouteProps) => {
  return (
    <Route
      {...props}
      render={componentProps => {
        if ((isUserActual && !user) || error) {
          const to = props.location
            ? props.location.pathname + props.location.search
            : undefined;

          const redirectUrl = createLoginLink({[QueryParam.To]: to});

          return <Redirect to={redirectUrl} />;
        }

        if (user) {
          // @ts-ignore
          return <Component {...componentProps} />;
        }

        return null;
      }}
    />
  );
};

const ConnectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export default ConnectedPrivateRoute;
