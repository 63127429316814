import {makeStyles} from '@material-ui/core';
import cx from 'classnames';
import React, {FunctionComponent} from 'react';
import { ReactComponent as LogoBig } from './logo-big.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  small: {
    height: 27,
  },
  big: {
    height: 58,
    width: 'auto',
  }
}));

interface LogoProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  imageClassName?: string;
  logoType?: 'big' | 'small';
}

const Logo: FunctionComponent<LogoProps> = ({
  onClick,
  imageClassName,
  logoType = 'small'
}: LogoProps) => {
  const classes = useStyles();

  return (
    <a
      className={classes.root}
      href={process.env.REACT_APP_LEGACY_INTERFACE_BASE_URL}
      onClick={onClick}
    >
      {logoType === 'small' && (
        <img
          className={cx(classes.small, imageClassName)}
          src={require('./logo-rieltor-plus-lun.png')}
          srcSet={`${require('./logo-rieltor-plus-lun.png')} 1x, ${require('./logo-rieltor-plus-lun@2x.png')} 2x`}
          alt=""
        />
      )}
      {logoType === 'big' && <LogoBig className={cx(classes.big, imageClassName)}/>}
    </a>
  );
};

export default Logo;
