import {Locale} from 'common/types';
import {
  FETCH_I18N_MESSAGES_FAILURE,
  FETCH_I18N_MESSAGES_REQUEST,
  FETCH_I18N_MESSAGES_SUCCESS,
  FetchI18nMessagesAction,
  FetchI18nMessagesFailureAction,
  FetchI18nMessagesSuccessAction,
  SET_LOCALE,
  SetLocaleAction,
} from './types';

export const setLocale = (locale: Locale): SetLocaleAction => ({
  type: SET_LOCALE,
  payload: {
    locale,
  },
});

export const fetchi18nMessages = (locale: Locale): FetchI18nMessagesAction => ({
  type: FETCH_I18N_MESSAGES_REQUEST,
  payload: {
    locale,
  },
});

export const fetchi18nMessagesSuccess = (
  locale: Locale,
  messages: Record<string, string>
): FetchI18nMessagesSuccessAction => ({
  type: FETCH_I18N_MESSAGES_SUCCESS,
  payload: {
    locale,
    messages,
  },
});

export const fetchi18nMessagesFailure = (
  error: Error
): FetchI18nMessagesFailureAction => ({
  type: FETCH_I18N_MESSAGES_FAILURE,
  error,
});
