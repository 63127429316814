import {Nullable, ReferralInfo} from 'common/types';
import {
  GET_REFERRAL_INFO_SUCCESS,
  RESET_REGISTRATION_STATE,
  SEND_REGISTRATION_CODE_FAILURE,
  SEND_REGISTRATION_CODE_REQUEST,
  SEND_REGISTRATION_CODE_SUCCESS,
  UserAction,
  VERIFY_REGISTRATION_CODE_FAILURE,
  VERIFY_REGISTRATION_CODE_REQUEST,
  VERIFY_REGISTRATION_CODE_SUCCESS,
} from '../types';

interface RegistrationState {
  isCodeSent: boolean;
  isCodeSending: boolean;
  isCodeVerified: boolean;
  isCodeVerifying: boolean;
  sendCodeError: Nullable<Error>;
  codeVerificationError: Nullable<Error>;

  registerId?: string;
  referralInfo?: ReferralInfo;

  sendCodeAttemptsCount: number;
}
const initialState: RegistrationState = {
  isCodeSending: false,
  isCodeSent: false,
  sendCodeError: null,

  isCodeVerified: false,
  isCodeVerifying: false,
  codeVerificationError: null,

  registerId: undefined,
  referralInfo: undefined,

  sendCodeAttemptsCount: 0,
};

export const registrationReducer = (
  state = initialState,
  action: UserAction
) => {
  switch (action.type) {
    case GET_REFERRAL_INFO_SUCCESS:
      return {
        ...state,
        referralInfo: action.payload,
      };
    case SEND_REGISTRATION_CODE_REQUEST:
      return {
        ...state,
        isCodeSending: true,
        isCodeSent: false,
        sendCodeError: null,
        sendCodeAttemptsCount: state.sendCodeAttemptsCount + 1,
      };

    case SEND_REGISTRATION_CODE_SUCCESS:
      return {
        ...state,
        isCodeSending: false,
        isCodeSent: true,
      };

    case SEND_REGISTRATION_CODE_FAILURE:
      return {
        ...state,
        isCodeSending: false,
        sendCodeError: action.error,
      };

    case VERIFY_REGISTRATION_CODE_REQUEST:
      return {
        ...state,
        isCodeVerifying: true,
        isCodeVerified: false,
        codeVerificationError: null,
      };

    case VERIFY_REGISTRATION_CODE_SUCCESS:
      return {
        ...state,
        isCodeVerifying: false,
        isCodeVerified: true,
        registerId: action.payload.registerId,
      };

    case VERIFY_REGISTRATION_CODE_FAILURE:
      return {
        ...state,
        isCodeVerifying: false,
        codeVerificationError: action.error,
      };

    case RESET_REGISTRATION_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
