import {OlxImportManagementListing} from 'common/types';
import {
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_FAILURE,
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST,
  FETCH_OLXIMPORT_MANAGEMENT_LISTING_SUCCESS,
  FetchOlxImportManagementListingAction,
  FetchOlxImportManagementListingFailureAction,
  FetchOlxImportManagementListingSuccessAction,
} from './types';

export const fetchOlxImportManagementListing = (
  params: any
): FetchOlxImportManagementListingAction => ({
  type: FETCH_OLXIMPORT_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchOlxImportManagementListingSuccess = (
  data: OlxImportManagementListing
): FetchOlxImportManagementListingSuccessAction => ({
  type: FETCH_OLXIMPORT_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchOlxImportManagementListingFailure = (
  error: Error
): FetchOlxImportManagementListingFailureAction => ({
  type: FETCH_OLXIMPORT_MANAGEMENT_LISTING_FAILURE,
  error,
});
