import {Spec} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';

export interface FetchOfferOptionsPayload {
  itemType: number;
  operType: number;
}

class CommonAPI extends APIService {
  fetchSpecs = apiMethod<Spec[]>(() =>
    this.fetcher.request({
      url: '/api/common/specs/',
      method: 'get',
    })
  );

  fetchItemTypes = apiMethod<object>(() =>
    this.fetcher.request({
      url: '/api/common/spec-types/',
      method: 'get',
    })
  );

  fetchOperTypes = apiMethod<object>(() =>
    this.fetcher.request({
      url: '/api/common/oper-types/',
      method: 'get',
    })
  );

  fetchOfferOptions = apiMethod<object>((params: FetchOfferOptionsPayload) =>
    this.fetcher.request({
      url: `/api/offers/get-common-data/`,
      params,
      method: 'get',
    })
  );
}

export {CommonAPI};
