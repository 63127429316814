import {AppState} from 'store';

const CHIPS_COUNTERS = '@deals/chips-counters';

export interface PartnersChipsCounters {
  partners: number;
  deals: number;
  offers: number;
}

export interface ChipsCountersAction {
  type: typeof CHIPS_COUNTERS;
  payload: {
    chipsCounters: PartnersChipsCounters;
  };
}

interface State {
  chipsCounters: PartnersChipsCounters;
}

const initialState: State = {
  chipsCounters: {
    partners: 0,
    deals: 0,
    offers: 0,
  }
};

export const chipsCountersReducer = (
  state: State = initialState,
  action: ChipsCountersAction
) => {
  switch (action.type) {
    case CHIPS_COUNTERS:
      return {
        ...state,
        chipsCounters: action.payload.chipsCounters
      };
    default:
      return state;
  }
};

export const setPartnerChipsCounters = (counters: PartnersChipsCounters) => {
  return {
    type: CHIPS_COUNTERS,
    payload: {
      chipsCounters: counters
    }
  };
};

export const partnersChipsCounters = (state: AppState) =>
  state.partners.chips.chipsCounters;
