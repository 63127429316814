import {call, put, takeLatest} from 'redux-saga/effects';
import {ReferralsAPI} from 'services/ReferralsAPI';
import * as ReferralsActions from './actions';
import {
  FETCH_REFERRALS_MANAGEMENT_LISTING_REQUEST,
  FetchReferralsManagementListingAction,
} from './types';

export function* fetchReferralsManagementListing(
  action: FetchReferralsManagementListingAction
) {
  try {
    console.log('FetchReferralsManagementListingAction');
    const {params} = action.payload;

    const referralsAPI = new ReferralsAPI();

    const {data} = yield call(referralsAPI.fetchReferralsList, params);
    yield put(ReferralsActions.fetchReferralsManagementListingSuccess(data));
  } catch (error) {
    yield put(ReferralsActions.fetchReferralsManagementListingFailure(error));
  }
}

export default [
  takeLatest(
    FETCH_REFERRALS_MANAGEMENT_LISTING_REQUEST,
    fetchReferralsManagementListing
  ),
];
