import {AppState} from 'store';

export const isLoginFetching = (state: AppState) => state.user.login.isFetching;

export const isLoginSuccess = (state: AppState) => state.user.login.isSuccess;

export const loginError = (state: AppState) => state.user.login.error;

export const isRegistrationCodeSending = (state: AppState) =>
  state.user.registration.isCodeSending;
export const isRegistrationCodeSent = (state: AppState) =>
  state.user.registration.isCodeSent;
export const sendRegistrationCodeError = (state: AppState) =>
  state.user.registration.sendCodeError;

export const sendRegistrationCodeAttemptsCount = (state: AppState) =>
  state.user.registration.sendCodeAttemptsCount;

export const isRegistrationCodeVerified = (state: AppState) =>
  state.user.registration.isCodeVerified;
export const isRegistrationCodeVerifying = (state: AppState) =>
  state.user.registration.isCodeVerifying;

export const registrationCodeVerificationError = (state: AppState) =>
  state.user.registration.codeVerificationError;

export const registerId = (state: AppState) =>
  state.user.registration.registerId;

export const referralInfo = (state: AppState) =>
  state.user.registration.referralInfo;

export const isResetPasswordCodeSending = (state: AppState) =>
  state.user.resetPassword.isCodeSending;
export const isResetPasswordCodeSent = (state: AppState) =>
  state.user.resetPassword.isCodeSent;
export const sendResetPasswordCodeError = (state: AppState) =>
  state.user.resetPassword.sendCodeError;

export const sendResetPasswordCodeAttemptsCount = (state: AppState) =>
  state.user.resetPassword.sendCodeAttemptsCount;

export const isResetPasswordCodeVerified = (state: AppState) =>
  state.user.resetPassword.isCodeVerified;
export const isResetPasswordCodeVerifying = (state: AppState) =>
  state.user.resetPassword.isCodeVerifying;

export const resetPasswordCodeVerificationError = (state: AppState) =>
  state.user.resetPassword.codeVerificationError;

export const resetPasswordToken = (state: AppState) =>
  state.user.resetPassword.resetPasswordToken;

export const isPasswordResetted = (state: AppState) =>
  state.user.resetPassword.isResetted;

export const isPasswordResetting = (state: AppState) =>
  state.user.resetPassword.isResetting;

export const resetPasswordError = (state: AppState) =>
  state.user.resetPassword.resetError;

//------------------Restore user------------------
export const isRestoreUserCodeSending = (state: AppState) =>
  state.user.restoreUser.isCodeSending;
export const isRestoreUserCodeSent = (state: AppState) =>
  state.user.restoreUser.isCodeSent;
export const sendRestoreUserCodeError = (state: AppState) =>
  state.user.restoreUser.sendCodeError;

export const sendRestoreUserCodeAttemptsCount = (state: AppState) =>
  state.user.restoreUser.sendCodeAttemptsCount;

export const isRestoreUserCodeVerified = (state: AppState) =>
  state.user.restoreUser.isCodeVerified;
export const isRestoreUserCodeVerifying = (state: AppState) =>
  state.user.restoreUser.isCodeVerifying;

export const restoreUserCodeVerificationError = (state: AppState) =>
  state.user.restoreUser.codeVerificationError;

export const restoreUserToken = (state: AppState) =>
  state.user.restoreUser.restoreUserToken;

export const isUserRestored = (state: AppState) =>
  state.user.restoreUser.isRestored;

export const isUserRestoring = (state: AppState) =>
  state.user.restoreUser.isRestoring;

export const restoreUserError = (state: AppState) =>
  state.user.restoreUser.resetError;
//------------------Restore user------------------

export const isUserInfoFetching = (state: AppState) =>
  state.user.info.isFetching;
export const isUserInfoActual = (state: AppState) => state.user.info.isActual;
export const userInfo = (state: AppState) => state.user.info.data;
export const userInfoError = (state: AppState) => state.user.info.error;
