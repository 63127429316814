import {
  FETCH_GLOBAL_CONFIG_FAILURE,
  FETCH_GLOBAL_CONFIG_REQUEST,
  FETCH_GLOBAL_CONFIG_SUCCESS,
  FetchGlobalConfigAction,
  FetchGlobalConfigFailureAction,
  FetchGlobalConfigSuccessAction
} from "./types";
import {GlobalConfig} from "../../common/types";

export const fetchGlobalConfig = (): FetchGlobalConfigAction => ({
  type: FETCH_GLOBAL_CONFIG_REQUEST,
});

export const fetchGlobalConfigSuccess = (globalConfig: GlobalConfig): FetchGlobalConfigSuccessAction => ({
  type: FETCH_GLOBAL_CONFIG_SUCCESS,
  payload: {
    globalConfig
  },
});

export const fetchGlobalConfigFailure = (error: Error): FetchGlobalConfigFailureAction => ({
  type: FETCH_GLOBAL_CONFIG_FAILURE,
  error,
});

