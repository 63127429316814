import {call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {RegistrationAPI} from 'services/RegistrationAPI';
import {UsersAPI} from 'services/UsersAPI';
import {AppState} from 'store';
import * as Actions from './actions';
import * as Selectors from './selectors';
import {
  DELETE_PROFILE_REQUEST,
  FETCH_USER_INFO_REQUEST, GET_REFERRAL_INFO_REQUEST, GetReferralInfoAction,
  LOGIN_REQUEST,
  LoginAction,
  LOGOUT_REQUEST,
  RESET_PASSWORD_REQUEST,
  ResetPasswordAction, RESTORE_USER_REQUEST, RestoreUserAction,
  SEND_REGISTRATION_CODE_REQUEST,
  SEND_RESET_PASSWORD_CODE_REQUEST, SEND_RESTORE_USER_CODE_REQUEST,
  SendRegistrationCodeAction,
  SendResetPasswordCodeAction, SendRestoreUserCodeAction,
  VERIFY_REGISTRATION_CODE_REQUEST,
  VERIFY_RESET_PASSWORD_CODE_REQUEST, VERIFY_RESTORE_USER_CODE_REQUEST,
  VerifyRegistrationCodeAction,
  VerifyResetPasswordCodeAction, VerifyRestoreUserCodeAction,
} from './types';

export function* getReferralInfo(action: GetReferralInfoAction) {
  try {
    const {referral} = action.payload;

    const api = new UsersAPI();

    const {
      data,
    } = yield call(api.getReferralInfo, referral);

    yield put(Actions.getReferralInfoSuccess(data));
  } catch (error) {
    yield put(Actions.getReferralInfoFailure(error));
  }
}

export function* sendRegistrationCode(action: SendRegistrationCodeAction) {
  try {
    const {phone, recaptcha} = action.payload;

    const state: AppState = yield select();
    const attemptsCount = Selectors.sendRegistrationCodeAttemptsCount(state);
    const retry = attemptsCount - 1;

    const registrationAPI = new RegistrationAPI();

    const payload = {
      phone,
      retry,
      recaptcha,
    };

    yield call(registrationAPI.sendSmsCode, payload);

    yield put(Actions.sendRegistrationCodeSuccess());
  } catch (error) {
    yield put(Actions.sendRegistrationCodeFailure(error));
  }
}

export function* verifyRegistrationCode(action: VerifyRegistrationCodeAction) {
  try {
    const {code, phone} = action.payload;
    const registrationAPI = new RegistrationAPI();

    const {
      data: {registerId},
    } = yield call(registrationAPI.confirmSmsCode, phone, code);

    yield put(Actions.verifyRegistrationCodeSuccess(registerId));
  } catch (error) {
    yield put(Actions.verifyRegistrationCodeFailure(error));
  }
}

export function* loginUser(action: LoginAction) {
  try {
    const {phone, password, utmParams} = action.payload;

    const usersAPI = new UsersAPI();

    yield call(usersAPI.login, phone, password, utmParams);

    yield put(Actions.loginSuccess());
  } catch (error) {
    yield put(Actions.loginFailure(error));
  }
}

export function* sendResetPasswordCode(action: SendResetPasswordCodeAction) {
  try {
    const {phone, recaptcha} = action.payload;

    const state: AppState = yield select();
    const attemptsCount = Selectors.sendResetPasswordCodeAttemptsCount(state);
    const retry = attemptsCount - 1;

    const usersAPI = new UsersAPI();

    yield call(usersAPI.sendResetPasswordCode, {
      phone,
      retry,
      recaptcha,
    });

    yield put(Actions.sendResetPasswordCodeSuccess());
  } catch (error) {
    yield put(Actions.sendResetPasswordCodeFailure(error));
  }
}

export function* verifyResetPasswordCode(
  action: VerifyResetPasswordCodeAction
) {
  try {
    const {code, phone} = action.payload;
    const usersAPI = new UsersAPI();

    const {
      data: {resetPasswordToken},
    } = yield call(usersAPI.confirmResetPasswordCode, phone, code);

    yield put(Actions.verifyResetPasswordCodeSuccess(resetPasswordToken));
  } catch (error) {
    yield put(Actions.verifyResetPasswordCodeFailure(error));
  }
}

export function* resetPassword(action: ResetPasswordAction) {
  try {
    const {password, resetPasswordToken} = action.payload;

    const usersAPI = new UsersAPI();

    yield call(usersAPI.resetPassword, resetPasswordToken, password);

    yield put(Actions.resetPasswordSuccess());
  } catch (error) {
    yield put(Actions.resetPasswordFailure(error));
  }
}

//---------------------------------------------
export function* sendRestoreUserCode(action: SendRestoreUserCodeAction) {
  try {
    const {phone, recaptcha} = action.payload;

    const state: AppState = yield select();
    const attemptsCount = Selectors.sendRestoreUserCodeAttemptsCount(state);
    const retry = attemptsCount - 1;

    const usersAPI = new UsersAPI();

    yield call(usersAPI.sendRestoreUserCode, {
      phone,
      retry,
      recaptcha,
    });

    yield put(Actions.sendRestoreUserCodeSuccess());
  } catch (error) {
    yield put(Actions.sendRestoreUserCodeFailure(error));
  }
}

export function* verifyRestoreUserCode(
  action: VerifyRestoreUserCodeAction
) {
  try {
    const {code, phone} = action.payload;
    const usersAPI = new UsersAPI();

    const {
      data: {restoreUserToken},
    } = yield call(usersAPI.confirmRestoreUserCode, phone, code);

    yield put(Actions.verifyRestoreUserCodeSuccess(restoreUserToken));
  } catch (error) {
    yield put(Actions.verifyRestoreUserCodeFailure(error));
  }
}

export function* restoreUser(action: RestoreUserAction) {
  try {
    const {restoreUserToken} = action.payload;

    const usersAPI = new UsersAPI();

    yield call(usersAPI.restoreUser, restoreUserToken);

    yield put(Actions.restoreUserSuccess());
  } catch (error) {
    yield put(Actions.restoreUserFailure(error));
  }
}
//---------------------------------------------

export function* fetchUserInfo() {
  try {
    const usersAPI = new UsersAPI();

    const {data: userInfo} = yield call(usersAPI.fetchUserInfo);

    yield put(Actions.fetchUserInfoSuccess(userInfo));
  } catch (error) {
    yield put(Actions.fetchUserInfoFailure(error));
  }
}

export function* logout() {
  try {
    const usersAPI = new UsersAPI();

    yield call(usersAPI.logout);
    yield put(Actions.logoutSuccess());
  } catch (error) {
    yield put(Actions.logoutFailure(error));
  }
}

export function* deleteProfile() {
  try {
    const usersAPI = new UsersAPI();

    yield call(usersAPI.deleteProfile);
    yield put(Actions.deleteProfileSuccess());
  } catch (error) {
    yield put(Actions.deleteProfileFailure(error));
  }
}

export default [
  takeLatest(GET_REFERRAL_INFO_REQUEST, getReferralInfo),
  takeLatest(SEND_REGISTRATION_CODE_REQUEST, sendRegistrationCode),
  takeLatest(VERIFY_REGISTRATION_CODE_REQUEST, verifyRegistrationCode),
  takeLatest(LOGIN_REQUEST, loginUser),
  takeLatest(SEND_RESET_PASSWORD_CODE_REQUEST, sendResetPasswordCode),
  takeLatest(VERIFY_RESET_PASSWORD_CODE_REQUEST, verifyResetPasswordCode),
  takeLatest(RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(SEND_RESTORE_USER_CODE_REQUEST, sendRestoreUserCode),
  takeLatest(VERIFY_RESTORE_USER_CODE_REQUEST, verifyRestoreUserCode),
  takeLatest(RESTORE_USER_REQUEST, restoreUser),
  takeLatest(LOGOUT_REQUEST, logout),
  takeLatest(DELETE_PROFILE_REQUEST, deleteProfile),
  takeLatest(FETCH_USER_INFO_REQUEST, fetchUserInfo),

  fork(fetchUserInfo),
];
