import {combineReducers} from 'redux';
import {headerReducer} from './header';
import {itemTypesReducer} from './item-types';
import {offerOptionsReducer} from './offer-options';
import {operTypesReducer} from './oper-types';
import {specsReducer} from './specs';

export const commonReducer = combineReducers({
  specs: specsReducer,
  itemTypes: itemTypesReducer,
  operTypes: operTypesReducer,
  offerOptions: offerOptionsReducer,
  header: headerReducer,
});
