import {UserInfo} from 'common/types';
import {AppState} from 'store';

const ADD_PARTNER_DIALOG = '@partners/add-partner-dialog';
const DELETE_PARTNER_DIALOG = '@partners/delete-partner-dialog';
const RELOAD_LIST = '@partners/reload-list';

export interface AddPartnerDialogAction {
  type: typeof ADD_PARTNER_DIALOG;
  payload: {
    addPartnerOpened: boolean;
  };
}

export interface DeletePartnerDialogAction {
  type: typeof DELETE_PARTNER_DIALOG;
  payload: {
    deleteItem: null | UserInfo;
  };
}

export interface ReloadPartnersListAction {
  type: typeof RELOAD_LIST;
  payload: {
    forceListReload: boolean;
  };
}

type PartnerAction = AddPartnerDialogAction
  | DeletePartnerDialogAction
  | ReloadPartnersListAction;

interface State {
  addPartnerOpened: boolean;
  deleteItem: null | UserInfo;
  forceListReload: boolean;
}

const initialState: State = {
  addPartnerOpened: false,
  deleteItem: null,
  forceListReload: false,
};

export const partnerReducer = (state: State = initialState, action: PartnerAction) => {
  switch (action.type) {
    case ADD_PARTNER_DIALOG:
      return {
        ...state,
        addPartnerOpened: action.payload.addPartnerOpened,
      };
    case DELETE_PARTNER_DIALOG:
      return {
        ...state,
        deleteItem: action.payload.deleteItem,
      };
    case RELOAD_LIST:
      return {
        ...state,
        forceListReload: action.payload.forceListReload,
      };
    default:
      return state;
  }
};

export const setAddPartnerDialogOpened = (isOpened: boolean) => {
  return {
    type: ADD_PARTNER_DIALOG,
    payload: {
      addPartnerOpened: isOpened
    }
  };
};

export const setDeleteDialogItem = (user: null | UserInfo) => {
  return {
    type: DELETE_PARTNER_DIALOG,
    payload: {
      deleteItem: user
    }
  };
};

export const reloadPartnersList = (status: boolean) => {
  return {
    type: RELOAD_LIST,
    payload: {
      forceListReload: status
    }
  };
};

export const partnersState = (state: AppState) => state.partners.partners;
